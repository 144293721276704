import { ProjectId, ProjectsTasksModel } from '..';
import { BaseTimeInterval } from '../base';

export type ProjectsStageId = number;

export enum ProjectsStagesPermissions {
  StageCreate = 'stage_create',
  StageEdit = 'stage_edit',
  StageView = 'stage_view',
}

export interface ProjectsStageModel extends BaseTimeInterval {
  id: ProjectsStageId;
  title: string;
  color: string;
  projectId: ProjectId;
  permissions: ProjectsStagesPermissions[];
  tasks: ProjectsTasksModel[];
  tasksCount: number;
}
