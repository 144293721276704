import { PopulateEditChange } from '.';
import { endpoints } from '../../endpoints';
import { api } from '../../utils';

export type PostResetLayoutItemsParams = {
  layoutItemIds: number[];
};

export type PostUsersPopulate = {
  editChanges: PopulateEditChange[];
};

export const postResetLayoutItems = ({ layoutItemIds }: PostResetLayoutItemsParams) => {
  return api
    .post({
      url: endpoints.pages.usersCurrentUserIdResetLayoutItems(),
      data: { layoutItemIds },
    })
    .then(({ data }) => data);
};

export const postUsersPopulate = ({ editChanges }: PostUsersPopulate) => {
  return api
    .post<PopulateEditChange>({
      url: endpoints.pages.usersCurrentUserIdPopulate(),
      data: editChanges,
    })
    .then(({ data }) => data);
};

export const postResetDefaultLayoutItems = ({ layoutItemIds }: PostResetLayoutItemsParams) => {
  return api
    .post({
      url: endpoints.pages.defaultResetLayoutItems(),
      data: { layoutItemIds },
    })
    .then(({ data }) => data);
};

export const postDefaultPopulate = ({ editChanges }: PostUsersPopulate) => {
  return api
    .post<PopulateEditChange>({
      url: endpoints.pages.defaultPopulate(),
      data: editChanges,
    })
    .then(({ data }) => data);
};
