import { AxiosResponse } from 'axios';

import { TasksFilterParams, UpdateProjectParticipantsParams, UpdateStageParams } from '.';
import { endpoints } from '../../endpoints';
import {
  ProjectsTaskCreateModel,
  ProjectsTaskId,
  ProjectsTaskUpdateModel,
  ProjectId,
  ProjectsStageId,
} from '../../types/models';
import { CreateStageParams, ProjectCreateData, ProjectUpdateData } from '../../types/tasks';
import { api } from '../../utils';

export const browseTasks = <T = unknown>(data: TasksFilterParams): Promise<AxiosResponse<T>> => {
  return api.get({
    url: endpoints.tasks.browse(),
    data,
  });
};

export const createTask = <T = unknown>(data: ProjectsTaskCreateModel): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.tasks.tasksNew(data.projectId),
    data,
  });

export const getTask = <T = unknown>(taskId: ProjectsTaskId): Promise<AxiosResponse<T>> =>
  api.get({
    url: endpoints.tasks.tasksId(taskId),
  });

export const updateTask = <T = unknown>(data: ProjectsTaskUpdateModel): Promise<AxiosResponse<T>> =>
  api.patch({
    url: endpoints.tasks.tasksId(data.id),
    data,
  });

export const deleteTask = (taskId: ProjectsTaskId): Promise<AxiosResponse> =>
  api.delete({
    url: endpoints.tasks.tasksId(taskId),
  });

export const createProject = <T = unknown>(data: ProjectCreateData): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.tasks.projects(),
    data,
  });

export const updateProject = <T = unknown>(
  id: ProjectId,
  data: ProjectUpdateData,
): Promise<AxiosResponse<T>> =>
  api.patch({
    url: endpoints.tasks.projectsId(id),
    data,
  });

export const updateProjectParticipants = <T = unknown>({
  projectId,
  ...data
}: UpdateProjectParticipantsParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: endpoints.tasks.projectsIdParticipants(projectId),
    data,
  });

export const deleteProject = (projectId: ProjectId): Promise<AxiosResponse> =>
  api.delete({
    url: endpoints.tasks.projectsId(projectId),
  });

export const createStage = <T = unknown>(data: CreateStageParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.tasks.stages(),
    data,
  });

export const updateStage = <T = unknown>({ id, ...rest }: UpdateStageParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: endpoints.tasks.stagesId(id),
    data: rest,
  });

export const deleteStage = (stageId: ProjectsStageId): Promise<AxiosResponse> =>
  api.delete({
    url: endpoints.tasks.stagesId(stageId),
  });
