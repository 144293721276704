import { endpoints } from '../../endpoints';
import { DictPaginated } from '../../types/api';
import { GamificationBadgeId, GamificationBadges } from '../../types/models';
import { buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';

type GamificationUsersByBadgeIdParams = { badgeId: GamificationBadgeId; userName?: string };

export const getGamificationUsersByBadgeIdStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GamificationBadges>,
    GamificationBadges[],
    GamificationBadges[],
    GamificationUsersByBadgeIdParams
  >({
    endpointBuilder: ({ badgeId, userName }) =>
      buildEndpointWithQueryParams(endpoints.gamification.badgesBadgeIdUsers(badgeId), { userName }),
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
