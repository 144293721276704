import { AxiosError } from 'axios';
import { createEffect, createEvent } from 'effector';

import { endpoints } from '../../endpoints';
import {
  DictDataParams,
  DictMatchTypes,
  DictPaginated,
  FetchSelectEntityParams,
  UserProfile,
} from '../../types';
import {
  CreateDictionaryRecord,
  GroupManagementDivision,
  UserIdModel,
  Dictionaries,
  RecordResponse,
  GroupDivisionModel,
  GroupMemberStructureInfo,
  GroupMemberStructureInfoModel,
  ContactsModel,
  ProfileAdditionalInfoModel,
  ProfileBiographyModel,
  ProfileInfoModel,
  ProfileJobHistoryModel,
  ProfileProjectItemModel,
  ProfileProjectModel,
  UserProfileModel,
  UserJobModel,
  UserProfileJobModel,
  UserAuthorModel,
  FullNameModel,
  UserPositionModel,
  OrganizationId,
} from '../../types/models';
import { UiOptionData } from '../../types/option';
import {
  getFullName,
  generateDictRecord,
  buildEndpointWithQueryParams,
  EndpointQueryParamsBaseType,
  abstractStorageFactory,
} from '../../utils';
import { getDictsStorage } from '../dictionaries';
import {
  BiographyParams,
  ContactsParams,
  createProfileProject,
  JobsHistoryParams,
  MainEditParams,
  patchBiographyProfile,
  patchJobHistoryProfile,
  patchMainProfile,
  patchProfileContacts,
  patchProfileUserAdditionalInfo,
  patchProfileUserProjects,
  ProfileFullInfoParams,
  ProjectsParams,
} from './api';

export type UserIdParams = { userId: UserIdModel };

type FetchProfileParams = {
  userId: UserIdModel;
};

export const getProfileStorage = () => {
  const storage = abstractStorageFactory<UserProfileModel, UserProfile, null, FetchProfileParams>({
    endpointBuilder: ({ userId }) => endpoints.profile.main(userId),
    defaultValue: null,
    dataMapper: (data) => {
      const groupPathList = data.groupPath?.split('/');
      const profileGroupId = groupPathList?.at(-1);
      const profileOrganizationId = groupPathList?.at(0);

      return {
        ...data,
        profileGroupId,
        profileOrganizationId,
      };
    },
  });

  return { storage };
};

export const getProfileJobStorage = () => {
  const storage = abstractStorageFactory<UserJobModel, UserJobModel, null, FetchProfileParams>({
    endpointBuilder: ({ userId }) => endpoints.profile.job(userId),
    defaultValue: null,
  });

  return { storage };
};

export interface ProfileListParams extends Partial<FullNameModel>, EndpointQueryParamsBaseType {
  ordering?: string;
  usersIds?: UserIdModel[];
  username?: string;
  email?: string;
  isActive?: boolean;
  skipEmptyName?: boolean;
  exact?: boolean;
  excludeUsers?: UserIdModel[];
  withContacts?: boolean;
  skipEmptyOrganization?: boolean;
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}

export const getProfileListOptionsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<UserProfileJobModel>,
    UiOptionData[],
    UiOptionData[],
    Partial<ProfileListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.profile.v2List(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) =>
      data.items.map((item) => ({
        value: item.id,
        label: getFullName(item.fullName),
        job: item.job?.position,
        avatar: item.smallAvatar,
        data: item,
      })),
  });

  return { storage };
};

export const getProfileContactsStorage = ({ userId }: UserIdParams) => {
  const storage = abstractStorageFactory<ContactsModel, ContactsModel, null>({
    endpointBuilder: () => endpoints.profile.userIdContacts(userId),
    cancelPendingRequestOnFetch: true,
    defaultValue: null,
  });

  const patchProfileContactsEffect = createEffect<ContactsParams, ContactsModel, AxiosError>((params) =>
    patchProfileContacts(params).then((response) => response.data),
  );

  storage.store.on(patchProfileContactsEffect.doneData, (state, contacts) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            ...contacts,
          },
        }
      : state,
  );

  return { storage, patchProfileContactsEffect };
};

export const getProfilesListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<UserAuthorModel>,
    UserAuthorModel[],
    UserAuthorModel[],
    Partial<ProfileListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.profile.v2List(), params),
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    defaultValue: [],
    dataBuilder: (ids) => ids,
  });

  return { storage };
};

export type ProfilesListStorage = ReturnType<typeof getProfilesListStorage>;

type ProfilePositionListParams = {
  query: string;
};

export const getProfilePositionListStorage = () => {
  const storage = abstractStorageFactory<
    UserPositionModel[],
    UserPositionModel[],
    UserPositionModel[],
    Partial<ProfilePositionListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.profile.listPositions(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type ProfileStructureInfoMapParams = {
  ids: UserIdModel[];
};

export const getProfileStructureInfoMapStorage = () => {
  const storage = abstractStorageFactory<
    GroupMemberStructureInfoModel[],
    Record<UserIdModel, GroupMemberStructureInfo>,
    Record<UserIdModel, GroupMemberStructureInfo>,
    ProfileStructureInfoMapParams
  >({
    endpointBuilder: endpoints.groups.membersGroups,
    dataMapper: (data) => Object.fromEntries(data.map(({ userId, ...info }) => [userId, info])),
    dataBuilder: ({ ids }) => ({ ids }),
    requestMethod: 'post',
    defaultValue: {},
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type ProfileStructureInfoParams = {
  id: UserIdModel;
};

export const getProfileStructureInfoStorage = () => {
  const storage = abstractStorageFactory<
    GroupMemberStructureInfoModel[],
    GroupMemberStructureInfo,
    GroupMemberStructureInfo | null,
    ProfileStructureInfoParams
  >({
    endpointBuilder: endpoints.groups.membersGroups,
    dataBuilder: ({ id }) => ({ ids: [id] }),
    // TODO: Исправить типизацию https://gitlab.corp.mail.ru/people-hub/apps/frontend/-/issues/322
    dataMapper: ([item]) => ({
      organization: item?.organization,
      organizationId: item?.organizationId,
      division: item?.division,
      divisionId: item?.divisionId,
    }),
    requestMethod: 'post',
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetProfileStructureInfoStorage = ReturnType<typeof getProfileStructureInfoStorage>;

const getProfileFullInfoStorage = () => {
  const storage = abstractStorageFactory<ProfileInfoModel, ProfileInfoModel, null, ProfileFullInfoParams>({
    endpointBuilder: ({ userId }) => endpoints.profile.userIdFull(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const updateAvatarEvent = createEvent<string>();

  storage.store.on(updateAvatarEvent, (state, avatar) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        main: {
          ...state.data.main,
          avatar,
        },
      },
    };
  });

  const patchMainProfileEffect = createEffect<MainEditParams, UserProfileModel, AxiosError>((params) =>
    patchMainProfile(params).then((response) => response.data),
  );

  storage.store.on(patchMainProfileEffect.doneData, (state, main) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        main,
      },
    };
  });

  const patchJobHistoryProfileEffect = createEffect<JobsHistoryParams, ProfileJobHistoryModel[], AxiosError>(
    (params) => patchJobHistoryProfile(params).then((response) => response.data),
  );

  storage.store.on(patchJobHistoryProfileEffect.doneData, (state, jobHistory) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        jobHistory,
      },
    };
  });

  const patchBiographyProfileEffect = createEffect<BiographyParams, ProfileBiographyModel, AxiosError>(
    (params) => patchBiographyProfile(params).then((response) => response.data),
  );

  storage.store.on(patchBiographyProfileEffect.doneData, (state, biography) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        biography,
      },
    };
  });

  const patchProfileUserAdditionalInfoEffect = createEffect<
    ProfileAdditionalInfoModel,
    ProfileAdditionalInfoModel,
    AxiosError
  >((params) => patchProfileUserAdditionalInfo(params).then((response) => response.data));

  storage.store.on(patchProfileUserAdditionalInfoEffect.doneData, (state, additionalInfo) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        additionalInfo,
      },
    };
  });

  const patchProfileUserProjectsEffect = createEffect<ProjectsParams, ProfileProjectItemModel[], AxiosError>(
    (params) => patchProfileUserProjects(params).then((response) => response.data),
  );

  storage.store.on(patchProfileUserProjectsEffect.doneData, (state, projects) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        projects,
      },
    };
  });

  return {
    storage,
    updateAvatarEvent,
    patchJobHistoryProfileEffect,
    patchMainProfileEffect,
    patchBiographyProfileEffect,
    patchProfileUserAdditionalInfoEffect,
    patchProfileUserProjectsEffect,
  };
};

export const profileFullInfoStorage = getProfileFullInfoStorage();

export const competenciesDictsStorage = getDictsStorage<RecordResponse, DictDataParams>({
  dictionaryName: Dictionaries.Names.Competencies,
  dataBuilder: ({ name: { type, value } }) => ({ name: { type, value } }),
  getEndpointParams: () => ({ ordering: 'name' }),
});

export const getProfileProjectDictLikeStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProfileProjectModel>,
    RecordResponse[],
    RecordResponse[],
    FetchSelectEntityParams
  >({
    endpointBuilder: () =>
      buildEndpointWithQueryParams(endpoints.profile.projectsSearch(), { ordering: 'name' }),
    dataBuilder: ({ value }) => ({ name: { type: DictMatchTypes.Icontains, value } }),
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.items.map((item) => generateDictRecord(item)),
  });

  const createProfileProjectDictLikeEffect = createEffect<CreateDictionaryRecord, RecordResponse, AxiosError>(
    ({ attributes }) =>
      attributes.name
        ? createProfileProject<ProfileProjectModel>({ name: attributes.name }).then(({ data }) =>
            generateDictRecord(data),
          )
        : Promise.reject(),
  );

  storage.store.on(createProfileProjectDictLikeEffect.doneData, (state, newProject) => ({
    ...state,
    data: [...state.data, newProject],
  }));

  return { storage, createProfileProjectDictLikeEffect };
};

export const getProfilePositionDictLikeStorage = () => {
  const storage = abstractStorageFactory<
    UserPositionModel[],
    RecordResponse[],
    RecordResponse[],
    FetchSelectEntityParams
  >({
    endpointBuilder: ({ value }) =>
      buildEndpointWithQueryParams(endpoints.profile.listPositions(), { query: value }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data.map((item) => generateDictRecord(item)),
  });

  return { storage };
};

export const getProfileDepartmentDictLikeStorage = (organizationId?: OrganizationId) => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupDivisionModel>,
    RecordResponse[],
    RecordResponse[],
    FetchSelectEntityParams
  >({
    endpointBuilder: ({ value }) =>
      buildEndpointWithQueryParams(endpoints.groups.department(), { name: value, organizationId }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items.map((item) => generateDictRecord(item)),
  });

  return { storage };
};

export const getOrganizationDictLikeStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GroupManagementDivision>,
    RecordResponse[],
    RecordResponse[],
    FetchSelectEntityParams
  >({
    endpointBuilder: ({ value }) =>
      buildEndpointWithQueryParams(endpoints.groups.organization(), { search: value }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items.map((item) => generateDictRecord(item)),
  });

  return { storage };
};
