import { ProjectId, ProjectsStageId, ProjectsTaskId } from '../types/models';

export const tasksEndpoints = {
  browse: () => '/api/tasks/browse/',
  history: () => '/api/tasks/history/',
  projects: () => '/api/tasks/projects/',
  projectsId: (projectId: ProjectId) => `/api/tasks/projects/${projectId}/`,
  projectsIdParticipants: (projectId: ProjectId) => `/api/tasks/projects/${projectId}/participants/`,
  referencesPriorities: () => '/api/tasks/references/priorities/',
  referencesStatuses: () => '/api/tasks/references/statuses/',
  stages: () => '/api/tasks/stages/',
  stagesId: (stageId: ProjectsStageId) => `/api/tasks/stages/${stageId}/`,
  tasksId: (taskId: ProjectsTaskId) => `/api/tasks/tasks/${taskId}/`,
  tasksNew: (projectId: ProjectId) => `/api/tasks/tasks/${projectId}/new/`,
};
