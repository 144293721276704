import type { BaseAttributes } from './dictionary.model';
import type { FileStorageEntryId, FileStorageEntryUrl } from './file.model';
import type { ReactionModel } from './reactions.model';
import type { UserIdModel, UserProfileModel } from './user.model';

export enum GamificationItemTypes {
  Thanks = 'thanks',
  Badge = 'badge',
}

export enum GamificationBadgeTypes {
  All = 'all',
  Single = 'single',
  Quantity = 'quantity',
}

export enum GamificationBadgeAwardType {
  Manual = 'manual',
  Event = 'event',
}

export type GamificationBadgesSummary = {
  name: string;
  image: string;
  badgeCount: number;
  maxBadgeNum: number;
};

export type GamificationCompletedActions = {
  description: string | null;
  event: string;
};

export type GamificationBadgeId = number;

export type GamificationThanksId = string;

export type GamificationBadgeInfo = {
  id: GamificationBadgeId;
  type: GamificationBadgeTypes;
  isSystem: boolean;
  multiple: boolean;
  awardType: GamificationBadgeAwardType;
  createdAt: string;
  groupId: string;
  externalId: string;
  clientId: string;
  categoryRid: string;
  name: string;
  coins: number;
  image: string;
  description: string;
  quantity: number;
  maxBadgeNum: number;
  usersCount: number;
};

export interface GamificationUserModel
  extends Pick<
    UserProfileModel,
    'id' | 'avatar' | 'fullName' | 'gender' | 'smallAvatar' | 'mediumAvatar' | 'position'
  > {
  userName: string;
}

export type GamificationBadges = {
  awardedAt: string;
  awardDecisionData: string;
  awardReason: string;
  badgeCount: number;
  badge: GamificationBadgeInfo;
  completedActions: GamificationCompletedActions[];
  id: number;
  user: GamificationUserModel;
  reactions: ReactionModel[];
  reactionsCount: number;
  commentsCount: number;
};

export type GamificationWallet = {
  id: number;
  balance: string;
  createdAt: string;
  updatedAt: string;
  user: UserProfileModel;
};

export type GamificationThanksSummary = {
  name: string;
  image: string;
  count: number;
};

export interface GamificationThanksBase {
  id: GamificationThanksId;
  attributes: GamificationThanksSummary;
}

export type GamificationAdminThanks = Pick<GamificationThanksBase, 'id'> & GamificationThanksSummary;

export type GamificationAdminBadge = {
  id: GamificationBadgeId;
  name: string;
  categoryRid: Pick<GamificationBadgeInfo, 'categoryRid'>;
  category: BaseAttributes['name'];
  awardeesCount: number;
  fileStorageImageId: FileStorageEntryId;
  fileStorageImageUrl: FileStorageEntryUrl;
};

export interface GamificationThanks extends GamificationThanksBase {
  msg: string;
  coins: number;
  reactions: ReactionModel[];
  reactionsCount: number;
  commentsCount: number;
  createdAt: string;
  fromUser: UserProfileModel;
}

export interface GamificationAddedThank extends GamificationThanks {
  toUser: UserProfileModel;
}

export enum GamificationTransactionType {
  ActionCompletion = 'action_completion',
  BadgeCompletion = 'badge_completion',
  ManualReward = 'manual_reward',
  Purchase = 'purchase',
  Transfer = 'transfer',
  Withdraw = 'withdraw',
}

export type GamificationTransactionHistory = {
  amount: number;
  createdAt: string;
  id: number;
  recipient: UserProfileModel;
  sender: UserProfileModel;
  text: string;
  type: GamificationTransactionType;
};

export type GamificationAwardUsers = {
  keycloakIds: UserIdModel[];
  badgeIds: GamificationBadgeId[];
  awardReason?: string;
};
