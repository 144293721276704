/**
 * Если трогаешь эти MIME-типы,
 * не забудь про чекеры в fileMimeTypeCheckersMap
 */
export enum FileUploadAccepts {
  All = '',
  ImageAll = 'image/*',
  ImagePng = 'image/png',
  ImagePngJpg = 'image/png,image/jpeg,image/jpg',
  ImagePngJpgGif = 'image/png,image/jpeg,image/jpg,image/gif',
  ImagePngJpgGifBmp = 'image/png,image/jpeg,image/jpg,image/gif,image/bmp',
  ImageSvg = 'image/svg+xml',
  VideoAll = 'video/*',
  VideoWeb = 'video/mp4,video/mpeg,video/webm',
  PackageZip = 'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip',
  ExcelXlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Html = 'text/html',
}

type FileMimeTypeChecker = (fileType: string) => boolean;

const ImageJPEG = 'image/jpeg';
const ImageJPG = 'image/jpg';
const ImagePNG = 'image/png';
const ImageGIF = 'image/gif';
const ImageBMP = 'image/bmp';

const fileMimeTypeCheckersMap: Record<FileUploadAccepts, FileMimeTypeChecker> = {
  [FileUploadAccepts.All]: () => true,
  [FileUploadAccepts.VideoAll]: (fileType) => fileType.startsWith('video/'),
  [FileUploadAccepts.VideoWeb]: (fileType) => ['video/mp4', 'video/webm', 'video/mpeg'].includes(fileType),
  [FileUploadAccepts.ImageAll]: (fileType) => fileType.startsWith('image/'),
  [FileUploadAccepts.ImagePng]: (fileType) => fileType === 'image/png',
  [FileUploadAccepts.ImagePngJpg]: (fileType) => [ImagePNG, ImageJPG, ImageJPEG].includes(fileType),
  [FileUploadAccepts.ImagePngJpgGif]: (fileType) =>
    [ImagePNG, ImageJPG, ImageJPEG, ImageGIF].includes(fileType),
  [FileUploadAccepts.ImagePngJpgGifBmp]: (fileType) =>
    [ImagePNG, ImageJPG, ImageJPEG, ImageGIF, ImageBMP].includes(fileType),
  [FileUploadAccepts.ImageSvg]: (fileType) => fileType === 'image/svg+xml',
  [FileUploadAccepts.PackageZip]: (fileType) =>
    [
      'application/zip',
      'application/octet-stream',
      'application/x-zip-compressed',
      'multipart/x-zip',
    ].includes(fileType),
  [FileUploadAccepts.ExcelXlsx]: (fileType) =>
    fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FileUploadAccepts.Html]: (fileType) => fileType === 'text/html',
};

export const checkFileMimeType = (expectedMimeType: FileUploadAccepts, fileType: string) =>
  fileMimeTypeCheckersMap[expectedMimeType](fileType);
