import { CalendarEventModel, GamificationAddedThank } from '../../../types/models';

export enum NotificationTypes {
  CommentCreated = 'blog_entry_comment_created',
  CalendarEventCreated = 'calendar_event_created',
  NewThanks = 'new_thanks',
}

export type NotificationPayloads = {
  [NotificationTypes.CommentCreated]: {
    text: string;
    blog: {
      id: number;
      image: string;
      name: string;
      slug: string;
    };
    entry: {
      id: number;
      text: string;
    };
  };
  [NotificationTypes.CalendarEventCreated]: {
    event: CalendarEventModel;
  };
  [NotificationTypes.NewThanks]: GamificationAddedThank;
};
