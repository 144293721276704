import { useState, useEffect, useCallback } from 'react';

type UseTimeoutHandler = {
  start: () => void;
  clear: () => void;
  stop: () => void;
  isActive: boolean;
  timer: number;
};

export function useTimer(timeoutDelayMs = 1000, startNumber = 0): UseTimeoutHandler {
  const [isTimeoutActive, setIsTimeoutActive] = useState(false);
  const [timer, setTimer] = useState(startNumber);

  const stop = useCallback(() => {
    setIsTimeoutActive(false);
  }, []);

  const clear = useCallback(() => {
    setTimer(0);
  }, []);

  const start = useCallback(() => {
    setIsTimeoutActive(true);
  }, []);

  function callback() {
    setTimer((value) => value + 1);
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let removeTimeoutListener = () => {};

    if (isTimeoutActive) {
      const timeout = window.setInterval(callback, timeoutDelayMs);

      removeTimeoutListener = () => window.clearInterval(timeout);
    }

    return removeTimeoutListener;
  }, [isTimeoutActive, timeoutDelayMs, timer]);

  return {
    clear,
    isActive: isTimeoutActive,
    start,
    stop,
    timer,
  };
}
