import { CommentContentTypes, CommentDetailDataModel, CommentDetailListModel } from '../models/comment.model';

export const isCommentContentType = <T extends CommentDetailDataModel>(
  comment: CommentDetailListModel,
  type: CommentContentTypes,
): comment is CommentDetailListModel<T> => {
  return comment.contentType === type;
};

export const isCommentSomeOfContentTypes = <T extends CommentDetailDataModel>(
  comment: CommentDetailListModel,
  types: CommentContentTypes[],
): comment is CommentDetailListModel<T> => {
  return types.some((type) => comment.contentType === type);
};
