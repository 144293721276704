import { EntityContentData } from '../editor';
import { Dictionaries } from './dictionary.model';
import { FileStorageEntryUrl } from './file.model';
import { PostSettings, PostTypes } from './post';
import { ReactableModel } from './reactions.model';
import { BaseTag } from './tags.model';
import { UserModel } from './user.model';

export enum NewsStatus {
  Published = 'published',
  Planned = 'planned',
  Draft = 'draft',
  Pinned = 'pinned',
}

export enum NewsСategoryActiveStatus {
  Active = 'active',
  Inactive = 'inactive',
  All = 'all',
}

export type NewsCategoryListParams = {
  pageNumber?: number;
  pageSize?: number;
  activeStatus?: string;
};

export type NewsCategory = {
  id: CategoryId;
  name: string;
  extraInfo: { color?: string };
  isActive: boolean;
};

export type NewsId = number;
export type CategoryId = number;
export type NewsCategoriesDist = Record<number, NewsCategory>;
export type NewsType = PostTypes.Simple | PostTypes.Stories;

export interface NewsCategoryParams extends Omit<NewsCategory, 'id'> {
  id?: NewsId;
}

type NewsArticleSettings = Pick<PostSettings, 'isComments' | 'isReacted'>;

export interface NewsArticle<Tags extends BaseTag = Dictionaries.Dict> extends ReactableModel {
  id: NewsId;
  title: string;
  cover: FileStorageEntryUrl;
  type?: NewsType;
  categoryId: CategoryId;
  publishedAt: string;
  updatedAt?: string;
  createdAt: string;
  author: UserModel;
  commentsCount: number;
  important: boolean;
  pinned: boolean;
  timelinePinned: boolean;
  tags: Tags[];
  body: EntityContentData;
  draft: boolean;
  authorId: string;
  status: NewsStatus;
  viewsCount: number;
  settings: NewsArticleSettings;
}
