import { CategoryId, NewsId, UserIdModel } from '../types/models';

export const newsEndpoints = {
  feed: () => '/api/news/feed/',
  categoryList: () => '/api/news/category/list/',
  newsCategoryId: (categoryId: CategoryId) => `/api/news/category/${categoryId}/`,
  categorySave: () => '/api/news/category/save/',
  list: () => '/api/news/list/',
  newsId: (newsId: NewsId) => `/api/news/${newsId}/`,
  newsIdPlanning: (newsId: NewsId) => `/api/news/${newsId}/planning/`,
  newsIdPublish: (newsId: NewsId) => `/api/news/${newsId}/publish/`,
  save: () => '/api/news/save/',
  rolesList: () => `/api/news/roles/list/`,
  rolesSave: () => '/api/news/roles/save/',
  rolesUserId: (userId: UserIdModel) => `/api/news/roles/${userId}/`,
};
