import { useCallback, useEffect } from 'react';

/**  @deprecated: используй useDebouncedCallback */
export const useDebounce = (effect: () => void, delay: number, deps: unknown[]) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
