import { UserAgreementId } from '../types/models';

export const termsOfUseEndpoints = {
  get: () => '/api/terms-of-use/',
  new: () => '/api/terms-of-use/new/',
  openAPI: () => '/api/terms-of-use/open-api/',
  openAPIAgreementId: (id: UserAgreementId) => `/api/terms-of-use/open-api/${id}/`,
  openAPIAgreementIdFactsOfConsent: (id: UserAgreementId) =>
    `/api/terms-of-use/open-api/${id}/facts_of_consent/`,
};
