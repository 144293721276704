import type { UploadDraggerAreaProps } from '@vkph/components';

import type { BaseFileSizeUploadParams } from '../utils';
import type { FileToUploadModel, PreloadedFileModel } from './models';

export type ImageUploadCropDescription = {
  rulesTitle: string;
  rulesList: string[];
  cropTitle: string;
};

export type ImageUploadCropValue = {
  id: PreloadedFileModel['storageObject'];
  file: PreloadedFileModel['fileUrl'];
};

export type ImageUploadCropPreviewValue = {
  uploadImage?: ImageUploadCropValue;
  previewImage?: FileToUploadModel;
};

export type CropImageAreaFigure = {
  aspect: number;
  minWidth: number;
  minHeight: number;
};

export enum FigureType {
  Circle = 'circle',
  Rectangle = 'rectangle',
}

export interface ImageUploadCropPayload extends Partial<CropImageAreaFigure> {
  title: string;
  value?: ImageUploadCropPreviewValue;
  onChange: (image: ImageUploadCropValue) => void;
  onClose: () => void;
  figureType?: FigureType;
  description?: ImageUploadCropDescription;
  maxSize?: BaseFileSizeUploadParams['maxSize'];
  uploadAreaProps?: Partial<UploadDraggerAreaProps>;
}
