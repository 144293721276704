import flatMap from 'lodash/flatMap';

import { getProfileStorage } from '../../../store/profile';
import { getProfileRolesPermissionsStorage } from '../../../store/roles';
import { AllAllowedPermissions } from '../../../types/models';

export const currentProfileStorage = getProfileStorage().storage;

/** @deprecated Используй useCurrentProfile */
export const currentProfileStore = currentProfileStorage.store.map(({ data }) => data);

export const currentProfileRolesPermissionsStorage = getProfileRolesPermissionsStorage().storage;

export const currentProfileRolesPermissionsStore = currentProfileRolesPermissionsStorage.store.map(
  ({ data }) => data,
);
export const currentProfileRolesPermissionsMapStore = currentProfileRolesPermissionsStorage.store.map(
  ({ data }) =>
    new Map<AllAllowedPermissions, string>(flatMap(data).map((item) => [item.name, item.description])),
);

export const currentProfileRolesPermissionsSetStore = currentProfileRolesPermissionsStorage.store.map(
  ({ data }) => new Set<AllAllowedPermissions>(flatMap(data).map(({ name }) => name)),
);
