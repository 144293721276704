import { SkillId, UserIdModel } from '../types/models';

export const profileEndpoints = {
  additionalInfo: (userId: UserIdModel) => `/profile/${userId}/additional_info/`,
  biography: (userId: UserIdModel) => `/profile/${userId}/biography/`,
  contactsSearch: () => `/profile/contacts/search`,
  counters: (id: UserIdModel) => `/profile/${id}/counters/`,
  getUserId: () => `/profile/get_user_id/`,
  job: (userId: UserIdModel) => `/profile/${userId}/job/`,
  jobHistory: (userId: UserIdModel) => `/profile/${userId}/job/history/`,
  listPositions: () => '/profile/list/positions/',
  main: (userId: UserIdModel) => `/profile/${userId}/main/`,
  openAPIToken: () => `/profile/open-api/token/`,
  hobbySearch: () => '/profile/hobby/search/',
  projectsSearch: () => '/profile/projects/search/',
  projects: () => '/profile/projects/',
  userIdSubstitutions: (userId: UserIdModel) => `/profile/${userId}/substitutions/`,
  userIdContacts: (userId: UserIdModel) => `/profile/${userId}/contacts/`,
  userIdFull: (userId: UserIdModel) => `/profile/${userId}/full/`,
  userProjects: (userId: UserIdModel) => `/profile/user/${userId}/projects/`,
  userSettings: () => '/profile/user/settings/',
  v2List: () => '/profile/v2/list/',
  v2Skills: (id: UserIdModel) => `/profile/v2/${id}/skills/`,
  v2SkillAction: (id: UserIdModel, skillId: SkillId) => `/profile/v2/${id}/skills/${skillId}/`,
  v2SkillPeoples: (id: UserIdModel) => `/profile/v2/skills/${id}/people/`,
  v3AvatarCreate: (userId: UserIdModel) => `/profile/v3/${userId}/avatar/create/`,
  v3AdvancedSearch: () => '/profile/v3/advanced_search/',
  v3AdvancedSearchDownload: () => '/profile/v3/advanced_search/download/',
};
