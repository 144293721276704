import { AxiosResponse } from 'axios';

import { endpoints } from '../../endpoints';
import {
  UserIdModel,
  GroupsMailingSendingParams,
  GroupsMailingId,
  SendingSurveyId,
  SurveyId,
  SurveyModel,
  SurveySendingParams,
  SurveySendingUpdateParams,
  GroupsMailingUpdateParams,
} from '../../types/models';
import { api } from '../../utils';

export type DefaultSurveyParams = { surveyId: SurveyId };
export type DefaultSendingSurveyParams = { sendingSurveyId: SendingSurveyId };
export type DefaultGroupMailingParams = { groupMailingId: GroupsMailingId };
export type DeleteMemberParams = { groupMailingId: GroupsMailingId; memberId: UserIdModel };

export const deleteSurvey = ({ surveyId }: DefaultSurveyParams) =>
  api.delete({
    url: endpoints.survey.surveyId(surveyId),
  });

export const cloneSurvey = <T = unknown>({ surveyId }: DefaultSurveyParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.survey.surveyClone(surveyId),
  });

export const saveSurvey = <T = unknown>(data: SurveyModel): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.survey.survey(),
    data,
  });

export const getSurvey = <T = unknown>({ surveyId }: DefaultSurveyParams): Promise<AxiosResponse<T>> =>
  api.get({
    url: endpoints.survey.surveyId(surveyId),
  });

export const surveySending = <T = unknown>(data: SurveySendingParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.survey.surveySending(),
    data,
  });

export const surveySendingUpdate = <T = unknown>(
  data: SurveySendingUpdateParams,
): Promise<AxiosResponse<T>> =>
  api.patch({
    url: endpoints.survey.surveyInstanceSendingSurveyId(data.id),
    data,
  });

export const surveySendingCancel = <T = unknown>(id: SendingSurveyId): Promise<AxiosResponse<T>> =>
  api.delete({
    url: endpoints.survey.surveyInstanceSendingSurveyIdCancel(id),
  });

export const deleteGroupMailing = ({ groupMailingId }: DefaultGroupMailingParams) =>
  api.delete({
    url: endpoints.survey.groupGroupId(groupMailingId),
  });

export const createGroupMailing = <T = unknown>(
  data: GroupsMailingSendingParams,
): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.survey.group(),
    data,
  });

export const updateGroupMailing = <T = unknown>(data: GroupsMailingUpdateParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: endpoints.survey.groupGroupId(data.id),
    data,
  });

export const getGroupMailing = <T = unknown>(groupMailingId: GroupsMailingId): Promise<AxiosResponse<T>> =>
  api.get({
    url: endpoints.survey.groupGroupId(groupMailingId),
  });

export const cloneGroupMailing = <T = unknown>(groupMailingId: GroupsMailingId): Promise<AxiosResponse<T>> =>
  api.post({ url: endpoints.survey.groupMailingClone(groupMailingId) });

export const deleteMemberGroupMailing = <T = unknown>(
  groupMailingId: GroupsMailingId,
  memberId: UserIdModel,
): Promise<AxiosResponse<T>> =>
  api.delete({
    url: endpoints.survey.membersGroupMailing(groupMailingId, memberId),
  });
