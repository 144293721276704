import { message } from '@vkph/ui';

import { FileToUploadModelWithoutData } from '../types/models';
import { FileUploadAccepts, checkFileMimeType } from './checkFileMimeType';
import { readFileToStringPromise } from './read-file-to-string-promise';

type UseFileDialogParams = {
  multiple?: boolean;
  accept?: FileUploadAccepts;
  doNotParseIntoBase64?: boolean;
};

const UNSUPPORTED_FILE = 'Данный формат файла не поддерживается';

export const openFileDialog = ({
  multiple = false,
  accept = FileUploadAccepts.All,
}: UseFileDialogParams = {}): Promise<FileToUploadModelWithoutData[]> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');

    input.type = 'file';
    input.multiple = multiple;
    input.accept = accept;

    input.onchange = () => {
      const files = Array.from(input.files || []);
      const promises = files
        .filter((file) => checkFileMimeType(accept, file.type))
        .map((file) => readFileToStringPromise(file, false));

      const unsupportedFiles = files.filter((file) => !checkFileMimeType(accept, file.type)).length > 0;

      if (unsupportedFiles) {
        message.error(UNSUPPORTED_FILE);
      }

      if (!promises.length) {
        reject(UNSUPPORTED_FILE);
      } else {
        Promise.all(promises).then(resolve).catch(reject);
      }
    };

    input.onerror = reject;

    input.click();
  });
};
