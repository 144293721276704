import type { AbstractStoragePaginationInfo } from '../../utils/effector';
import type { DictPaginated, PaginatedListResults, PaginatedNextResults } from '../api';

export const isDictPaginated = (paginated: Record<string, unknown>): paginated is DictPaginated<unknown> => {
  const { meta, items } = paginated as DictPaginated<unknown>;

  return meta !== undefined && items !== undefined;
};

export const isPaginatedListResults = (
  paginated: Record<string, unknown>,
): paginated is PaginatedListResults<unknown> => {
  const { meta, results } = paginated as PaginatedListResults<unknown>;

  return 'objectsCount' in meta && results !== undefined;
};

export const isPaginatedNextResults = (
  paginated: AbstractStoragePaginationInfo['next'],
): paginated is PaginatedNextResults<unknown> => {
  const { meta, results } = paginated as PaginatedNextResults<unknown>;

  return 'next' in meta && results !== undefined;
};
