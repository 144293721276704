import axios, { AxiosError, CancelTokenSource } from 'axios';
import { createEffect, createEvent, createStore, sample } from 'effector';

import { FileUploadAccepts, checkFileMimeType, getErrorResponseMessage } from '..';
import { api } from '../api';
import { getVideoFileMeta } from '../getVideoFileMeta';

type StoreFileId = string;

type UploadFileStatusType = 'error' | 'done' | 'uploading' | 'removed';
export type FileValidationHandler<T> = (file: UploadFile<T>) => string | null;

export type UploadFile<StoreFile> = {
  key: StoreFileId;
  status?: 'error' | 'done' | 'uploading' | 'removed';
  file: File;
  fileData: StoreFile;
  errorMessage?: string | null;
  cancelTokenSource?: CancelTokenSource;
};

interface AbstractFilesUploadConfiguration<Response, StoreFile> {
  defaultValue: UploadFile<StoreFile>[];
  endpoint: string;
  dataMapper?: (uploadedFile: Response, beforeUploadFile: StoreFile) => StoreFile;
  fileValidation?: FileValidationHandler<StoreFile>;
}

interface AbstractFilesUploadParams<StoreFile> {
  filesToUpload: UploadFile<StoreFile>[];
  appendData?: boolean;
}

export const abstractFilesUploadFactory = <Response, StoreFile>(
  params: AbstractFilesUploadConfiguration<Response, StoreFile>,
) => {
  const {
    endpoint,
    defaultValue,
    dataMapper = (value: unknown) => value as StoreFile,
    fileValidation,
  } = params;
  const store = createStore<UploadFile<StoreFile>[]>(defaultValue);
  const uploadFilesEvent = createEvent<AbstractFilesUploadParams<StoreFile>>();
  const removeFilesEvent = createEvent<StoreFileId[]>();
  const refetchFileEvent = createEvent<StoreFileId>();
  const resetFilesEvent = createEvent();

  const validateSize = (file: UploadFile<StoreFile>): UploadFile<StoreFile> => {
    const errorMessage = fileValidation?.(file);
    const status = errorMessage ? 'error' : file.status;

    return { ...file, status, errorMessage };
  };

  const isFileToUpload = ({ status }: UploadFile<StoreFile>) => {
    const fetchedStatuses: UploadFileStatusType[] = ['done', 'error'];

    return fetchedStatuses.every((fetchedStatus) => fetchedStatus !== status);
  };

  const getFileToRefetch = (
    state: UploadFile<StoreFile>[],
    fileIdToRefetch: StoreFileId,
  ): UploadFile<StoreFile> | undefined => {
    const fileToRefetch = state.find((file) => file.key === fileIdToRefetch);

    if (fileToRefetch) {
      return validateSize({
        ...fileToRefetch,
        status: 'uploading',
        errorMessage: undefined,
      }) satisfies UploadFile<StoreFile>;
    }

    return undefined;
  };

  const uploadFile = async (file: File, cancelTokenSource?: CancelTokenSource) => {
    const formData = new window.FormData();

    formData.append('file', file);

    if (checkFileMimeType(FileUploadAccepts.VideoAll, file.type)) {
      const meta = await getVideoFileMeta(file);

      Object.entries(meta).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }

    return api.post<Response>({
      url: endpoint,
      data: formData,
      cancelToken: cancelTokenSource?.token,
    });
  };

  const uploadFileEffect = createEffect<UploadFile<StoreFile>, UploadFile<StoreFile>, AxiosError>(
    (storeData) => {
      if (storeData.status === 'error') {
        return Promise.reject(storeData);
      }

      return uploadFile(storeData.file, storeData.cancelTokenSource).then(({ data }) => {
        const mappedResult = dataMapper(data, storeData.fileData);

        return {
          ...storeData,
          fileData: mappedResult,
        };
      });
    },
  );

  store
    .on(uploadFileEffect.done, (state, { result }) => {
      return state.map((file) =>
        file.key === result.key ? { ...result, status: 'done', errorMessage: undefined } : file,
      );
    })
    .on(uploadFileEffect.fail, (state, { params: errorFile, error }) => {
      return state.map((file) =>
        file.key === errorFile.key
          ? {
              ...errorFile,
              status: 'error',
              errorMessage: getErrorResponseMessage(
                error,
                errorFile.errorMessage || `Файл не загружен ${errorFile.file.name}`,
              ),
            }
          : file,
      );
    })
    .on(removeFilesEvent, (state, fileIdsToDelete) => {
      const newState: UploadFile<StoreFile>[] = [];

      state.forEach((file) => {
        if (fileIdsToDelete?.includes(file.key)) {
          file.cancelTokenSource?.cancel();
        } else {
          newState.push(file);
        }
      });

      return newState;
    })
    .on(resetFilesEvent, (state) => {
      state.forEach((item) => item.cancelTokenSource?.cancel());
      store.reset();
    })
    .on(uploadFilesEvent, (state, { filesToUpload, appendData = true }) => {
      const storeMap = new Map(state.map((file) => [file.key, file]));

      const newFiles: UploadFile<StoreFile>[] = filesToUpload
        .filter((file) => !storeMap.has(file.key))
        .map((file) => {
          const source = axios.CancelToken.source();

          return { ...file, status: 'uploading', cancelTokenSource: source };
        });

      return appendData ? [...state, ...newFiles] : newFiles;
    })
    .watch(uploadFilesEvent, (state) => {
      state.filter(isFileToUpload).map(validateSize).forEach(uploadFileEffect);
    });

  sample({
    clock: removeFilesEvent,
    source: store,
    target: store,
    fn: (state, idsToRemove) => {
      const filesToProceed: UploadFile<StoreFile>[] = [];
      const idsToRemoveSet = new Set(idsToRemove);

      state.forEach((file) => {
        if (idsToRemoveSet.has(file.key)) {
          file.cancelTokenSource?.cancel();
        } else {
          filesToProceed.push(file);
        }
      });

      return filesToProceed;
    },
  });

  sample({
    clock: resetFilesEvent,
    source: store,
    target: store,
    fn: (state) => {
      state.forEach((file) => file.cancelTokenSource?.cancel());

      return [];
    },
  });

  sample({
    clock: refetchFileEvent,
    source: store,
    target: store,
    fn: (state, fileIdToRefetch) => {
      const fileToRefetch = getFileToRefetch(state, fileIdToRefetch);

      return fileToRefetch
        ? state.map((file) => (file.key === fileToRefetch.key ? fileToRefetch : file))
        : state;
    },
  });

  store.watch(refetchFileEvent, (state, fileIdToRefetch) => {
    const fileToRefetch = getFileToRefetch(state, fileIdToRefetch);

    if (fileToRefetch) {
      uploadFileEffect(fileToRefetch);
    }
  });

  return { store, uploadFilesEvent, removeFilesEvent, refetchFileEvent, resetFilesEvent, uploadFileEffect };
};

export type FilesUploadStore<StoreFile, Response> = ReturnType<
  typeof abstractFilesUploadFactory<StoreFile, Response>
>;
