import { parse } from 'query-string';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HistoryActionType } from '../types/history';
import { QueryParams } from '../types/query';
import { getStringifyIdentity } from '../utils';

export interface UseQueryParams<T extends QueryParams> {
  queryParams: T;
  setQueryParams: (param?: Partial<T>, action?: HistoryActionType, strict?: boolean) => void;
}

/**
 * useQueryParams hook
 *
 * @returns {UseQueryParams} { queryParams, setQueryParams }
 * Возвращает параметры из адресной строки и callback для изменения
 */
export const useQueryParams = <Params extends QueryParams>(): UseQueryParams<Params> => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const queryParams = useMemo(() => parse(search) as Params, [search]);

  /**
   * @param {QueryParams} value - параметры, которые надо изменить
   * @param {HistoryActionType} action - действие, совершаемое с history (push, replace)
   * @param {boolean} strict - строгий режим, параметры в адресной строке должны быть переданы явно в value,
   *                           служит для удаления истории параметров
   */
  const setQueryParams = useCallback(
    (value?: Partial<Params>, action = HistoryActionType.Push, strict = false) => {
      return navigate(
        {
          pathname,
          search: getStringifyIdentity({ ...parse(strict ? '' : search), ...value }),
        },
        { replace: action === HistoryActionType.Replace },
      );
    },
    [pathname, search],
  );

  return { queryParams, setQueryParams };
};
