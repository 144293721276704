import { AxiosResponse } from 'axios';

import { endpoints } from '../../endpoints';
import { BaseAttributes, CreateDictionaryRecord, Dictionaries, RecordResponse } from '../../types/models';
import { api } from '../../utils';

export type DictionaryName = { dictionaryName: Dictionaries.Name };
export type UpdateDictionaryRecord = {
  attributesToSet: Partial<BaseAttributes>;
  attributesToRemove?: string[];
  isDeleted?: boolean;
  isEnabled?: boolean;
  recordId: string;
  keyFields?: string[];
};
export type DeleteDictionaryRecord = {
  recordId: string;
};

export const generateCreateDictRecordParams = (name: string): CreateDictionaryRecord => ({
  attributes: { name },
});

export const createNewDictsRecord = <T = RecordResponse>({
  dictionaryName,
  keyFields,
  attributes,
}: DictionaryName & CreateDictionaryRecord): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.dicts.dictionaryNameRecordsNew(dictionaryName),
    data: { keyFields, attributes },
  });

export const updateDictsRecord = <T = RecordResponse>({
  recordId,
  attributesToSet,
  attributesToRemove,
  isDeleted = false,
  isEnabled = true,
}: DictionaryName & UpdateDictionaryRecord): Promise<AxiosResponse<T>> =>
  api.put({
    url: endpoints.dicts.recordsRecordId(recordId),
    data: { attributesToSet, attributesToRemove, isDeleted, isEnabled },
  });

export const deleteDictsRecord = <T = unknown>({
  recordId,
}: DictionaryName & DeleteDictionaryRecord): Promise<AxiosResponse<T>> =>
  api.delete({ url: endpoints.dicts.recordsRecordId(recordId) });
