import type { Rule } from '@vkph/ui';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import startOfToday from 'date-fns/startOfToday';
import subYears from 'date-fns/subYears';

export const MAX_BIRTHDAY_RANGE = 100;
export const MIN_BIRTHDAY_RANGE = 16;

export const dateBirthdayValidator = (date: Date) =>
  differenceInCalendarDays(subYears(new Date(), MAX_BIRTHDAY_RANGE), date) >= 0 ||
  differenceInCalendarDays(subYears(new Date(), MIN_BIRTHDAY_RANGE), date) <= 0;

export const MIN_MEETING_YEAR = 2000;
export const MAX_MEETING_YEAR = 2200;

export const dateMeetingValidator = (date: Date) =>
  startOfToday() >= date || date.getFullYear() < MIN_MEETING_YEAR || date.getFullYear() > MAX_MEETING_YEAR;

export const dateRangeEndLessThanToday: Rule = {
  validator: (_, [, endDate]: [Date, Date]) => {
    return !isPast(endDate) || isToday(endDate) ? Promise.resolve() : Promise.reject();
  },
  required: true,
  message: 'Конечная дата не может быть меньше текущей',
};
