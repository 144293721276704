import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import startOfWeek from 'date-fns/startOfWeek';

const weekArr = eachDayOfInterval({
  start: startOfWeek(new Date(), { weekStartsOn: 1 }),
  end: endOfWeek(new Date(), { weekStartsOn: 1 }),
});

export const weekDaysEn = weekArr.map((day) => {
  return format(day, 'EEEEEE', { locale: enLocale }).toLowerCase();
});

export const weekDaysRu = weekArr.map((day) => {
  return format(day, 'EEEEEE', { locale: ruLocale });
});
