import { EntityId } from '../types/blogs';
import { GroupId, UserIdModel } from '../types/models';

export const groupsEndpoints = {
  /** @deprecated */
  affiliates: () => `/api/groups/affiliates/`,
  adminRolesList: () => '/api/groups/admin_roles/list/',
  adminRolesId: (keycloakId: UserIdModel) => `/api/groups/admin_roles/${keycloakId}/`,
  adminRolesSave: () => '/api/groups/admin_roles/save/',
  bindUsersUpload: () => `/api/groups/bind_users/upload/`,
  bindUsersCheck: () => `/api/groups/bind_users/check/`,
  bindUsersUploadTaskId: (taskId: string) => `/api/groups/bind_users/${taskId}/`,
  department: () => '/api/groups/department/',
  departmentId: (id: EntityId) => `/api/groups/department/${id}/`,
  departmentUpload: () => '/api/groups/department/upload/',
  departmentCheck: () => '/api/groups/department/check/',
  detailed: (groupId: GroupId) => `/api/groups/${groupId}/detailed/`,
  idMembersItems: (id: string) => `/api/groups/${id}/members/items/`,
  list: () => '/api/groups/list/',
  organization: () => '/api/groups/organization/',
  organizations: () => '/api/groups/organizations/',
  organizationId: (id: EntityId) => `/api/groups/organization/${id}/`,
  organizationFilter: () => '/api/groups/organization/filter/',
  organizationIdOrganizationsPath: (groupId: GroupId) =>
    `/api/groups/organization/${groupId}/organizations_path/`,
  organizationUpload: () => '/api/groups/organization/upload/',
  organizationCheck: () => '/api/groups/organization/check/',
  organizationOrganizations: () => '/api/groups/organization/organizations/',
  organizationMembersAvailable: () => '/api/groups/organization/members/available/',
  membersIdMove: (keycloakId: UserIdModel) => `/api/groups/members/${keycloakId}/move/`,
  membersGroups: () => '/api/groups/members/groups/',
  membersUserIdGroups: (userId: string) => `/api/groups/members/${userId}/groups/`,
  people: () => '/api/groups/people/',
  peopleId: (id: string) => `/api/groups/people/${id}/`,
  peopleList: () => '/api/groups/people/list/',
  /** @deprecated */
  root: () => '/api/groups/root/',
  search: () => `/api/groups/search/`,
  templates: () => `/api/groups/templates/`,
  usersDownload: () => `/api/groups/users/download/`,
};
