import { CMSPageId } from './cms.model';
import { CommentId } from './comment.model';
import { CreateDateModel } from './date.model';
import { FileStorageEntryId } from './file.model';
import { NewsId } from './news.model';
import { PostId } from './post';
import { UserIdModel } from './user.model';

export type ReactionId = number;
export type EmojiUuid = string;
export type ReactionObjectId = PostId | NewsId | CommentId | FileStorageEntryId | CMSPageId;

export type ReactionsCountInfo = {
  id: string;
  count: number;
  isReacted?: boolean;
};

export type ReactionModel = {
  reactionId: EmojiUuid;
  reactionCount: number;
  isReacted: boolean;
};

export type ReactionsTypesModel = {
  id: EmojiUuid;
  weight: number;
  name: string;
  iconName: string;
};

export interface ReactableModel {
  reactions?: ReactionModel[];
}

export enum ReactionContentType {
  Post = 'entry',
  Micropost = 'micropost',
  News = 'newsentry',
  Comment = 'comment',
  Page = 'page',
  StorageObject = 'storageobject',
  Thanks = 'thanks',
  UserBadge = 'user-badge',
  TimelineRecord = 'timeline-record',
}

export interface ReactionServiceModel extends CreateDateModel {
  id: ReactionId;
  createdById: UserIdModel;
  objectId: ReactionObjectId;
  contentType: ReactionContentType;
  emojiUuid: EmojiUuid;
}
