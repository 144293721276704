import { endpoints } from '../../endpoints';
import { ColorTheme } from '../../types/theme';
import { api } from '../../utils';

export type SaveThemeColorsParams = {
  colors: ColorTheme[];
};

export const saveThemeColors = <T>({ colors = [] }: SaveThemeColorsParams) =>
  api.post<T>({
    url: endpoints.pages.colors(),
    data: colors,
  });
