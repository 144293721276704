import type { EventId } from './event.model';
import type { FileId, FileStorageEntryId } from './file.model';
import type { PollId } from './poll.model';

export enum AttachmentTypes {
  File = 'file',
  Image = 'image',
  Video = 'video',
  Embed = 'embed',
}

export type AttachmentEntryId = number;
export type AttachmentEntityId = PollId | FileId | EventId | FileStorageEntryId;

export type AttachmentDetail = {
  name: string;
  attachmentFileUrl: string;
  size: number;
};
