import { endpoints } from '../../endpoints';
import {
  AttachmentEntryId,
  FileModel,
  FileToUploadModelWithoutData,
  FileStorageEntryId,
  FileStorageFileModel,
  FileStorageEntryModel,
  FileStorageEntryVersionId,
  FileStorageEntryRevision,
  FileStorageCategoryId,
  FileStorageCategoryModel,
  FileStorageEntryFavoriteModel,
  FileStorageCropImageModel,
  FileStorageFolderUserRolesModel,
  FileStorageFolderUserRoleModel,
  FileStorageFolderVideoExtensions,
  FileStorageFolderImageExtensions,
  FileStorageFolderExtensionsModel,
  TagId,
} from '../../types/models';
import { api } from '../../utils';
import { FileStorageFileShareParams } from './filestorage';

export enum FileStorageApiVersions {
  v2,
  v3,
}

export type GetFileFromStorageParamsV2 = {
  id: AttachmentEntryId;
};

export type BaseFileStorageIdParams = {
  id: FileStorageEntryId;
};

export type UploadFileStorageAttachmentParams = {
  file: File;
  onUploadProgress?: (percent: number) => void;
  apiVersion?: FileStorageApiVersions;
};

export type UploadFileStorageMultipleAttachmentsParams = {
  files: FileToUploadModelWithoutData[];
  onUploadProgress?: (id: string, percent: number) => void;
  apiVersion: FileStorageApiVersions;
};

export interface CropImageUploadBaseParams {
  settings: {
    x?: number;
    y?: number;
    height?: number;
    width?: number;
  };
}

export interface FileStorageCropImageUploadParams extends CropImageUploadBaseParams {
  fileStorageEntryId: FileStorageEntryId;
}

export interface MoveFileStorageEntryParams extends BaseFileStorageIdParams {
  parentId: FileStorageEntryId;
}

export interface DeleteFileStorageEntriesParams {
  objectIds: FileStorageEntryId[];
}

export interface RenameFileStorageEntryParams extends BaseFileStorageIdParams {
  name: string;
}

export type DeleteFileStorageCategoryParams = {
  categoryId: FileStorageCategoryId;
};

export type CreateFileStorageCategoryParams = {
  rootFolderId: FileStorageEntryId;
  name: string;
};

// TODO:: Добавить категории когда будет готова реализация
export type PutFileStorageEntryParams = {
  id: FileStorageEntryId;
  parent?: FileStorageEntryId;
  name?: string;
  description?: string;
  tags?: TagId[];
  categories?: FileStorageCategoryId[];
  versionId?: FileStorageEntryVersionId;
};

export type CreateFileStorageFolderParams = {
  parent: FileStorageEntryId;
  name: string;
};

export type UpdateFileStorageFolderParams = {
  id: FileStorageEntryId;
  name: string;
};

export type TransferFileStorageEntriesParams = {
  destination: FileStorageEntryId;
  objectIds: FileStorageEntryId[];
};

export interface PatchFileStorageCategoryParams extends Omit<Partial<FileStorageCategoryModel>, 'id'> {
  id: FileStorageCategoryId;
}

export type GetFilesStorageParams = {
  ids: AttachmentEntryId[];
};

export type AvailablePatchFileStorageParams = RenameFileStorageEntryParams | MoveFileStorageEntryParams;

export type GetFileStorageCategoriesParams = { entryId: FileStorageEntryId };

export const uploadFileStorageAttachment = async <T = FileModel>(
  params: UploadFileStorageAttachmentParams,
) => {
  const { file, onUploadProgress, apiVersion = FileStorageApiVersions.v2 } = params;
  const formData = new window.FormData();

  formData.append('file', file);

  let url = endpoints.filestorage.v2FileUpload();

  if (apiVersion === FileStorageApiVersions.v3) {
    url = endpoints.filestorage.fileUpload();
  }

  return api
    .post<T>({
      url,
      data: formData,
      onUploadProgress:
        onUploadProgress &&
        ((progressEvent) => {
          onUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1)));
        }),
    })
    .then((response) => response.data);
};

export const cropFileStorageImage = (params: FileStorageCropImageUploadParams) =>
  api.post<FileStorageCropImageModel>({
    url: endpoints.filestorage.fileImageIdCropImage(params.fileStorageEntryId),
    data: { settings: params.settings },
  });

export const getFileFromStorage = (params: GetFileFromStorageParamsV2) =>
  api.get<FileModel>({
    url: endpoints.filestorage.v2FileId(params.id),
  });

export const deleteFileStorageEntry = (data: DeleteFileStorageEntriesParams) =>
  api.delete({
    url: endpoints.filestorage.object(),
    data,
  });

export const patchFileStorageEntry = ({ id, ...data }: AvailablePatchFileStorageParams) =>
  api.patch({
    url: endpoints.filestorage.objectEntryId(id),
    data,
  });

export const createFileStorageFolder = (data: CreateFileStorageFolderParams) =>
  api.post<FileStorageEntryModel>({
    url: endpoints.filestorage.folderNew(),
    data,
  });

export const patchFileStorageFolder = ({ id, ...data }: UpdateFileStorageFolderParams) =>
  api.patch<FileStorageEntryModel>({
    url: endpoints.filestorage.folderEntryId(id),
    data,
  });

export const putFileStorageEntry = ({ id, ...data }: PutFileStorageEntryParams) =>
  api.put<FileStorageFileModel>({
    url: endpoints.filestorage.fileEntryIdRevision(id),
    data,
  });

interface BaseFileStorageEntryVersionParams extends BaseFileStorageIdParams {
  revision: FileStorageEntryRevision;
}

export type DeleteFileStorageEntryVersionParams = BaseFileStorageEntryVersionParams;
export type UndeleteFileStorageEntryVersionParams = BaseFileStorageEntryVersionParams;
export type RestoreFileStorageEntryVersionParams = BaseFileStorageEntryVersionParams;

export const deleteFileStorageEntryVersion = <T = void>({
  id,
  revision,
}: DeleteFileStorageEntryVersionParams) =>
  api.delete<T>({
    url: endpoints.filestorage.fileEntryIdVersionsRevision(id, revision),
  });

export const undeleteFileStorageEntryVersion = ({ id, revision }: UndeleteFileStorageEntryVersionParams) =>
  api.post<string>({
    url: endpoints.filestorage.fileEntryIdVersionsRevisionUndelete(id, revision),
  });

export const restoreFileStorageEntryVersion = <T = void>({
  id,
  revision,
}: RestoreFileStorageEntryVersionParams) =>
  api.post<T>({
    url: endpoints.filestorage.fileEntryIdVersionsRevisionRestore(id, revision),
  });

export const transferFileStorageEntries = (data: TransferFileStorageEntriesParams) =>
  api.post<void>({
    url: endpoints.filestorage.objectTransfer(),
    data,
  });

export const createFileStorageCategory = (data: CreateFileStorageCategoryParams) =>
  api.post<FileStorageCategoryModel>({
    url: endpoints.filestorage.categories(),
    data,
  });

export const deleteFileStorageCategory = ({ categoryId }: DeleteFileStorageCategoryParams) =>
  api.delete<void>({
    url: endpoints.filestorage.categoriesCategoryId(categoryId),
  });

export const patchFileStorageCategory = ({ id, ...data }: PatchFileStorageCategoryParams) =>
  api.patch<FileStorageCategoryModel>({
    url: endpoints.filestorage.categoriesCategoryId(id),
    data,
  });

export const addObjectToFavorite = ({ id }: BaseFileStorageIdParams) =>
  api.post<FileStorageEntryFavoriteModel>({
    url: endpoints.filestorage.objectEntryIdFavorite(id),
  });

export const removeObjectFromFavorite = ({ id }: BaseFileStorageIdParams) =>
  api.delete<void>({
    url: endpoints.filestorage.objectEntryIdFavorite(id),
  });

export type GetFileStorageFolderUserRolesParams = GetFileStorageCategoriesParams;
export interface SetFileStorageFolderUserRoleParams
  extends GetFileStorageFolderUserRolesParams,
    Pick<FileStorageFolderUserRolesModel, 'defaultRole'> {
  userRoles: Pick<FileStorageFolderUserRoleModel, 'userUid' | 'role'>[];
  eraseExisting?: boolean;
}

export const setFileStorageFolderUserRole = (data: SetFileStorageFolderUserRoleParams) => {
  const { entryId, ...restData } = data;

  return api.post<void>({
    url: endpoints.filestorage.folderEntryIdSetRole(entryId),
    data: restData,
  });
};

export type GetFileStorageFolderExtensionsParams = GetFileStorageCategoriesParams;
export interface SetFileStorageFolderExtensionsParams extends GetFileStorageFolderExtensionsParams {
  video: FileStorageFolderVideoExtensions[];
  image: FileStorageFolderImageExtensions[];
}

export const setFileStorageFolderExtensions = (data: SetFileStorageFolderExtensionsParams) => {
  const { entryId, ...restData } = data;

  return api.put<FileStorageFolderExtensionsModel>({
    url: endpoints.filestorage.folderEntryIdExtensions(entryId),
    data: restData,
  });
};

export const fileStorageFileShare = (data: FileStorageFileShareParams) =>
  api.post<void>({
    url: endpoints.filestorage.fileShare(),
    data,
  });
