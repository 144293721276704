import { generateRandomString } from '@vkph/ui/utils/generateRandomString';

import { FileToUploadModel, FileToUploadModelWithoutData } from '../types/models';

export function readFileToStringPromise(file: File, shouldParseData: true): Promise<FileToUploadModel>;
export function readFileToStringPromise(
  file: File,
  shouldParseData: false,
): Promise<FileToUploadModelWithoutData>;

export function readFileToStringPromise(file: File, shouldParseData = false) {
  if (shouldParseData) {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();

      reader.onload = (e) => {
        const data = e.target?.result?.toString();

        if (data) {
          const fileToUpload: FileToUploadModel = {
            id: generateRandomString(),
            filename: file.name,
            rawFile: file,
            data,
          };

          resolve(fileToUpload);
        } else {
          reject();
        }
      };

      reader.onerror = reject;
      reader.onabort = reject;

      reader.readAsDataURL(file);
    });
  }

  return Promise.resolve({
    id: generateRandomString(),
    filename: file.name,
    rawFile: file,
  } as FileToUploadModelWithoutData);
}
