import { endpoints } from '../../endpoints';
import { GetSummaryAnalyticsReportParams, SummaryAnalyticsReportType } from '../../types';
import { api, getTimeZoneValue, buildEndpointWithQueryParams } from '../../utils';

const getReportEndpoint = (params: GetSummaryAnalyticsReportParams) => {
  const { report, ...queryParams } = params;
  const isMetricsReports = [
    SummaryAnalyticsReportType.Dau,
    SummaryAnalyticsReportType.Mau,
    SummaryAnalyticsReportType.Sticky,
  ].includes(report);

  return buildEndpointWithQueryParams(
    isMetricsReports ? endpoints.reports.metricsReports(report) : endpoints.reports.blogCountsReports(report),
    queryParams,
  );
};

export const getReport = (params: GetSummaryAnalyticsReportParams) => {
  return api.get<Blob>({
    url: getReportEndpoint(params),
    responseType: 'blob',
    headersQuery: {
      'X-Iana-Timezone': getTimeZoneValue(),
    },
  });
};
