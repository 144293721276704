import type {
  CMSPageId,
  CMSPageSlugId,
  CMSSectionId,
  CMSSectionSlugId,
  CMSPageFeedbackId,
  UserIdModel,
} from '../types/models';

export const cmsEndpoints = {
  favoritePages: () => '/api/cms/favorite/pages/',
  favoritePagesSlugId: (slugId: CMSSectionSlugId) => `/api/cms/favorite/pages/${slugId}/`,
  pages: () => '/api/cms/pages/',
  pagesId: (pageId: CMSPageId) => `/api/cms/pages/${pageId}/`,
  pagesIdFeedback: (pageId: CMSPageId) => `/api/cms/pages/${pageId}/feedback/`,
  pagesSlugSectionSlugId: (slugId: CMSSectionSlugId) => `/api/cms/pages/slug/${slugId}/`,
  pagesSlugSectionSlugIdBreadcrumbs: (slugId: CMSSectionSlugId) =>
    `/api/cms/pages/slug/${slugId}/breadcrumbs/`,
  pagesSlugPageSlugIdDetail: (pageSlugId: CMSPageSlugId) => `/api/cms/pages/slug/${pageSlugId}/detail/`,
  pagesSlugSlugIdPopular: (slugId: CMSSectionSlugId) => `/api/cms/pages/slug/${slugId}/popular/`,
  pagesSectionIdUsers: (sectionId: CMSSectionId) => `/api/cms/pages/${sectionId}/users/`,
  pagesSectionIdPaths: (sectionId: CMSSectionId) => `/api/cms/pages/${sectionId}/paths/`,
  feedback: () => `/api/cms/feedback/`,
  feedbackId: (feedbackId: CMSPageFeedbackId) => `/api/cms/feedback/${feedbackId}/`,
  feedbackAuthors: () => '/api/cms/feedback/authors/',
  roles: () => '/api/cms/roles/',
  rolesList: () => `/api/cms/roles/list/`,
  rolesSave: () => `/api/cms/roles/save/`,
  rolesUserId: (userId: UserIdModel) => `/api/cms/roles/${userId}/`,
  rolesUsers: () => `/api/cms/roles/users/`,
  sections: () => '/api/cms/sections/',
  sectionsId: (sectionId: CMSSectionId) => `/api/cms/sections/${sectionId}/`,
  sectionsIdUsers: (sectionId: CMSSectionId) => `/api/cms/sections/${sectionId}/users/`,
  sectionsIdPaths: (sectionId: CMSSectionId) => `/api/cms/sections/${sectionId}/paths/`,
  sectionsIdReport: (sectionId: CMSSectionId) => `/api/cms/sections/${sectionId}/report/`,
  sectionsSlugId: (slugId: CMSSectionSlugId) => `/api/cms/sections/slug/${slugId}/`,
  sectionsSlugIdBreadcrumbs: (slugId: CMSSectionSlugId) => `/api/cms/sections/slug/${slugId}/breadcrumbs/`,
  sectionsSlugIdChildren: (slugId: CMSSectionSlugId) => `/api/cms/sections/slug/${slugId}/children/`,
  sectionsSlugIdTree: (slugId: CMSSectionSlugId) => `/api/cms/sections/slug/${slugId}/tree/`,
};
