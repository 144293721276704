import { EditorValue } from '../editor';
import { AttachmentEntityId } from './attachment.model';
import { BlogFullModel, PermissionsV2Enum } from './blog.model';
import { CreateDateModel, PublishDateModel } from './date.model';
import { Dictionaries } from './dictionary.model';
import { EventModel } from './event.model';
import { FileModel, FileStorageEntryUrl, FileStorageFileModel } from './file.model';
import { GamificationItemTypes } from './gamification.model';
import { NewsArticle } from './news.model';
import { PollModel } from './poll.model';
import { SkillType } from './profile.model';
import { ReactableModel } from './reactions.model';
import { PostSearchModel } from './search.model';
import { BaseTag } from './tags.model';
import { FullNameModel, Gender, UserIdModel, UserModel } from './user.model';

export enum PostTypes {
  Entry = 'entry',
  Micropost = 'micropost',
  Simple = 'simple',
  Stories = 'stories',
  GamificationEntry = 'gamificationentry',
  ProfileEntry = 'profileentry',
}

export enum PostStatuses {
  FlagArchive = 0,
  FlagPublished = 1,
  FlagNew = 2,
  FlagDelete = 3,
  AwaitingApprove = 4,
  ApproveInProgress = 5,
  Decline = 6,
}

export enum PostSettingsVisibilityType {
  All = 'all',
  Followers = 'followers',
}

export type PostSettings = {
  isReacted: boolean;
  isComments: boolean;
  visibility: PostSettingsVisibilityType;
};

interface Owner {
  id: UserIdModel;
  gender: Gender;
  avatar: string;
  fullName: FullNameModel;
  keycloakUser: UserModel['keycloakUser'];
}

export type PostId = number;

interface PostCommonFields<Tags extends BaseTag = Dictionaries.Dict>
  extends CreateDateModel,
    PublishDateModel {
  id: PostId;
  type: PostTypes;
  permissionsV2: Record<PermissionsV2Enum, boolean>;
  authorId: string;
  author: UserModel;
  commentsCount: number;
  owner: Owner;
  settings?: PostSettings;
  tags: Tags[];
  flag: PostStatuses;
}

export interface PostOrdinaryEntryBase extends ReactableModel, PostCommonFields {
  blogFeedback: boolean;
  blogsFull: BlogFullModel[];
  blogs: BlogFullModel[] | BlogFullModel;

  /**
   * Эта строка - JSON, в котором два поля - тип контента (textType) и сам контент (textValue).
   * Для новых постов в типе будет признак того, каким способом показывать контент (HTML, markdown и т.п.),
   * и какой редактор открывать при редактировании
   * Если в поле невалидный JSON, счиатем, что это строка. Для обратной совместимости
   */
  cutText: string;
  draft: boolean;
  favorite: boolean;
  image?: FileStorageEntryUrl /** @deprecated use fileStorageImageUrl instead */;
  isAdvert: boolean;
  isPinned: boolean;
  title?: string;
  viewsCount: number;
  body?: EditorValue;
}

export interface MicropostModel extends ReactableModel, PostCommonFields {
  createdAt: string;
  updatedAt: string;

  /**
   * Эта строка - JSON, в котором два поля - тип контента (textType) и сам контент (textValue).
   * Для новых постов в типе будет признак того, каким способом показывать контент (HTML, markdown и т.п.),
   * и какой редактор открывать при редактировании
   * Если в поле невалидный JSON, счиатем, что это HTML. Для обратной совместимости
   */
  text: string;
  favorite: boolean;
  type: PostTypes.Micropost;
  title?: string;
  body?: EditorValue;
  updatePeriodMinutes?: number;
  remainingUpdateTimeMinutes?: number;
  attachmentsCount: number;
}

export interface PostBlogModel extends PostOrdinaryEntryBase {
  type: PostTypes.Entry;
  attachmentsCount: number;
}

export interface PostNewsModel
  extends ReactableModel,
    PostCommonFields,
    Omit<NewsArticle, 'tags' | 'settings'> {
  type: PostTypes.Simple | PostTypes.Stories;
  image: FileStorageEntryUrl;
}

export interface PostGamificationModel extends PostOrdinaryEntryBase {
  type: PostTypes.GamificationEntry;
  entityType: GamificationItemTypes;
  entityId: number;
}

interface ProfileExtraInfo {
  id: string;
  name: string;
  entityId: number;
}

export interface PostProfileEntryModel extends ReactableModel, PostCommonFields {
  createdAt: string;
  updatedAt: string;
  text: string;
  entityType: SkillType;
  extraInfo: ProfileExtraInfo;
  type: PostTypes.ProfileEntry;
}

export type PostBasicModel = PostBlogModel | MicropostModel;
export type PostTextModel = PostBasicModel | PostNewsModel;
export type PostTextExtendedModel = PostTextModel | PostSearchModel;
export type PostModel = PostTextModel | PostProfileEntryModel | PostGamificationModel;

export type PostFormAttachmentIdTemp = string;
export type PostFormAttachmentId = number | PostFormAttachmentIdTemp;
export type PostAttachedEntity = PollModel | FileModel | EventModel | FileStorageFileModel;

/** При добавлении нового типа, не забыть про attachmentGroupsPriority, иначе атачменты отображаться не будут */
export enum PostAttachmentTypes {
  Poll = 'poll',
  File = 'file',
  Image = 'image',
  Event = 'event',
}

export type BasicPostAttachmentModel<T extends PostAttachedEntity = PostAttachedEntity> = {
  attachmentId: AttachmentEntityId;
  attachmentType: PostAttachmentTypes;
  attachedEntity: T;
};

export interface PostAttachmentModel<T extends PostAttachedEntity = PostAttachedEntity>
  extends BasicPostAttachmentModel<T> {
  id: PostFormAttachmentId;
  objectId?: number;
  contentType?: number | string;
}
