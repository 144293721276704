import {
  EventsAllowedPermission,
  GroupsAllowedPermission,
  CmsAllowedPermission,
  NewsAllowedPermission,
  PermissionModel,
  PermissionsModel,
  SurveyAllowedPermission,
  CmsPersonalPagesAllowedPermission,
  IdeasAllowedPermission,
} from './models';

export enum ServiceRolesNames {
  News = 'news',
  Survey = 'survey',
  Events = 'events',
  Groups = 'groups',
  CMSGroupsPages = 'cms-groups-pages',
  CMSPersonalPages = 'cms-personal-pages',
  Ideas = 'ideas',
}

export type ServiceRolesPermissionModel = PermissionsModel;
export type ServiceRolesPermissionModelList = ServiceRolesPermissionModel[];

export type ServiceRolesServicePermissionModel<T> = PermissionModel<T>[];
export type ServiceRolesPermissionsModel = {
  [ServiceRolesNames.News]: ServiceRolesServicePermissionModel<NewsAllowedPermission>;
  [ServiceRolesNames.Events]: ServiceRolesServicePermissionModel<EventsAllowedPermission>;
  [ServiceRolesNames.Survey]: ServiceRolesServicePermissionModel<SurveyAllowedPermission>;
  [ServiceRolesNames.Groups]: ServiceRolesServicePermissionModel<GroupsAllowedPermission>;
  [ServiceRolesNames.CMSGroupsPages]: ServiceRolesServicePermissionModel<CmsAllowedPermission>;
  [ServiceRolesNames.CMSPersonalPages]: ServiceRolesServicePermissionModel<CmsPersonalPagesAllowedPermission>;
  [ServiceRolesNames.Ideas]: ServiceRolesServicePermissionModel<IdeasAllowedPermission>;
};
