import {
  FileStorageCategoryId,
  FileStorageEntryId,
  FileStorageEntryRevision,
  AttachmentEntryId,
} from '../types/models';

const optional = (revision?: FileStorageEntryRevision) => (revision ? `${revision}/` : '');

export const filestorageEndpoints = {
  v2FileUpload: () => '/api/filestorage/v2/file/upload/',
  v2FileId: (id: AttachmentEntryId) => `/api/filestorage/v2/file/${id}/`, // TODO: используется только для poll, удалить после перехода на v3
  folderEntryIdCategories: (entryId: FileStorageEntryId) =>
    `/api/filestorage/v3/folder/${entryId}/categories/`,
  categories: () => '/api/filestorage/v3/categories/',
  categoriesCategoryId: (categoryId: FileStorageCategoryId) =>
    `/api/filestorage/v3/categories/${categoryId}/`,
  fileImageIdCropImage: (imageId: FileStorageEntryId) => `/api/filestorage/v3/file/${imageId}/crop-image/`,
  fileEntryIdRevision: (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    `/api/filestorage/v3/file/${entryId}/${optional(revision)}`,
  fileEntryIdVersionsRevision: (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    `/api/filestorage/v3/file/${entryId}/versions/${optional(revision)}`,
  fileEntryIdVersionsRevisionRestore: (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    `/api/filestorage/v3/file/${entryId}/versions/${revision}/restore/`,
  fileEntryIdVersionsRevisionUndelete: (entryId: FileStorageEntryId, revision?: FileStorageEntryRevision) =>
    `/api/filestorage/v3/file/${entryId}/versions/${revision}/undelete/`,
  fileShare: () => '/api/filestorage/v3/file/share/',
  fileUpload: () => '/api/filestorage/v3/file/upload/',
  filesSearch: () => '/api/filestorage/v3/files/search/',
  folderNew: () => '/api/filestorage/v3/folder/new/',
  folderSharedFolder: () => '/api/filestorage/v3/folder/shared_folder/',
  folderEntryId: (entryId: FileStorageEntryId) => `/api/filestorage/v3/folder/${entryId}/`,
  folderEntryIdObjects: (entryId: FileStorageEntryId) => `/api/filestorage/v3/folder/${entryId}/objects/`,
  folderEntryIdFilesSearch: (entryId: FileStorageEntryId) =>
    `/api/filestorage/v3/folder/${entryId}/files/search/`,
  folderEntryIdAllowedCategories: (entryId: FileStorageEntryId) =>
    `/api/filestorage/v3/folder/${entryId}/allowed-categories/`,
  folderEntryIdUserRoles: (entryId: FileStorageEntryId) =>
    `/api/filestorage/v3/folder/${entryId}/user-roles/`,
  folderEntryIdSetRole: (entryId: FileStorageEntryId) => `/api/filestorage/v3/folder/${entryId}/set-role/`,
  folderEntryIdExtensions: (entryId: FileStorageEntryId) =>
    `/api/filestorage/v3/folder/${entryId}/extensions/`,
  object: () => '/api/filestorage/v3/object/',
  objectTransfer: () => '/api/filestorage/v3/object/transfer/',
  objectEntryId: (entryId: FileStorageEntryId) => `/api/filestorage/v3/object/${entryId}/`,
  objectEntryIdHistory: (entryId: FileStorageEntryId) => `/api/filestorage/v3/object/${entryId}/history/`,
  objectEntryIdFavorite: (entryId: FileStorageEntryId) => `/api/filestorage/v3/object/${entryId}/favorite/`,
  objectEntryIdHierarchy: (entryId: FileStorageEntryId) => `/api/filestorage/v3/object/${entryId}/hierarchy/`,
};
