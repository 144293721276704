import type { EventId, NewsId, NewsType, PostId, PostTypes, SendingSurveyId, TimelineId } from './models';

export type LongreadBaseProps = {
  onClose: () => void;
};

export enum LongreadTypes {
  Post = 'post',
  Events = 'events',
  Surveys = 'surveys',
  News = 'news',
  Timeline = 'timeline',
}

export type PostLongreadParams = {
  type: LongreadTypes.Post;
  id: PostId;
  postType: PostTypes.Entry | PostTypes.Micropost;
};

export type EventsLongreadParams = {
  type: LongreadTypes.Events;
  id: EventId;
};

export type SurveysLongreadParams = {
  type: LongreadTypes.Surveys;
  id: SendingSurveyId;
};

export type NewsLongreadParams = {
  type: LongreadTypes.News;
  id: NewsId;
  newsType: NewsType;
};

export type TimelineLongreadParams = {
  type: LongreadTypes.Timeline;
  id: TimelineId;
};

export type LongreadParams =
  | PostLongreadParams
  | EventsLongreadParams
  | SurveysLongreadParams
  | NewsLongreadParams
  | TimelineLongreadParams;
