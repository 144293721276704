import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { BaseFieldParams, DictPaginated, OrderingParams, PaginationParams } from '../../types/api';
import {
  CMSPageId,
  CMSPageModel,
  CMSRolesUserModel,
  CMSRolesUserRole,
  CMSSectionId,
  CMSSectionModel,
  CMSViewType,
  TagId,
  UserProfileModel,
} from '../../types/models';
import { buildEndpointWithQueryParams, EndpointQueryParamsBaseType } from '../../utils';
import { abstractStorageFactory, abstractStoreFactory } from '../../utils/effector';
import {
  createPage,
  createSection,
  deletePage,
  deleteSection,
  getSection,
  getSectionReport,
  updatePage,
  updateSection,
} from './api';

type PathName = string;
type BaseCMSSectionsPagesParams = Partial<PaginationParams> & Pick<BaseFieldParams, 'query' | 'ordering'>;
export interface GetCMSSectionsParams extends BaseCMSSectionsPagesParams {
  isOnlyRoot: boolean;
  parentId: CMSSectionId;
  showAll: boolean;
  showAllTree: boolean;
  showSuperGroupPage: boolean;
  createdBy: string[];
  path: string[];
}

export enum CMSPagesSearchField {
  All = 'all',
  Name = 'name',
}
interface GetCMSPagesParams extends BaseCMSSectionsPagesParams {
  sectionId: CMSSectionId;
  showAllTree: boolean;
  searchField: CMSPagesSearchField;
  isActual: boolean;
  createdBy: string[];
  updatedBy: string[];
  path: string[];
  type: string;
}
export type GetPageParams = Pick<CMSPageModel, 'id'>;
export interface CreatePageParams
  extends Pick<CMSPageModel, 'id' | 'url' | 'name' | 'extraData' | 'body' | 'isActual'> {
  sectionId?: CMSSectionId;
  type?: string;
  tags: TagId[];
}

export type UpdatePageParams = GetPageParams & Partial<CreatePageParams>;
export type GetSectionParams = Pick<CMSSectionModel, 'id'>;
export type CreateSectionParams = Pick<CMSSectionModel, 'parentId' | 'url' | 'name' | 'extraData'>;
export type UpdateSectionParams = GetSectionParams & CreateSectionParams;

export enum ReportTypes {
  Rating = 'pages_rating',
  List = 'pages_list',
}

export type GetSectionReportParams = {
  sectionId: CMSSectionId;
  reportType: ReportTypes;
  dateFrom?: string;
  dateTo?: string;
  tags?: string;
  isAuthor?: boolean;
};
type CMSRolesParams = Partial<PaginationParams>;
interface CMSRolesUsersParams extends Partial<PaginationParams> {
  isOnlyRoot: boolean;
}

export const getCMSSectionsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CMSSectionModel>,
    CMSSectionModel[],
    CMSSectionModel[],
    Partial<GetCMSSectionsParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.cms.sections(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const paramsStore = abstractStoreFactory<Partial<GetCMSSectionsParams>>({});

  return { storage, paramsStore };
};

export type GetCMSSectionsStorage = ReturnType<typeof getCMSSectionsStorage>;

export const getCMSSectionStorage = () => {
  const storage = abstractStorageFactory<CMSSectionModel, CMSSectionModel, null, GetSectionParams>({
    endpointBuilder: ({ id }) => endpoints.cms.sectionsId(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const createSectionEffect = createEffect<CreateSectionParams, CMSSectionModel, AxiosError>((params) =>
  createSection<CMSSectionModel>(params).then(({ data }) => data),
);

export const getSectionEffect = createEffect<CMSSectionId, CMSSectionModel, AxiosError>((sectionId) =>
  getSection<CMSSectionModel>(sectionId).then(({ data }) => data),
);

export const updateSectionEffect = createEffect<UpdateSectionParams, CMSSectionModel, AxiosError>((params) =>
  updateSection<CMSSectionModel>(params).then(({ data }) => data),
);

export const deleteSectionEffect = createEffect<CMSSectionId, unknown, AxiosError>(deleteSection);

export const getCMSPagesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CMSPageModel>,
    CMSPageModel[],
    CMSPageModel[],
    Partial<GetCMSPagesParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.cms.pages(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  const paramsStore = abstractStoreFactory<Partial<GetCMSPagesParams>>({});

  return { storage, paramsStore };
};

export type GetCMSPagesStorage = ReturnType<typeof getCMSPagesStorage>;

export const getCMSPageStorage = () => {
  const storage = abstractStorageFactory<CMSPageModel, CMSPageModel, null, GetPageParams>({
    endpointBuilder: ({ id }) => endpoints.cms.pagesId(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const deletePageEffect = createEffect<CMSPageId, unknown, AxiosError>(deletePage);

export const createPageEffect = createEffect<CreatePageParams, CMSPageModel, AxiosError>((params) =>
  createPage<CMSPageModel>(params).then(({ data }) => data),
);

export const updatePageEffect = createEffect<UpdatePageParams, CMSPageModel, AxiosError>((params) =>
  updatePage<CMSPageModel>(params).then(({ data }) => data),
);

export const getCMSRolesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CMSRolesUserRole>,
    CMSRolesUserRole[],
    CMSRolesUserRole[],
    CMSRolesParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.cms.roles(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

export type GetCMSRolesStorage = ReturnType<typeof getCMSRolesStorage>;

export const getCMSRolesUsersStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CMSRolesUserModel>,
    CMSRolesUserModel[],
    CMSRolesUserModel[],
    Partial<CMSRolesUsersParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.cms.rolesUsers(), params),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

export const getSectionReportEffect = createEffect<GetSectionReportParams, Blob, AxiosError>((params) =>
  getSectionReport(params).then(({ data }) => data),
);

interface CMSSectionsUsersParams
  extends Partial<PaginationParams>,
    EndpointQueryParamsBaseType,
    Pick<BaseFieldParams, 'query'> {
  sectionId: CMSSectionId;
}

export const getCMSSectionsUsersStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<UserProfileModel>,
    UserProfileModel[],
    UserProfileModel[],
    CMSSectionsUsersParams
  >({
    endpointBuilder: ({ sectionId, ...params }) => {
      return buildEndpointWithQueryParams(endpoints.cms.sectionsIdUsers(sectionId), params);
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

interface CMSPagesUsersParams extends CMSSectionsUsersParams {
  searchField?: OrderingParams.CreatedByAsc | OrderingParams.UpdatedByAsc;
}

export const getCMSPagesUsersStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<UserProfileModel>,
    UserProfileModel[],
    UserProfileModel[],
    CMSPagesUsersParams
  >({
    endpointBuilder: ({ sectionId, ...params }) => {
      return buildEndpointWithQueryParams(endpoints.cms.pagesSectionIdUsers(sectionId), params);
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

interface CMSPathsParams
  extends Partial<PaginationParams>,
    EndpointQueryParamsBaseType,
    Pick<BaseFieldParams, 'query'> {
  type: CMSViewType;
  sectionId: CMSSectionId;
}

export const getCMSPathsStorage = () => {
  const storage = abstractStorageFactory<DictPaginated<PathName>, PathName[], PathName[], CMSPathsParams>({
    endpointBuilder: ({ type, sectionId, ...params }) => {
      const endpoint =
        type === CMSViewType.Page ? endpoints.cms.pagesSectionIdPaths : endpoints.cms.sectionsIdPaths;

      return buildEndpointWithQueryParams(endpoint(sectionId), params);
    },
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  return { storage };
};

export type GetCMSRolesUsersStorage = ReturnType<typeof getCMSRolesUsersStorage>;
