import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';

interface UseClipboardOptions {
  onSuccess?: () => void;
}

// TODO: После обновления antd v5 удалить и использовать https://github.com/ant-design/ant-design/blob/5.16.1/components/typography/hooks/useCopyClick.ts
export const useClipboard = (value: string, options?: UseClipboardOptions) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      options?.onSuccess?.();
    }
  }, [isCopied]);

  return [
    () => {
      const didCopy = copy(value);

      setIsCopied(didCopy);
    },
  ];
};
