import get from 'lodash/get';

import { RecordResponse } from '../types/models';

/**
 * Базовая схема
 *
 * @param idPath - путь до id через точку
 * @param namePath - путь до name через точку
 */
type DictScheme = { idPath: string; namePath: string };

const DEFAULT_OPTION_SCHEME = {
  idPath: 'id',
  namePath: 'name',
};

export const generateDictRecord = <T extends object>(
  object: T,
  scheme: DictScheme = DEFAULT_OPTION_SCHEME,
): RecordResponse => {
  return {
    id: get(object, scheme.idPath),
    attributes: { name: get(object, scheme.namePath) },
  };
};
