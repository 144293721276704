import { matchPath } from 'react-router-dom';

/**
 * Соглашение по неймингу
 *
 * [PluralEntityName]List - список (например, BlogsList)
 * [EntityName]Create - форма создания (например, BlogCreate)
 * [EntityName]Edit - форма редактирования (например, BlogEdit)
 * [EntityName]View - страница просмотра (например, BlogView)
 * [EntityName][ActionOrEntityName] - страницы для другого действия
 *                                    или подстраница (например, PollVote или BlogSubscribers)
 */
export enum RouteNames {
  Home = 'Home',
  SignIn = 'SignIn',
  Profile = 'Profile',
  ProfileEdit = 'ProfileEdit',
  ProfileFolder = 'ProfileFolder',
  ProfileFile = 'ProfileFile',
  ProfileRewardsProgress = 'ProfileRewardsProgress',

  AdminOrgStructure = 'AdminOrgStructure',
  AdminContentManagement = 'AdminContentManagement',
  AdminContentManagementGroupsPages = 'AdminContentManagementGroupsPages',
  AdminContentManagementGroupView = 'AdminContentManagementGroupView',
  AdminContentManagementSurveysAnalyticsView = 'AdminContentManagementSurveysAnalyticsView',
  AdminContentManagementCmsPageFeedbackView = 'AdminContentManagementCmsPageFeedbackView',
  AdminAdministration = 'AdminAdministration',
  AdminHealthCheck = 'AdminHealthCheck',
  AdminSummaryAnalytics = 'AdminSummaryAnalytics',

  News = 'News',
  GroupsList = 'GroupsList',
  GroupView = 'GroupView',
  GroupSearch = 'GroupSearch',
  GroupFolder = 'GroupFolder',
  GroupFile = 'GroupFile',

  Structure = 'Structure',

  Calendar = 'Calendar',

  CmsView = 'CmsView',

  MeetingRooms = 'MeetingRooms',

  Search = 'Search',
  AdvancedSearch = 'AdvancedSearch',
  AdvancedSearchFileView = 'AdvancedSearchFileView',

  Events = 'Events',

  Idea = 'Idea',
  Ideas = 'Ideas',
  IdeasDraft = 'IdeasDraft',

  Tasks = 'Tasks',
  Projects = 'Projects',
  ProjectsView = 'ProjectsView',
  ProjectsFolder = 'ProjectsFolder',
  ProjectsFile = 'ProjectsFile',
  Surveys = 'Surveys',
  Favorites = 'Favorites',

  Folder = 'Folder',
  File = 'File',

  VKHRTek = 'VKHRTek',
  VKTeams = 'VKTeams',
}

export type RouteConfig = Record<RouteNames, string>;
export type RouteParams = Record<string, string | number>;

export const routes: RouteConfig = {
  [RouteNames.Home]: '/home',
  [RouteNames.SignIn]: '/sign-in',
  [RouteNames.Profile]: '/user/:id',
  [RouteNames.ProfileEdit]: '/user/:id/edit',
  [RouteNames.ProfileFolder]: '/user/my/folder/:entryId?',
  [RouteNames.ProfileFile]: '/user/my/file/:fileId',
  [RouteNames.ProfileRewardsProgress]: '/user/:id/rewards-progress',

  [RouteNames.AdminOrgStructure]: '/admin/org-structure',
  [RouteNames.AdminContentManagement]: '/admin/content-management/:tab?',
  [RouteNames.AdminContentManagementGroupsPages]: '/admin/content-management/cms-group-pages/:type/:id',
  [RouteNames.AdminContentManagementSurveysAnalyticsView]: '/admin/content-management/survey-analytics/:id',
  [RouteNames.AdminContentManagementCmsPageFeedbackView]:
    '/admin/content-management/cms-page-feedback/:type/:id',
  [RouteNames.AdminContentManagementGroupView]: '/admin/content-management/group/:id',
  [RouteNames.AdminAdministration]: '/admin/administration/:tab?',
  [RouteNames.AdminHealthCheck]: '/admin/health-check',
  [RouteNames.AdminSummaryAnalytics]: '/admin/summary-analytics',

  [RouteNames.News]: '/news',
  [RouteNames.GroupsList]: '/groups',
  [RouteNames.GroupView]: '/groups/:id',
  [RouteNames.GroupSearch]: '/groups/:id/search',
  [RouteNames.GroupFolder]: '/groups/:id/folder/:entryId?',
  [RouteNames.GroupFile]: '/groups/:id/file/:fileId',

  [RouteNames.Structure]: '/structure/:id?',

  [RouteNames.Calendar]: '/calendar',

  [RouteNames.CmsView]: '/cms/:type/:slugId',

  [RouteNames.MeetingRooms]: '/meeting-rooms',

  [RouteNames.Search]: '/search/:origin',

  [RouteNames.AdvancedSearch]: '/advanced-search',
  [RouteNames.AdvancedSearchFileView]: '/advanced-search/file/:fileId',

  [RouteNames.Events]: '/events',

  [RouteNames.Idea]: '/idea/:id',
  [RouteNames.Ideas]: '/ideas',
  [RouteNames.IdeasDraft]: '/ideas/draft',

  [RouteNames.Tasks]: '/tasks',
  [RouteNames.Projects]: '/projects',
  [RouteNames.ProjectsView]: '/projects/:id',
  [RouteNames.ProjectsFolder]: '/projects/:id/folder/:entryId?',
  [RouteNames.ProjectsFile]: '/projects/:id/file/:fileId',
  [RouteNames.Surveys]: '/surveys',
  [RouteNames.Favorites]: '/favorites',

  [RouteNames.Folder]: '/folder/:entryId?',
  [RouteNames.File]: '/file/:fileId',

  [RouteNames.VKHRTek]: '/vk-hr-tek/*',
  [RouteNames.VKTeams]: '/vk-teams',
};

const isRouteName = (route: string | RouteNames): route is RouteNames => route in RouteNames;

export const replaceParams = (route: string | RouteNames, params: RouteParams) => {
  let result = isRouteName(route) ? routes[route] : route;

  Object.keys(params).forEach((param) => {
    result = result.replace(new RegExp(`:${param}[?]{0,1}`), encodeURIComponent(params[param]));
  });

  return result;
};

export const getRoutePath = (routeName: RouteNames, params?: RouteParams) => {
  let path = routes[routeName];

  if (params) {
    path = replaceParams(path, params);
  }

  // Заменяем опциональные параметры (/:param?) вместе со слэшем на пустую строку, если параметр не передан
  path = path.replace(/\/:\w+\??/g, '');

  return path;
};

const isPathWithoutParamsMatchRoute = (pathnameWithoutParams: string, routeName: RouteNames) => {
  const routePath = routes[routeName];

  return Boolean(matchPath(routePath, pathnameWithoutParams));
};

export const getPathnameWithoutParams = (pathname: string | null) =>
  (pathname || '').split('?')[0].split('#')[0];

export const isPathMatchRoute = (pathname: string | null, routeName: RouteNames) =>
  isPathWithoutParamsMatchRoute(getPathnameWithoutParams(pathname), routeName);

export const checkInnerPath = (pathname: string | null) => {
  const pathnameWithoutParams = getPathnameWithoutParams(pathname);

  return Object.values(routes).some((route) => matchPath(route, pathnameWithoutParams));
};

export const normalizeToInnerUrl = (url: string) => {
  const remote = process.env.REMOTE_URL || window.location.origin;
  const isRemoteUrl = url.toLocaleLowerCase().startsWith(remote);

  if (isRemoteUrl) {
    const innerRoute = url.replace(remote, '');

    return checkInnerPath(innerRoute) ? innerRoute : url;
  }

  return url;
};

export const normalizeToExternalUrl = (url: string) => (url.startsWith('http') ? url : `https://${url}`);

export const getHrefByPathname = (pathname: string) => {
  return `${window.location.origin}${pathname}`;
};
