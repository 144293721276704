import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { DictPaginated } from '../../types/api';
import {
  FullNameModel,
  JobModel,
  UserAvatarModel,
  UserIdModel,
  ConfirmEventParticipationModel,
  CreateUpdateEventModel,
  CreateUpdateScheduleSlotModel,
  EventId,
  EventInviteModel,
  EventMemberModel,
  EventModel,
  EventParticipantsInfoModel,
  EventRequestDecideModel,
  EventRequestModel,
  GetByEventIdParams,
  GetEventParticipantsQueryParams,
  InviteUserToEventModel,
  CancelInviteUserToEventModel,
  ScheduleSlotModel,
  EventUsersModel,
  EventGuestsCount,
  UpdateEventScheduleGuestsCountParams,
} from '../../types/models';
import { authService, buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';
import {
  cancelInviteUserToEvent,
  confirmActivityParticipation,
  confirmEventParticipation,
  createNewEvent,
  createScheduleSlot,
  declineActivityParticipation,
  declineEventParticipation,
  deleteEvent,
  deleteScheduleSlot,
  eventRequestDecide,
  inviteUserToEvent,
  removeMemberFromEvent,
  updateEvent,
  updateScheduleSlot,
  eventParticipantsInfo,
  updateEventGuestsCount,
  updateScheduleGuestsCount,
  getEvent,
} from './api';

export enum EventOrderingParams {
  AscSince = 'since',
  DescSince = '-since',
  AscPublishedAt = 'published_at',
  DescPublishedAt = '-published_at',
  AscTill = 'till',
  DescTill = '-till',
}

export type GetEventParams = {
  pageSize?: number;
  pageNumber?: number;
  since?: string;
  till?: string;
  isAuthoredByMe?: boolean;
  role?: string;
  type?: string;
  kind?: string;
  isDraft?: boolean;
  ordering?: EventOrderingParams;
  showVisible?: boolean;
};

type UpdateEventParams = {
  id: EventId;
  body: CreateUpdateEventModel;
};

export type UpdateScheduleSlotParams = {
  id: EventId;
  body: CreateUpdateScheduleSlotModel;
};

type ConfirmEventParticipationParams = {
  id: EventId;
  body: ConfirmEventParticipationModel;
};

type SetEventUserParticipationParams = {
  event: EventModel;
  guestsCount?: number;
  isUserParticipate: boolean;
};
export const createEventEffect = createEffect<CreateUpdateEventModel, EventModel, AxiosError>((params) =>
  createNewEvent<EventModel>(params).then(({ data }) => data),
);

export const updateEventEffect = createEffect<UpdateEventParams, EventModel, AxiosError>(({ id, body }) =>
  updateEvent<EventModel>(id, body).then(({ data }) => data),
);

export const getEventEffect = createEffect<GetByEventIdParams, EventModel, AxiosError>(({ eventId }) =>
  getEvent<EventModel>({ eventId }).then(({ data }) => data),
);

export const deleteEventEffect = createEffect<string, unknown, AxiosError>((id) => deleteEvent(id));

export const confirmEventParticipationEffect = createEffect<
  ConfirmEventParticipationParams,
  unknown,
  AxiosError
>(({ id, body }) => confirmEventParticipation(id, body));

export const declineEventParticipationEffect = createEffect<string, unknown, AxiosError>((id) =>
  declineEventParticipation(id),
);

export const confirmActivityParticipationEffect = createEffect<
  ConfirmEventParticipationParams,
  unknown,
  AxiosError
>(({ id, body }) => confirmActivityParticipation(id, body));

export const declineActivityParticipationEffect = createEffect<string, unknown, AxiosError>((id) =>
  declineActivityParticipation(id),
);

export const updateEventGuestsCountEffect = createEffect<
  UpdateEventScheduleGuestsCountParams,
  EventGuestsCount,
  AxiosError
>(({ id, invitedGuestsCount }) =>
  updateEventGuestsCount(id, { invitedGuestsCount }).then(({ data }) => data),
);

export const updateScheduleGuestsCountEffect = createEffect<
  UpdateEventScheduleGuestsCountParams,
  EventGuestsCount,
  AxiosError
>(({ id, invitedGuestsCount }) =>
  updateScheduleGuestsCount(id, { invitedGuestsCount }).then(({ data }) => data),
);

export const createScheduleSlotEffect = createEffect<
  CreateUpdateScheduleSlotModel,
  CreateUpdateScheduleSlotModel,
  AxiosError
>((params) => createScheduleSlot<CreateUpdateScheduleSlotModel>(params).then(({ data }) => data));

export const updateScheduleSlotEffect = createEffect<UpdateScheduleSlotParams, ScheduleSlotModel, AxiosError>(
  ({ id, body }) => updateScheduleSlot<ScheduleSlotModel>(id, body).then(({ data }) => data),
);

export const deleteScheduleSlotEffect = createEffect<string, unknown, AxiosError>((id) =>
  deleteScheduleSlot(id),
);

export const getEventsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<EventModel>,
    EventModel[],
    EventModel[],
    GetEventParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.events.eventsList(), params),
    dataMapper: ({ items }) => items,
    defaultValue: [],
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  const setEventUserParticipation = (eventUserParticipationParams: SetEventUserParticipationParams) => {
    const { event, isUserParticipate, guestsCount } = eventUserParticipationParams;
    const currentUserId = authService.getCurrentUserId();
    const newMemberTotalUids =
      currentUserId && guestsCount && guestsCount > 0
        ? [...event.memberTotalUids, currentUserId]
        : event.memberTotalUids.filter((id) => id !== currentUserId);

    return {
      ...event,
      isUserParticipate,
      memberTotalUids: guestsCount ? newMemberTotalUids : event.memberTotalUids,
      seatsLeft: guestsCount && event.seatsLeft ? event.seatsLeft - guestsCount : event.seatsLeft,
    };
  };

  storage.store.on(confirmEventParticipationEffect, (state, { id, body: { invitedGuestsCount } }) => ({
    ...state,
    data: state.data.map((event) => {
      if (event.id === id) {
        return setEventUserParticipation({
          event,
          isUserParticipate: true,
          guestsCount: invitedGuestsCount + 1,
        });
      }

      return event;
    }),
  }));

  storage.store.on(declineEventParticipationEffect, (state, id) => ({
    ...state,
    data: state.data.map((event) => {
      if (event.id === id) {
        return setEventUserParticipation({ event, isUserParticipate: false, guestsCount: -1 });
      }

      return event;
    }),
  }));

  return { storage };
};

export type GetEventsStorage = ReturnType<typeof getEventsStorage>;

export const getSingleEventStorage = () => {
  const storage = abstractStorageFactory<EventModel, EventModel, null, GetByEventIdParams>({
    endpointBuilder: ({ eventId }) => endpoints.events.eventEntityId(eventId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getScheduleSlotsListStorage = () => {
  const storage = abstractStorageFactory<ScheduleSlotModel[], ScheduleSlotModel[], [], GetByEventIdParams>({
    endpointBuilder: ({ eventId }) => endpoints.events.eventEntityIdActivityList(eventId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type MemberParams = {
  eventId: EventId;
  userId: UserIdModel;
};

type EventRequestDecideParams = {
  eventId: EventId;
  userId: UserIdModel;
  decision: boolean;
};

export interface Participant extends FullNameModel, Partial<UserAvatarModel> {
  job: JobModel;
  userId: UserIdModel;
  isInvited?: boolean;
  isActive?: boolean;
}

export const removeMemberFromEventEffect = createEffect<MemberParams, void, AxiosError>((params) =>
  removeMemberFromEvent(params).then(({ data }) => data),
);

export const eventRequestDecideEffect = createEffect<
  EventRequestDecideParams,
  EventRequestDecideModel,
  AxiosError
>(({ eventId, userId, decision }) =>
  eventRequestDecide<EventRequestDecideModel>(eventId, { memberUid: userId, decide: decision }).then(
    ({ data }) => data,
  ),
);

export const inviteUserToEventEffect = createEffect<MemberParams, InviteUserToEventModel, AxiosError>(
  ({ eventId, userId }) =>
    inviteUserToEvent<InviteUserToEventModel>(eventId, {
      memberUid: userId,
    }).then(({ data }) => data),
);

export const cancelInviteUserToEventEffect = createEffect<
  MemberParams,
  CancelInviteUserToEventModel,
  AxiosError
>(({ eventId, userId }) =>
  cancelInviteUserToEvent<CancelInviteUserToEventModel>(eventId, { memberUid: userId }).then(
    ({ data }) => data,
  ),
);

export const eventParticipantsInfoEffect = createEffect<
  GetByEventIdParams,
  EventParticipantsInfoModel,
  AxiosError
>(({ eventId }) => eventParticipantsInfo<EventParticipantsInfoModel>({ eventId }).then(({ data }) => data));

export const getEventInviteListStorage = (eventId: EventId) => {
  const storage = abstractStorageFactory<
    DictPaginated<EventInviteModel>,
    Participant[],
    Participant[],
    GetEventParticipantsQueryParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.events.eventIdV2InviteUsers(eventId), params),
    dataMapper: ({ items }) =>
      items.map((item) => ({
        userId: item.memberUid,
        avatar: item.avatar,
        firstName: item.firstName,
        lastName: item.lastName,
        patronymic: item.patronymic,
        job: item.job,
        isInvited: true,
      })),
    defaultValue: [],
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  storage.store.on(cancelInviteUserToEventEffect.done, (state, { result: { memberUid } }) => ({
    ...state,
    data: state.data.filter((user) => user.userId !== memberUid),
  }));

  return { storage };
};

export const getEventRequestListStorage = (eventId: EventId) => {
  const storage = abstractStorageFactory<
    DictPaginated<EventRequestModel>,
    Participant[],
    Participant[],
    GetEventParticipantsQueryParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.events.eventIdV2RequestUsers(eventId), params),
    dataMapper: ({ items }) =>
      items.map((item) => ({
        userId: item.memberUid,
        avatar: item.avatar,
        firstName: item.firstName,
        lastName: item.lastName,
        patronymic: item.patronymic,
        job: item.job,
      })),
    defaultValue: [],
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getEventUserListStorage = (eventId: EventId) => {
  const storage = abstractStorageFactory<
    DictPaginated<EventUsersModel>,
    Participant[],
    Participant[],
    GetEventParticipantsQueryParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.events.eventIdUsers(eventId), params),
    dataMapper: (data) =>
      data.items.map((item) => ({
        userId: item.id,
        avatar: item.avatar,
        firstName: item.fullName.firstName,
        lastName: item.fullName.lastName,
        patronymic: item.fullName.patronymic,
        job: item.job,
        isInvited: item.isInvited,
        isActive: item.isActive,
      })),
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    defaultValue: [],
    dataBuilder: (ids) => ids,
  });

  const toggleInvitedUserState = (user: Participant, userId: UserIdModel) =>
    user.userId === userId
      ? {
          ...user,
          isInvited: !user.isInvited,
        }
      : user;

  storage.store.on([cancelInviteUserToEventEffect, inviteUserToEventEffect], (state, { userId }) => ({
    ...state,
    data: state.data.map((user) => toggleInvitedUserState(user, userId)),
  }));

  storage.store.on(
    [cancelInviteUserToEventEffect.fail, inviteUserToEventEffect.fail],
    (state, { params: { userId } }) => ({
      ...state,
      data: state.data.map((user) => toggleInvitedUserState(user, userId)),
    }),
  );

  return { storage };
};

export const getEventMemberListStorage = (eventId: EventId) => {
  const storage = abstractStorageFactory<
    DictPaginated<EventMemberModel>,
    Participant[],
    Participant[],
    GetEventParticipantsQueryParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.events.eventIdV2Members(eventId), params),
    dataMapper: ({ items }) =>
      items.map((item) => ({
        userId: item.memberUid,
        avatar: item.avatar,
        firstName: item.firstName,
        lastName: item.lastName,
        patronymic: item.patronymic,
        job: item.job,
        isActive: item.isActive,
      })),
    defaultValue: [],
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  storage.store.on(eventRequestDecideEffect.doneData, () => storage.refetchWithLastParams());

  return { storage };
};

export const getEventParticipantsInfoStorage = () => {
  const storage = abstractStorageFactory<
    EventParticipantsInfoModel,
    EventParticipantsInfoModel,
    EventParticipantsInfoModel,
    GetByEventIdParams
  >({
    endpointBuilder: ({ eventId }) => endpoints.events.eventIdParticipantsInfo(eventId),
    defaultValue: { members: 0, invites: 0, requests: 0 },
    cancelPendingRequestOnFetch: true,
  });

  storage.store.on(inviteUserToEventEffect.doneData, (state) => ({
    ...state,
    data: {
      ...state.data,
      invites: state.data.invites + 1,
    },
  }));

  storage.store.on(cancelInviteUserToEventEffect.doneData, (state) => ({
    ...state,
    data: {
      ...state.data,
      invites: state.data.invites - 1,
    },
  }));

  storage.store.on(removeMemberFromEventEffect.doneData, (state) => ({
    ...state,
    data: {
      ...state.data,
      members: state.data.members - 1,
    },
  }));

  storage.store.on(eventRequestDecideEffect.doneData, (state, data) => {
    if (data.decide) {
      return {
        ...state,
        data: {
          ...state.data,
          members: state.data.members + 1,
          requests: state.data.requests - 1,
        },
      };
    }

    return {
      ...state,
      data: {
        ...state.data,
        requests: state.data.requests - 1,
      },
    };
  });

  storage.store.on(eventParticipantsInfoEffect.doneData, (state, data) => ({
    ...state,
    data,
  }));

  return { storage };
};
