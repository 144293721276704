/* eslint-disable @vkph/rules/no-dangerous-optional-chaining */
import { AuthContextProps } from 'react-oidc-context';

class AuthService {
  private oidc: AuthContextProps['user'] | null = null;
  private removeUser: AuthContextProps['removeUser'] | null = null;
  private signoutRedirect: AuthContextProps['signoutRedirect'] | null = null;

  init(auth: AuthContextProps) {
    this.oidc = auth.user;
    this.removeUser = auth.removeUser;
    this.signoutRedirect = auth.signoutRedirect;
  }
  logout(): void {
    const id_token_hint = this.oidc?.id_token;

    this.oidc = null;
    this.removeUser?.();
    this.signoutRedirect?.({
      post_logout_redirect_uri: window.location.href,
      id_token_hint,
    });
    localStorage.clear();
  }

  getToken(): string | undefined {
    return this.oidc?.access_token;
  }

  getCurrentUserId(): string | undefined {
    return this.oidc?.profile?.sub;
  }

  getAuthType(): string {
    return this.oidc?.token_type || '';
  }
}

export const authService = new AuthService();
