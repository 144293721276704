import { endpoints } from '../../endpoints';
import { PostAttachmentModel, PostFormAttachmentId, PostTypes } from '../../types/models';
import { api } from '../../utils';

export type GetAttachmentByIdParams = {
  contentType: PostTypes;
  objectId?: number;
  id: PostFormAttachmentId;
};

export type ReloadAttachmentByIdParams = {
  attachmentId: PostFormAttachmentId;
};

export type ReplaceAttachmentByIdParams = {
  id: PostFormAttachmentId;
  attachment: PostAttachmentModel;
};

export const getAttachmentById = ({ contentType, objectId, id }: GetAttachmentByIdParams) =>
  api.get<PostAttachmentModel>({
    url: endpoints.asyncBlogs.attachmentsContentTypeObjectIdEntityId(contentType, Number(objectId), id),
  });
