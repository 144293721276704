/**
 * Превращает base64 в blob для загрузки файла через formData
 * @param dataUrl - base64
 * @param fileName - [name].[extension_type]
 * @param type - 'image/png'
 */
export const dataUrlToFile = async (dataUrl: string, fileName: string, type = 'image/png'): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();

  return new window.File([blob], fileName, { type });
};
