import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { FileLogoVariants } from '../../types/models';
import { abstractStorageFactory } from '../../utils/effector';
import {
  deleteHeaderLogoImage,
  FileSchemeLogoResponse,
  HeaderLogoImageData,
  HeaderLogoImageModel,
  updateHeaderLogoImage,
} from './api';

const defaultLogosScheme = {
  desktop: null,
  mobile: null,
};

export const getHeaderLogoImageStorage = () => {
  const storage = abstractStorageFactory<FileSchemeLogoResponse, HeaderLogoImageModel, HeaderLogoImageModel>({
    endpointBuilder: endpoints.pages.image,
    defaultValue: defaultLogosScheme,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => {
      const result: HeaderLogoImageModel = defaultLogosScheme;

      data.forEach(({ type, fileStorageObject }) => {
        result[type] = fileStorageObject;
      });

      return result;
    },
  });

  const changeHeaderLogoImageEffect = createEffect<HeaderLogoImageData, HeaderLogoImageModel, AxiosError>(
    (params) => {
      let effect;

      if (Object.values(FileLogoVariants).some((variant) => params[variant])) {
        effect = updateHeaderLogoImage(
          Object.values(FileLogoVariants).map((variant) => ({
            type: variant,
            fileStorageObject: params[variant],
          })),
        );
      } else {
        effect = deleteHeaderLogoImage();
      }

      return effect.then(({ data }) => data);
    },
  );

  storage.store.on(changeHeaderLogoImageEffect.done, (state, { result }) => ({
    ...state,
    data: result,
  }));

  return { storage, changeHeaderLogoImageEffect };
};

export const headerLogoImageStorage = getHeaderLogoImageStorage();
