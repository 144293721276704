import { endpoints } from '../../endpoints';
import {
  UserIdModel,
  CreateUpdateEventModel,
  CreateUpdateScheduleSlotModel,
  ConfirmEventParticipationModel,
  EventId,
  EventRequestDecideParams,
  EventGuestsCount,
  EventScheduleSlotId,
} from '../../types/models';
import { api } from '../../utils';

export const createNewEvent = <T>(event: CreateUpdateEventModel) =>
  api.post<T>({
    url: endpoints.events.eventNew(),
    data: event,
  });

export const updateEvent = <T>(id: EventId, data: CreateUpdateEventModel) =>
  api.put<T>({
    url: endpoints.events.eventEntityId(id),
    data,
  });

export const deleteEvent = (id: EventId) =>
  api.delete({
    url: endpoints.events.eventEntityId(id),
  });

export const getEvent = <T>({ eventId }: { eventId: EventId }) =>
  api.get<T>({
    url: endpoints.events.eventEntityId(eventId),
  });

export const createScheduleSlot = <T>(scheduleSlot: CreateUpdateScheduleSlotModel) =>
  api.post<T>({
    url: endpoints.events.activityNew(),
    data: scheduleSlot,
  });

export const updateScheduleSlot = <T>(id: EventId, data: CreateUpdateScheduleSlotModel) =>
  api.put<T>({
    url: endpoints.events.activityEntityId(id),
    data,
  });

export const deleteScheduleSlot = (id: EventId) =>
  api.delete({
    url: endpoints.events.activityEntityId(id),
  });

export const uploadImage = <T>(image: File) => {
  const formData = new window.FormData();

  formData.append('file', image);

  return api.post<T>({
    url: endpoints.filestorage.fileUpload(),
    data: formData,
  });
};

export const confirmEventParticipation = (id: EventId, data: ConfirmEventParticipationModel) =>
  api.post({
    url: endpoints.events.eventEntityIdConfirmParticipation(id),
    data,
  });

export const declineEventParticipation = (id: EventId) =>
  api.post({
    url: endpoints.events.eventIdDeclineParticipation(id),
  });

export const confirmActivityParticipation = (id: EventId, data: ConfirmEventParticipationModel) =>
  api.post({
    url: endpoints.events.activityConfirmParticipation(id),
    data,
  });

export const declineActivityParticipation = (id: EventId) =>
  api.post({
    url: endpoints.events.activityDeclineParticipation(id),
  });

export const removeMemberFromEvent = ({ eventId, userId }: { eventId: EventId; userId: UserIdModel }) =>
  api.delete<void>({
    url: endpoints.events.eventIdMembersUserId(eventId, userId),
  });

export const eventParticipantsInfo = <T>({ eventId }: { eventId: EventId }) =>
  api.get<T>({
    url: endpoints.events.eventIdParticipantsInfo(eventId),
  });

export const inviteUserToEvent = <T>(eventId: EventId, data: { memberUid: UserIdModel }) =>
  api.post<T>({
    url: endpoints.events.eventIdV2InviteUsers(eventId),
    data,
  });

export const cancelInviteUserToEvent = <T>(eventId: EventId, data: { memberUid: UserIdModel }) =>
  api.delete<T>({
    url: endpoints.events.eventIdV2InviteUsers(eventId),
    data,
  });

export const eventRequestDecide = <T>(eventId: EventId, data: EventRequestDecideParams) =>
  api.patch<T>({
    url: endpoints.events.eventIdDecide(eventId),
    data,
  });

export const updateEventGuestsCount = (eventId: EventId, data: EventGuestsCount) => {
  return api.patch<EventGuestsCount>({
    url: endpoints.events.eventIdGuest(eventId),
    data,
  });
};

export const updateScheduleGuestsCount = (eventId: EventScheduleSlotId, data: EventGuestsCount) => {
  return api.patch<EventGuestsCount>({
    url: endpoints.events.activityEntityIdGuest(eventId),
    data,
  });
};
