import {
  BlogLastActivityRelatedObjectMeta,
  BlogLastActivityRelatedObjectModel,
  BlogLastActivityRelatedObjectType,
} from '../models/blog.model';

export const isBlogLastActivityRelatedObjectType = <T extends BlogLastActivityRelatedObjectMeta>(
  object: BlogLastActivityRelatedObjectModel,
  type: BlogLastActivityRelatedObjectType,
): object is BlogLastActivityRelatedObjectModel<T> => {
  return object.type === type;
};

export const isBlogLastActivityRelatedObjectSomeOfTypes = <T extends BlogLastActivityRelatedObjectMeta>(
  object: BlogLastActivityRelatedObjectModel,
  types: BlogLastActivityRelatedObjectType[],
): object is BlogLastActivityRelatedObjectModel<T> =>
  types.some((possibleType) => isBlogLastActivityRelatedObjectType(object, possibleType));
