import { camelizeKeys } from 'humps';
import { io, Socket } from 'socket.io-client';

import { authService } from '../auth';
import { NotificationTypes } from './events';
import { getNotificationEvent } from './helpers';
import { NotificationContent, NotificationMessagePayload } from './types';

const SOCKET_PATH = '/notify_ws/socket.io';
const NOTIFICATION_EVENT_TYPE = 'new_notification';

const getSocketIoBasePath = () => {
  const remoteUrl = process.env.REMOTE_URL;
  const host = remoteUrl ? remoteUrl.split('//')[1] : window.location.host;

  return `wss://${host}`;
};

let socketInstance: Socket | null = null;

export const getNotificationServiceInstance = () => socketInstance;

export const setupNotificationsService = () => {
  if (!socketInstance) {
    const isDev = process.env.NODE_ENV === 'development';
    const token = authService.getToken();
    const authType = authService.getAuthType();
    const basePath = getSocketIoBasePath();
    const triggerEvent = <T extends NotificationTypes>(eventType?: T, payload?: NotificationContent<T>) => {
      if (eventType && payload) {
        const event = getNotificationEvent(eventType);

        if (event) {
          event(payload);
        }
      }
    };

    socketInstance = io(basePath, {
      path: SOCKET_PATH,
      reconnection: !isDev,
      query: {
        token: `${authType} ${token}`,
      },
      transports: ['websocket'],
    });

    socketInstance!.on(NOTIFICATION_EVENT_TYPE, (payload: NotificationMessagePayload<NotificationTypes>) => {
      const camelizedPayload = camelizeKeys(payload || {}) as NotificationMessagePayload<NotificationTypes>;

      triggerEvent(payload.event, camelizedPayload.data);
    });
  }
};
