import {
  PostBlogModel,
  PostGamificationModel,
  MicropostModel,
  PostNewsModel,
  PostModel,
  PostTypes,
  PostProfileEntryModel,
  PostBasicModel,
} from '../models/post';

export function isBlogPost(post: PostModel): post is PostBlogModel {
  return post.type === PostTypes.Entry;
}

export function isMicropost(post: PostModel): post is MicropostModel {
  return post.type === PostTypes.Micropost;
}

export function isNewsPost(post: PostModel): post is PostNewsModel {
  return post.type === PostTypes.Simple || post.type === PostTypes.Stories;
}

export function isGamificationPost(post: PostModel): post is PostGamificationModel {
  return post.type === PostTypes.GamificationEntry;
}

export function isProfileEntry(post: PostModel): post is PostProfileEntryModel {
  return post.type === PostTypes.ProfileEntry;
}

export function isBasicPost(post: PostModel): post is PostBasicModel {
  return isBlogPost(post) || isMicropost(post);
}
