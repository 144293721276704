import {
  addDays,
  addMonths,
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';
import { createEvent, createStore } from 'effector';

import { CalendarViewType } from '../../../types/models';
import { getMonthRangeByWeeks } from '../../../utils';

export const COUNT_DISPLAY_DAYS_IN_WEEK = 7;

export enum AppearanceDatesActionTypes {
  Prev = 'prev',
  Next = 'next',
  Now = 'now',
}

const getMonthSinceDateMap: Record<AppearanceDatesActionTypes, (sinceDate: Date) => Date> = {
  [AppearanceDatesActionTypes.Prev]: (sinceDate) => subMonths(new Date(sinceDate), 1),
  [AppearanceDatesActionTypes.Next]: (sinceDate) => addMonths(new Date(sinceDate), 1),
  [AppearanceDatesActionTypes.Now]: () => new Date(),
};

const getWeekSinceDateMap: Record<AppearanceDatesActionTypes, (sinceDate: Date) => Date> = {
  [AppearanceDatesActionTypes.Prev]: (sinceDate) => subDays(new Date(sinceDate), COUNT_DISPLAY_DAYS_IN_WEEK),
  [AppearanceDatesActionTypes.Next]: (sinceDate) => addDays(new Date(sinceDate), COUNT_DISPLAY_DAYS_IN_WEEK),
  [AppearanceDatesActionTypes.Now]: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
};

const getDaySinceDateMap: Record<AppearanceDatesActionTypes, (sinceDate: Date) => Date> = {
  [AppearanceDatesActionTypes.Prev]: (sinceDate) => subDays(new Date(sinceDate), 1),
  [AppearanceDatesActionTypes.Next]: (sinceDate) => addDays(new Date(sinceDate), 1),
  [AppearanceDatesActionTypes.Now]: () => startOfDay(new Date()),
};

// currentDate нужна для перелистывания месяца, поскольку sinceDate и tillDate практически всегда - даты соседних месяцев
export type CalendarAppearanceDates = {
  viewType: CalendarViewType;
  sinceDate: Date;
  tillDate: Date;
  currentDate: Date;
};

const initialAppearanceDates: CalendarAppearanceDates = {
  viewType: CalendarViewType.Week,
  sinceDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
  tillDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
  currentDate: new Date(),
};

type CalendarSetAppearanceEvent = Pick<CalendarAppearanceDates, 'viewType'> &
  Partial<Pick<CalendarAppearanceDates, 'currentDate'>>;

export const getAppearanceDatesStore = () => {
  const store = createStore<CalendarAppearanceDates>(initialAppearanceDates);

  const setAppearanceEvent = createEvent<CalendarSetAppearanceEvent>();
  const changeAppearanceDatesEvent = createEvent<AppearanceDatesActionTypes>();

  store.on(setAppearanceEvent, (state, payload) => {
    const { currentDate, viewType } = payload;

    const date = currentDate || state.currentDate;

    let sinceDate = new Date();
    let tillDate = new Date();

    if (payload.viewType === CalendarViewType.Day) {
      sinceDate = startOfDay(date);
      tillDate = endOfDay(date);
    }

    if (payload.viewType === CalendarViewType.Week) {
      sinceDate = startOfWeek(date, { weekStartsOn: 1 });
      tillDate = endOfWeek(date, { weekStartsOn: 1 });
    }

    if (payload.viewType === CalendarViewType.Month) {
      const monthRange = getMonthRangeByWeeks(date);

      sinceDate = monthRange.sinceDate;
      tillDate = monthRange.tillDate;
    }

    return { viewType, currentDate: date, sinceDate, tillDate };
  });

  store.on(changeAppearanceDatesEvent, (state, actionType) => {
    const { viewType } = state;

    if (viewType === CalendarViewType.Month) {
      const currentDate = getMonthSinceDateMap[actionType](state.currentDate);
      const { sinceDate, tillDate } = getMonthRangeByWeeks(currentDate);

      return { viewType, sinceDate, tillDate, currentDate };
    }

    if (viewType === CalendarViewType.Week) {
      const sinceDate = getWeekSinceDateMap[actionType](state.sinceDate);
      const tillDate = endOfWeek(sinceDate, { weekStartsOn: 1 });
      const currentDate =
        actionType === AppearanceDatesActionTypes.Now
          ? new Date()
          : getWeekSinceDateMap[actionType](state.currentDate);

      return { viewType, sinceDate, tillDate, currentDate };
    }

    const sinceDate = getDaySinceDateMap[actionType](state.sinceDate);
    const tillDate = addDays(sinceDate, 1);

    return { viewType, sinceDate, tillDate, currentDate: sinceDate };
  });

  return {
    changeAppearanceDatesEvent,
    setAppearanceEvent,
    store,
  };
};

export const appearanceDatesStorage = getAppearanceDatesStore();
