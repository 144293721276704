import { endpoints } from '../../endpoints';
import { DictPaginated, PaginationParams } from '../../types/api';
import { NewsArticle, NewsCategoriesDist, NewsCategory, NewsCategoryListParams } from '../../types/models';
import { buildEndpointWithQueryParams } from '../../utils';
import { AbstractStorageConfiguration, abstractStorageFactory } from '../../utils/effector';

export interface NewsFeedListParams extends PaginationParams {
  type: string;
  important: boolean;
  pinned: boolean;
  category: string;
  categoryIds: string;
  authorId: string;
  searchString: string;
}

type GetNewsFeedStorageParams = Pick<
  AbstractStorageConfiguration<
    DictPaginated<NewsArticle>,
    NewsArticle[],
    NewsArticle[],
    Partial<NewsFeedListParams>
  >,
  'shouldAppendData'
>;

export const getNewsFeedStorage = (
  { shouldAppendData }: GetNewsFeedStorageParams = { shouldAppendData: true },
) => {
  const storage = abstractStorageFactory<
    DictPaginated<NewsArticle>,
    NewsArticle[],
    NewsArticle[],
    Partial<NewsFeedListParams>
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.news.feed(), params),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount, page: meta.pageNumber }),
    shouldAppendData,
  });

  return { storage };
};

export const getNewsCategoriesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<NewsCategory>,
    NewsCategoriesDist,
    null,
    NewsCategoryListParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.news.categoryList(), params),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => items.reduce((acc, category) => ({ ...acc, [category.id]: category }), {}),
  });

  return { storage };
};

export type NewsFeedStorage = ReturnType<typeof getNewsFeedStorage>;
export type NewsCategoriesStorage = ReturnType<typeof getNewsCategoriesStorage>;

export const newsCategoriesStore = getNewsCategoriesStorage();
