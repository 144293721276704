import { endpoints } from '../../endpoints';
import { CommentContentTypes, CommentId, CommentObjectId, EntityContentType } from '../../types/models';
import { api, stringifyEntityText } from '../../utils';

export type BaseCommentParams = {
  text: string;
  textType?: EntityContentType;
  contentType: CommentContentTypes;
  objectId: CommentObjectId;
};

export interface CreateCommentParams extends BaseCommentParams {
  parentId: CommentId | null;
}
export interface UpdateCommentParams extends BaseCommentParams {
  commentId: CommentId;
}
export type DeleteCommentParams = Pick<UpdateCommentParams, 'commentId'>;

export const createComment = <T>({
  parentId,
  text,
  textType = EntityContentType.Markdown,
  objectId,
  contentType,
}: CreateCommentParams) =>
  api.post<T>({
    url: endpoints.comments.commentSave(),
    data: {
      parentId,
      text: stringifyEntityText(textType, text),
      objectId,
      contentType,
    },
  });

export const updateComment = <T>({
  commentId,
  text,
  textType = EntityContentType.Markdown,
  contentType,
  objectId,
}: UpdateCommentParams) =>
  api.post<T>({
    url: endpoints.comments.commentSave(),
    data: {
      id: commentId,
      text: stringifyEntityText(textType, text),
      objectId,
      contentType,
    },
  });

export const deleteComment = ({ commentId }: DeleteCommentParams) =>
  api.delete<void>({
    url: endpoints.comments.commentId(commentId),
  });
