import {
  FileStorageBlogContextModel,
  FileStorageContextServiceTypes,
  FileStorageListEntryContext,
} from '../models/file.model';

export const isFileStorageBlogContext = (
  context?: FileStorageListEntryContext,
): context is FileStorageBlogContextModel => {
  return context?.service === FileStorageContextServiceTypes.Blogs;
};
