import { createEvent, createStore } from 'effector';

import { GlobalModalNames, GlobalModalsStorePayloads } from './modal-types';

export * from './modal-types';

export type GlobalModalsStoreStoredValue = Partial<GlobalModalsStorePayloads>;

const openUpdateModalEvent = createEvent<{
  modalName: GlobalModalNames;
  payload: GlobalModalsStorePayloads[GlobalModalNames]['payload'];
  queryParams: GlobalModalsStorePayloads[GlobalModalNames]['queryParams'];
}>();

const hideModalEvent = createEvent<{ modalName: GlobalModalNames }>();
const closeModalEvent = createEvent<{ modalName: GlobalModalNames }>();
const resetEvent = createEvent();

export const globalModalsStore = createStore<GlobalModalsStoreStoredValue>({})
  .on(openUpdateModalEvent, (state, { modalName, payload, queryParams }) => ({
    ...state,
    [modalName]: {
      isVisible: true,
      isHiding: false,
      payload,
      queryParams,
    },
  }))
  .on(hideModalEvent, (state, { modalName }) => {
    const currentState = state[modalName];

    if (currentState) {
      return {
        ...state,
        [modalName]: {
          ...currentState,
          isVisible: false,
          isHiding: true,
          keyboard: false,
        },
      };
    }

    return state;
  })
  .on(closeModalEvent, (state, { modalName }) => {
    const newState = { ...state };

    delete newState[modalName];

    return newState;
  })
  .reset(resetEvent);

export const globalModalsVisibleCountStore = globalModalsStore.map((globalModals) => {
  return Object.values(GlobalModalNames).reduce(
    (count, name) => (globalModals[name]?.isVisible && !globalModals[name]?.isHiding ? count + 1 : count),
    0,
  );
});

export const openGlobalModal = <T extends GlobalModalNames>(
  modalName: T,
  payload: GlobalModalsStorePayloads[T]['payload'] = {},
  queryParams: GlobalModalsStorePayloads[T]['queryParams'] = {},
) => {
  openUpdateModalEvent({ modalName, payload, queryParams });
};

export const closeGlobalModal = <T extends GlobalModalNames>(modalName: T) => {
  hideModalEvent({ modalName });

  window.setTimeout(() => {
    closeModalEvent({ modalName });
  }, 500);
};

export const closeAllGlobalModals = () => {
  resetEvent();
};
