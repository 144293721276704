export type LayoutId = number;
export type LayoutItemId = number;

export enum LayoutTypes {
  Header = 'header',
  Navbar = 'navbar',
  Breadcrumb = 'breadcrumb',
  Central = 'central',
  Content = 'content',
  Column = 'column',
  Footer = 'footer',
}

export type Layout = {
  id: LayoutId;
  annotation: string;
  layoutItems: LayoutItem[];
};

export type LayoutItem = {
  height: number;
  id: LayoutItemId;
  index?: number;
  types: string[];
  width: number;
};
