import { endpoints } from '../../endpoints';
import { ReactionServiceModel } from '../../types/models';
import { api } from '../../utils';

export type ReactionParams = Pick<ReactionServiceModel, 'emojiUuid' | 'contentType' | 'objectId'>;
export type DeleteReactionParams = Omit<ReactionParams, 'emojiUuid'>;

export const createReaction = (params: ReactionParams) =>
  api.post<ReactionServiceModel>({
    url: endpoints.reactions.reactionSave(),
    data: params,
  });

export const deleteReaction = ({ contentType, objectId }: DeleteReactionParams) =>
  api.delete<void>({
    url: endpoints.reactions.reactionContentTypeObjectId(contentType, objectId),
  });
