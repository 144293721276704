import { ProjectId, ProjectModel, ProjectsStageId, UserIdModel } from '..';
import { BaseWorkInterval } from '../base';
import { FileStorageEntryId, FileStorageObjectModel } from '../file.model';
import { BaseJobModel, FullNameModel, UserAvatarModel } from '../user.model';

export type ProjectsTaskId = number;
export type TasksReferencesStatusId = number;
export type TasksReferencesPrioritiesId = number;

export interface ProjectsTaskProfile extends FullNameModel, UserAvatarModel {
  email: string;
  id: UserIdModel;
  job: BaseJobModel;
  superAdmin: boolean;
  username: string;
  workPhone: string;
  isActive: boolean;
}

type ProjectShortInfo = Pick<ProjectModel, 'title'>;

interface ProjectsTasksBaseModel extends BaseWorkInterval {
  assignee: UserIdModel;
  author: UserIdModel;
  category: number;
  description: string;
  notes: string;
  priorityId: TasksReferencesPrioritiesId;
  progress: number;
  projectId: ProjectId;
  projectShortInfo: ProjectShortInfo;
  statusId: TasksReferencesStatusId;
  stageId?: ProjectsStageId | null;
  taskGroup: string;
  title: string;
}

export interface ProjectsTasksModel extends ProjectsTasksBaseModel {
  assigneeProfile: ProjectsTaskProfile;
  authorProfile: ProjectsTaskProfile;
  createdAt: string;
  id: ProjectsTaskId;
  key: string;
  permissions: string[];
  taskType: string;
  updatedAt: string;
  updatedBy: string;
  updatedByProfile: ProjectsTaskProfile;
  fileStorageObjects: FileStorageObjectModel[];
}

export interface ProjectsTaskCreateModel extends Partial<ProjectsTasksBaseModel> {
  assignee: string;
  title: string;
  workEnd: string;
  projectId: ProjectId;
  fileStorageObjects: FileStorageEntryId[];
}

export interface ProjectsTaskUpdateModel extends Partial<ProjectsTaskCreateModel> {
  id: ProjectsTaskId;
}

enum ProjectsTaskStatusType {
  Default = 'default',
  Intermediate = 'intermediate',
  Final = 'final',
  Cancel = 'cancel',
}

export interface ProjectsTaskReferencesStatusModel {
  name: string;
  statusType: ProjectsTaskStatusType;
  id: TasksReferencesStatusId;
  color: string;
}

export interface ProjectsTaskReferencesPrioritiesModel {
  id: TasksReferencesPrioritiesId;
  name: string;
  shortName: string;
  value: number;
  logoUrl: string;
}
