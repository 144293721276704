import type { Rule } from '@vkph/ui';

export * from './date';
export * from './getRestCharacters';
export * from './langRules';
export * from './maxInputLength';
export * from './required';
export * from './url';

export const emailRule: Rule = { type: 'email', message: 'Укажите правильный email' };

export const getCorrectJSONRule: Rule = {
  validator: (_, value: string) => {
    try {
      JSON.parse(value);
    } catch (e) {
      return Promise.reject(e);
    }

    return Promise.resolve();
  },
  message: 'Некорректный JSON',
};

export const isValueMatchRegexpPattern = (regex: RegExp, message: string): Rule => ({
  validator: (_, value = '') => {
    return regex.test(value?.trim()) ? Promise.reject() : Promise.resolve();
  },
  message,
});
