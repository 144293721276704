import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { DictPaginatedObjects } from '../../types/api';
import { UserAgreementModel, UserAgreementOpenApiModel } from '../../types/models';
import { buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';
import {
  AcceptUserAgreement,
  acceptUserAgreementsRequest,
  CreateNewUserAgreement,
  newUserAgreementsRequest,
} from './api';

export const getUserAgreementsStorage = () => {
  const storage = abstractStorageFactory<DictPaginatedObjects<UserAgreementModel>, UserAgreementModel[], []>({
    endpointBuilder: () => buildEndpointWithQueryParams(endpoints.termsOfUse.get()),
    dataMapper: (data) => data.objects,
    defaultValue: [],
  });

  return { storage };
};

export const getAllUserAgreementsStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginatedObjects<UserAgreementOpenApiModel>,
    UserAgreementOpenApiModel[],
    []
  >({
    endpointBuilder: () => buildEndpointWithQueryParams(endpoints.termsOfUse.openAPI()),
    dataMapper: (data) => data.objects,
    defaultValue: [],
  });

  return { storage };
};

export const createNewUserAgreementEffect = createEffect<CreateNewUserAgreement, unknown, AxiosError>(
  (newUserAgreement: CreateNewUserAgreement) => newUserAgreementsRequest(newUserAgreement),
);

export const acceptUserAgreementEffect = createEffect<AcceptUserAgreement, unknown, AxiosError>(
  (acceptUserAgreement: AcceptUserAgreement) => acceptUserAgreementsRequest(acceptUserAgreement),
);
