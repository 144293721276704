import type { UTCDate } from './date.model';

export interface BaseAttributes {
  name: string;
}

export type CreateDictionaryRecord = {
  attributes: BaseAttributes;
  keyFields?: string[];
};

export type RecordResponse<Attributes = BaseAttributes> = {
  attributes: Attributes;
  id: string;
};

export type DictsResponse<Scheme = Record<string, unknown>> = {
  id: string;
  name: string;
  scheme: Scheme;
  description: string;
  defaultKeyFields?: string[];
  hasUniqueField?: boolean;
  updatedAt?: UTCDate;
};

export type DictRecordId = string;

export interface ContactLinkAttributes extends BaseAttributes {
  icon: string;
  recordId: DictRecordId;
  prefix: string;
  placeholder: string;
}

export type DictBaseRecord = {
  id: DictRecordId;
  name: string;
};

export enum ContactDictKinds {
  Messenger = 'messenger',
  ExternalLink = 'external_link',
}

export type FetchDictsListStorageParams = {
  items: RecordResponse[];
};

export type DictsListStorageParams = {
  ids: string[];
};

export namespace Dictionaries {
  export enum Names {
    Countries = 'countries',
    AbsenceTypes = 'absence_types',
    Activity = 'activity',
    OrganizationUnits = 'organization_units',
    NotificationTypes = 'notification_types',
    Cities = 'cities',
    ContactTypes = 'contact_types',
    Devices = 'devices',
    UnitNames = 'unit_names',
    Congratulations = 'congratulations',
    Tags = 'tags',
    BlogCategories = 'blog_categories',
    Specializations = 'specializations',
    Competencies = 'competencies',
    Divisions = 'divisions',
    Division = 'division',
    CalendarEventType = 'calendar_event_type',
    EducationTypes = 'education_types',
    Organizations = 'organizations',
    Hobbies = 'hobbies',
    FunctionalActivity = 'functional_activity',
    OperatingSystems = 'operating_systems',
    /**
     * @deprecated Используй getProfilePositionListStorage
     */
    Positions = 'positions',
    Institutions = 'institutions',
    Languages = 'languages',
    LanguageLevel = 'language_level',
    Currencies = 'currencies',
    Awards = 'awards',
    Thanks = 'thanks',
    Cars = 'cars',
    Skills = 'skills',
    Metro = 'metro',
    Faculties = 'faculties',
    StopWords = 'stop_words',
    AwardGroups = 'award_groups',
    Qualifications = 'qualifications',
    PhoneTypes = 'phone_types',
    Reactions = 'reactions',
    Mailing = 'mailing',
    ProjectPartners = 'project_partners',
    BadgeCategories = 'badge_categories',
    EyeColors = 'eye_colors',
    FootSizes = 'foot_sizes',
    ClothesSizes = 'clothes_sizes',
  }

  export type Name = string | Names;

  export type Dict = {
    id: string;
    name: string;
    defaultKeyFields?: string[];
    hasUniqueField?: boolean;
  };
}
