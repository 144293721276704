import { endpoints } from '../../endpoints';
import { WidgetAttributes, WidgetModel, WidgetId, FileStorageEntryId, UserIdModel } from '../../types/models';
import { api } from '../../utils';

interface WidgetAttributesPatch extends Omit<WidgetAttributes, 'fileStorageImage'> {
  fileStorageImage?: FileStorageEntryId | null;
}

export type UpdateWidgetParams = {
  widgetId: WidgetId;
  attributes: Partial<WidgetAttributes>;
  onlyUser?: boolean; // TODO: B2BCORE-10619: удалить onlyUser после реализации
};
// TODO: B2BCORE-10619: удалить после реализации
export type UpdateAttributesDataWidgetParams = {
  widget: WidgetId;
  user?: UserIdModel | null;
  data: WidgetAttributes['data'];
};
export interface UpdateWidgetPathParams extends Omit<UpdateWidgetParams, 'attributes'> {
  attributes: Partial<WidgetAttributesPatch>;
}

export const updateWidget = <T extends WidgetModel>({ widgetId, attributes }: UpdateWidgetPathParams) =>
  api.patch<T>({
    url: endpoints.widgets.widgetId(widgetId),
    data: { attributes },
  });

// TODO: B2BCORE-10619: удалить после реализации
export const updateAttributesDataWidget = <T>(data: UpdateAttributesDataWidgetParams) =>
  api.post<T>({
    url: endpoints.pages.widgetDataNew(),
    data,
  });
