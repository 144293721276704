import { WidgetUserModel } from '@vkph/widget-sdk';
import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { OAuth2TokenModel } from '../../types/models';
import { AuthTokenParams, CurrentUserParams, getAuthToken, getCurrentUser } from './api';

export const getAuthTokenEffect = createEffect<AuthTokenParams, OAuth2TokenModel, AxiosError>((params) =>
  getAuthToken<OAuth2TokenModel>(params).then(({ data }) => data),
);

export const getCurrentUserEffect = createEffect<CurrentUserParams, WidgetUserModel, AxiosError>((params) =>
  getCurrentUser<WidgetUserModel>(params).then(({ data }) => data),
);
