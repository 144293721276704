import { UserIdModel, GamificationBadgeId, GamificationThanksId } from '../types/models';

export const gamificationEndpoints = {
  administratorThanks: () => '/api/gamification/administrator/thanks/',
  administratorThanksType: () => `/api/gamification/administrator/thanks-type/`,
  administratorThanksTypeThanksId: (thanksId: GamificationThanksId) =>
    `/api/gamification/administrator/thanks-type/${thanksId}/`,
  administratorBadges: () => '/api/gamification/administrator/badges/',
  badges: () => `/api/gamification/badges/`,
  badgesAwardUsers: () => `/api/gamification/badges/award-users/`,
  badgesBadgeId: (badgeId: GamificationBadgeId) => `/api/gamification/badges/${badgeId}/`,
  badgesSend: () => `/api/gamification/badges/send/`,
  badgesBadgeIdUsers: (badgeId: GamificationBadgeId) => `/api/gamification/badges/${badgeId}/users/`,
  thanks: () => `/api/gamification/thanks/`,
  thanksCreate: () => `/api/gamification/thanks/create/`,
  transactionHistory: () => `/api/gamification/transaction/history/`,
  userIdBadges: (userId: UserIdModel) => `/api/gamification/${userId}/badges/`,
  userIdBadgesSummary: (userId: UserIdModel) => `/api/gamification/${userId}/badges/summary/`,
  userIdThanksReceived: (userId: UserIdModel) => `/api/gamification/${userId}/thanks/received/`,
  userIdThanksReceivedSummary: (userId: UserIdModel) =>
    `/api/gamification/${userId}/thanks/received/summary/`,
  userIdWallet: (userId: UserIdModel) => `/api/gamification/${userId}/wallet/`,
};
