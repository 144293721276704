import { WidgetModel } from './widgets.model';

export type LayoutId = number;

export type PagesPopulateLayoutModel = {
  layoutItem: number;
  widget: WidgetModel;
  type: string;
  user: string | null;
};

export enum PagesNames {
  Navbar = 'navbar',
}
