import { WidgetId } from '../types/models';

export const widgetsEndpoints = {
  authToken: () => `/api/widgets/auth/token/`,
  authTokenRefresh: () => `/api/widgets/auth/token/refresh/`,
  currentUser: () => `/api/widgets/current_user/`,
  widgetCreate: () => `/api/widgets/widget/create/`,
  widgetId: (id: WidgetId) => `/api/widgets/widget/${id}/`,
  widgetList: () => `/api/widgets/widget/list/`,
  widgetTypes: () => `/api/widgets/widget/types/`,
};
