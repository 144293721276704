import { endpoints } from '../../endpoints';
import { UserAgreementId } from '../../types/models';
import { api } from '../../utils';

export type CreateNewUserAgreement = {
  htmlText: string;
};

export type AcceptUserAgreement = {
  id: UserAgreementId;
};

export type GetUserAgreementParams = {
  id: UserAgreementId;
};

export const acceptUserAgreementsRequest = (params: AcceptUserAgreement) => {
  return api.post({
    url: endpoints.termsOfUse.openAPIAgreementIdFactsOfConsent(params.id),
  });
};

export const newUserAgreementsRequest = (data: CreateNewUserAgreement) => {
  return api.post({
    url: endpoints.termsOfUse.new(),
    data,
  });
};

export const getUserAgreementRequest = (params: GetUserAgreementParams) => {
  return api.get({
    url: endpoints.termsOfUse.openAPIAgreementId(params.id),
  });
};
