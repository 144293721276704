import humps from 'humps';

import { endpoints } from '../../endpoints';
import { UserIdModel } from '../../types/models';
import {
  ServiceRolesNames,
  ServiceRolesPermissionModelList,
  ServiceRolesPermissionsModel,
} from '../../types/roles';
import { buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';

export type RolesPermissionsStorageParams = { serviceName: ServiceRolesNames };
type FetchProfilePermissionsParams = {
  userId: UserIdModel;
  groupBy: ServiceRolesNames;
  serviceName: ServiceRolesNames[];
};

export const getRolesServiceNamePermissionsStorage = ({ serviceName }: RolesPermissionsStorageParams) => {
  const storage = abstractStorageFactory<
    ServiceRolesPermissionModelList,
    ServiceRolesPermissionModelList,
    ServiceRolesPermissionModelList
  >({
    endpointBuilder: () => endpoints.roles.serviceNamePermissions(serviceName),
    defaultValue: [],
  });

  return { storage };
};

export const getProfileRolesPermissionsStorage = () => {
  const storage = abstractStorageFactory<
    Partial<ServiceRolesPermissionsModel>,
    Partial<ServiceRolesPermissionsModel>,
    null,
    FetchProfilePermissionsParams
  >({
    endpointBuilder: ({ userId, groupBy, serviceName }) => {
      const stringServicesNames = serviceName.join(',');

      return buildEndpointWithQueryParams(endpoints.roles.userPermissionsAll(userId), {
        groupBy,
        serviceName: stringServicesNames,
      });
    },
    defaultValue: null,
    dataMapper: (data) => humps.decamelizeKeys(data, { separator: '-' }),
  });

  return { storage };
};
