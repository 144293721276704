import { AxiosResponse } from 'axios';

import { endpoints } from '../../endpoints';
import {
  UserIdModel,
  ContactLinkAttributes,
  ContactLink,
  ContactsModel,
  PatchProfileJobHistoryModel,
  ProfileAdditionalInfoModel,
  ProfileBiographyHobbyId,
  ProfileBiographyModel,
  ProfileJobHistoryModel,
  ProfileProjectItemModel,
  ProjectId,
  UserProfileModel,
} from '../../types/models';
import { api } from '../../utils';

export interface ExternalLinkContact extends ContactLinkAttributes {
  value?: string;
}

export type PatchProfileProjectParams = { name: string };

export type ProfileContactsParams = {
  mobilePhone: string;
  personalEmail: string;
  externalLinks: ExternalLinkContact[];
  otherContacts: ContactLink[];
  otherLinks: ContactLink[];
  workEmail: string;
  workPhone: string;
};

export type ProfileFullInfoParams = { userId: UserIdModel };
export type ContactsParams = ProfileFullInfoParams & ProfileContactsParams;
export type MainEditParams = ProfileFullInfoParams & Partial<UserProfileModel>;

export interface ProjectsParams extends ProfileFullInfoParams {
  projects: ProjectId[];
}

export interface JobsHistoryParams extends ProfileFullInfoParams {
  jobHistory: PatchProfileJobHistoryModel[];
}

export interface BiographyParams extends ProfileFullInfoParams, Omit<ProfileBiographyModel, 'hobbies'> {
  hobbies: ProfileBiographyHobbyId[];
}

export const createProfileProject = <T = unknown>({
  name,
}: PatchProfileProjectParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.profile.projects(),
    data: { name },
  });

export const patchProfileContacts = (params: ContactsParams) => {
  const { userId, ...data } = params;

  return api.patch<ContactsModel>({
    url: endpoints.profile.userIdContacts(userId),
    data,
  });
};

export const patchProfileUserProjects = (params: ProjectsParams) => {
  const { userId, projects } = params;

  return api.patch<ProfileProjectItemModel[]>({
    url: endpoints.profile.userProjects(userId),
    data: { projects },
  });
};

export const patchMainProfile = (params: MainEditParams) => {
  const { userId, ...data } = params;

  return api.patch<UserProfileModel>({
    url: endpoints.profile.main(userId),
    data,
  });
};

export const patchJobHistoryProfile = (params: JobsHistoryParams) => {
  const { userId, jobHistory } = params;

  return api.patch<ProfileJobHistoryModel[]>({
    url: endpoints.profile.jobHistory(userId),
    data: jobHistory.map(({ pk, ...job }) => (!pk ? job : { pk, ...job })),
  });
};

export const patchBiographyProfile = (params: BiographyParams) => {
  const { userId, ...data } = params;

  return api.patch<ProfileBiographyModel>({
    url: endpoints.profile.biography(userId),
    data: {
      ...data,
      education: {
        ...data.education,
        additional: data.education.additional.map(({ id, ...educ }) => (!id ? educ : { id, ...educ })),
      },
      languages: data.languages,
    },
  });
};

export const patchProfileUserAdditionalInfo = (params: ProfileAdditionalInfoModel) => {
  const { id, cars, devices } = params;

  return api.patch<ProfileAdditionalInfoModel>({
    url: endpoints.profile.additionalInfo(id),
    data: {
      cars: cars.filter((car) => car.carRid || car.carNumber),
      devices: devices.filter((device) => device.typeRid || device.deviceRid || device.osRid),
    },
  });
};
