import { EventId } from '../types/models';

export const calendarEndpoints = {
  eventId: (eventId: EventId) => `/api/calendar/events/${eventId}`,
  eventIdAttendees: (eventId: EventId) => `/api/calendar/events/${eventId}/attendees`,
  eventIdIsUserParticipates: (eventId: EventId) => `/api/calendar/events/${eventId}/is_user_participates`,
  eventsStatus: () => '/api/calendar/events/status',
  freeBusy: () => '/api/calendar/free_busy',
  freeSlots: () => '/api/calendar/free_slots',
  personalEvents: () => '/api/calendar/personal/events',
  personalEventsNew: () => '/api/calendar/personal/events/new',
};
