import { openGlobalModal, closeGlobalModal, GlobalModalNames } from '../../../store/global-modals';
import { NotificationPayloads, NotificationTypes } from './events';
import { NotificationListener } from './hooks';

export type NotificationEventListenersGlobal = {
  [K in NotificationTypes]?: NotificationListener<NotificationPayloads[K]>;
};

export const notificationEventListenersGlobal: NotificationEventListenersGlobal = {
  [NotificationTypes.NewThanks]: (thank: NotificationPayloads[NotificationTypes.NewThanks]) => {
    openGlobalModal(GlobalModalNames.ProfileBadgeThanksView, {
      onClose: () => closeGlobalModal(GlobalModalNames.ProfileBadgeThanksView),
      thank,
    });
  },
};
