import { endpoints } from '../../../endpoints';
import { SkillId, SkillType } from '../../../types/models';
import { api, buildEndpointWithQueryParams } from '../../../utils';

export type SkillParams = { skillId: SkillId; skillType: SkillType };
export type ApproveSkillParams = SkillParams & { isAccept: boolean };
export type DeleteSkillParams = SkillParams;
export type SaveSkillParams = { name: string; skillId?: SkillId | number; skillType: SkillType };
export type GetSkillsStorageParams = { userId: string; skillType: SkillType };

type ApproveSkillApiParams = ApproveSkillParams & GetSkillsStorageParams;
type SaveSkillApiParams = SaveSkillParams & GetSkillsStorageParams;
type DeleteSkillApiParams = DeleteSkillParams & GetSkillsStorageParams;

export const approveSkill = (params: ApproveSkillApiParams) => {
  const { userId, skillId, isAccept, skillType } = params;

  return api.put({
    url: buildEndpointWithQueryParams(endpoints.profile.v2SkillAction(userId, skillId), { type: skillType }),
    data: { action: isAccept ? 'accept' : 'decline' },
  });
};

export const saveSkill = (params: SaveSkillApiParams) => {
  const { userId, name, skillId, skillType } = params;

  return api.post({
    url: buildEndpointWithQueryParams(endpoints.profile.v2Skills(userId), { type: skillType }),
    data: [{ name, skillId }],
  });
};

export const deleteSkill = (params: DeleteSkillApiParams) => {
  const { userId, skillId, skillType } = params;

  return api.delete({
    url: buildEndpointWithQueryParams(endpoints.profile.v2SkillAction(userId, skillId), { type: skillType }),
  });
};
