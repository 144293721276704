import { Dictionaries, DictRecordId } from '../types/models';

export const dictsEndpoints = {
  dicts: () => `/dicts/`,
  recordsList: () => `/dicts/records/list/`,
  dictionaryName: (dictionaryName: Dictionaries.Name) => `/dicts/${dictionaryName}/`,
  dictionaryNameRecords: (dictionaryName: Dictionaries.Name) => `/dicts/${dictionaryName}/records/`,
  dictionaryNameRecordsNew: (dictionaryName: Dictionaries.Name) => `/dicts/${dictionaryName}/records/new/`,
  recordsRecordId: (recordId: DictRecordId) => `/dicts/records/${recordId}/`,
};
