import { AxiosError } from 'axios';
import { createEffect, Effect } from 'effector';
import omit from 'lodash/omit';

import { endpoints } from '../../endpoints';
import { Layout, DictPaginated } from '../../types';
import { WidgetAttributes, WidgetModel } from '../../types/models';
import {
  buildEndpointWithQueryParams,
  AbstractStorage,
  abstractStorageFactory,
  authService,
} from '../../utils';
import { updateAttributesDataWidget, updateWidget, UpdateWidgetParams, UpdateWidgetPathParams } from './api';

const WIDGET_ATTRIBUTES_DEFAULT: Required<WidgetAttributes> = {
  name: '',
  description: '',
  data: [],
  defaultData: [],
  isExternal: false,
  types: [],
  fileStorageImage: null,
  path: '',
};

export interface GetWidgetStorageParams {
  id: number;
  type: string;
}

export const getWidgetStorage = <WidgetType extends WidgetModel>({
  id,
  type,
}: Partial<GetWidgetStorageParams>) => {
  const storage = abstractStorageFactory<DictPaginated<WidgetType> | WidgetType, WidgetType | null, null>({
    endpointBuilder: () =>
      id
        ? endpoints.widgets.widgetId(id)
        : buildEndpointWithQueryParams(endpoints.widgets.widgetList(), { type }), // TODO удалить когда виджет c типом navbar будет получаться по id,
    defaultValue: null,
    dataMapper: (data) => ('items' in data ? data.items[0] : data),
  });

  const updateWidgetEffect = createEffect<UpdateWidgetParams, WidgetType, AxiosError>(async (params) => {
    const { onlyUser } = params;
    const attributes = { ...WIDGET_ATTRIBUTES_DEFAULT, ...params.attributes };
    const fileStorageImageId = attributes?.fileStorageImage?.id;
    const fileStorageImage = fileStorageImageId || null;
    const updatedParams: UpdateWidgetPathParams = {
      ...params,
      attributes: { ...attributes, fileStorageImage },
    };

    // TODO: B2BCORE-10619: удалить после реализации
    await updateAttributesDataWidget({
      widget: updatedParams.widgetId,
      data: updatedParams.attributes.data || null,
      user: onlyUser ? authService.getCurrentUserId() || '' : null,
    });

    // TODO: B2BCORE-10619: удалить после реализации
    const cutParams = {
      ...updatedParams,
      attributes: omit(updatedParams.attributes, ['data', 'defaultData']),
    };

    return updateWidget<WidgetType>(cutParams).then(({ data }) => data);
  });

  storage.store.on(updateWidgetEffect.doneData, (state, { attributes }) => ({
    ...state,
    data: { ...state.data, attributes } as WidgetType,
  }));

  return { storage, updateWidgetEffect };
};

type WidgetListStorageParams = { type: string[] };

export const getWidgetListStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<WidgetModel>,
    DictPaginated<WidgetModel>,
    null,
    WidgetListStorageParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.widgets.widgetList(), params),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getWidgetTypesStorage = () => {
  const storage = abstractStorageFactory<string[], string[], string[]>({
    endpointBuilder: endpoints.widgets.widgetTypes,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GetWidgetStorage<WT extends WidgetModel> = {
  storage: AbstractStorage<DictPaginated<WT> | WT, WT | null, null>;
  updateWidgetEffect: Effect<UpdateWidgetParams, WT, AxiosError>;
};

type LayoutsParams = {
  name: string;
};

export const getLayoutStorage = () => {
  const storage = abstractStorageFactory<Layout, Layout, null, LayoutsParams>({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.pages.layouts(), params),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return {
    storage,
  };
};

export const widgetListStorage = getWidgetListStorage();
export const layoutStorage = getLayoutStorage();
export const widgetTypesStorage = getWidgetTypesStorage();
