import { AxiosError } from 'axios';
import { createEffect, createEvent } from 'effector';

import { endpoints } from '../../endpoints';
import { PostAttachmentModel, PostTypes } from '../../types/models';
import { abstractStorageFactory } from '../../utils/effector';
import { getAttachmentById, ReloadAttachmentByIdParams, ReplaceAttachmentByIdParams } from './api';

export type GetPostAttachmentsStorageParams = {
  postType: PostTypes;
  postId: number;
};

export const getPostAttachmentsStorage = ({ postType, postId }: GetPostAttachmentsStorageParams) => {
  const storage = abstractStorageFactory<PostAttachmentModel[], PostAttachmentModel[], PostAttachmentModel[]>(
    {
      endpointBuilder: () => endpoints.asyncBlogs.attachmentsContentTypeEntityId(postType, postId),
      defaultValue: [],
    },
  );

  const replaceAttachmentByIdEvent = createEvent<ReplaceAttachmentByIdParams>();

  storage.store.on(replaceAttachmentByIdEvent, (state, { id, attachment: newAttachment }) => {
    const foundAttachmentIndex = state.data.findIndex((attachment) => attachment.id === id);

    if (foundAttachmentIndex !== -1) {
      const newData = [...state.data];

      newData.splice(foundAttachmentIndex, 1, newAttachment);

      return {
        ...state,
        data: newData,
      };
    }

    return state;
  });

  const reloadAttachmentByIdEffect = createEffect<ReloadAttachmentByIdParams, void, AxiosError>(
    ({ attachmentId }) => {
      // TODO: GETSTATE ПОРОЖДАЕТ ТРУДНООТЛАЖИВАЕМЫЙ ИМПЕРАТИВНЫЙ КОД И СОСТОЯНИЯ ГОНКИ ДАННЫХ https://effector.dev/ru/docs/api/effector/store/
      const attachmentToReload = storage.store
        .getState()
        .data.find((attachment) => attachment.attachmentId === attachmentId);

      if (!attachmentToReload) {
        return Promise.resolve();
      }

      const { objectId, id } = attachmentToReload;

      return getAttachmentById({ contentType: postType, objectId, id }).then(({ data: attachment }) => {
        replaceAttachmentByIdEvent({ id, attachment });
      });
    },
  );

  return {
    storage,
    reloadAttachmentByIdEffect,
  };
};

export type AttachmentsStorage = ReturnType<typeof getPostAttachmentsStorage>;
