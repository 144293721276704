import { EntityId } from '../types/blogs';

export const pollsEndpoints = {
  polls: () => '/api/polls/polls/', // polls
  pollsEntityId: (entityId: EntityId) => `/api/polls/polls/${entityId}/`, // pollById
  pollsEntityIdVote: (entityId: EntityId) => `/api/polls/polls/${entityId}/vote/`, // vote
  pollsEntityIdExportXLSX: (entityId: EntityId) => `/api/polls/polls/${entityId}/export/xlsx/`, // exportPollToXLSX
  pollsQuestionIdOptionIdExportXLSX: (questionId: EntityId, optionId: EntityId) =>
    `/api/polls/polls/question/${questionId}/option/${optionId}/export/xlsx/`, // exportPollAnswerToXLSX
};
