import { Rule } from '@vkph/ui';

export const MAX_LENGTH_INPUT_10 = 10;
export const MAX_LENGTH_INPUT_15 = 15;
export const MAX_LENGTH_INPUT_50 = 50;
export const MAX_LENGTH_INPUT_80 = 80;
export const MAX_LENGTH_INPUT_100 = 100;
export const MAX_LENGTH_INPUT_150 = 150;
export const MAX_LENGTH_INPUT_200 = 200;
export const MAX_LENGTH_INPUT_255 = 255;
export const MAX_LENGTH_INPUT_300 = 300;
export const MAX_LENGTH_INPUT_600 = 600;
export const MAX_LENGTH_INPUT_1024 = 1024;
export const MAX_LENGTH_INPUT_1000 = 1000;
export const MAX_LENGTH_INPUT_2000 = 2000;
export const MAX_LENGTH_INPUT_4096 = 4096;

export const getMinLengthRule = (minLength: number): Rule => ({
  min: minLength,
  message: `Убедитесь, что в этом поле ${minLength} или больше символов.`,
});

export const getMaxLengthRule = (maxLength: number): Rule => ({
  max: maxLength,
  message: `Убедитесь, что в этом поле не больше ${maxLength} символов.`,
});
