import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { currentProfileStore } from '../../providers/current-profile';
import { DictPaginated } from '../../types';
import { PagesPopulateLayoutModel, WidgetId, WidgetModel } from '../../types/models';
import { abstractStorageFactory } from '../../utils';
import {
  postDefaultPopulate,
  postResetDefaultLayoutItems,
  postResetLayoutItems,
  postUsersPopulate,
} from './api';

export type PopulateEditChange = {
  layoutItemId: number;
  widgetId: WidgetId;
  type: string;
  user: string | null;
};

type EditPopulatedLayoutState = {
  isEdit: boolean;
  isSuperAdminPopulateWidgets: boolean;
  resetItems: number[];
  backupItems: PagesPopulateLayoutModel[];
  editChanges: PopulateEditChange[];
};

type PopulatedLayoutsParams = {
  layoutId: number;
};

type WidgetPopulatedLayoutEvent = {
  layoutItemId: number;
  widget: WidgetModel;
  type: string;
};

export const getPopulatedLayoutStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<PagesPopulateLayoutModel>,
    PagesPopulateLayoutModel[],
    PagesPopulateLayoutModel[],
    PopulatedLayoutsParams
  >({
    endpointBuilder: ({ layoutId }) => endpoints.pages.usersCurrentUserIdPopulatedLayout(layoutId),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    cancelPendingRequestOnFetch: true,
  });

  const editPopulatedLayoutState = createStore<EditPopulatedLayoutState>({
    isEdit: false,
    isSuperAdminPopulateWidgets: false,
    backupItems: [],
    resetItems: [],
    editChanges: [],
  });

  const setIsEditLayoutEvent = createEvent();
  const setIsSuperAdminPopulateEvent = createEvent();
  const resetLayoutItemsEvent = createEvent<{ layoutItemId: number }>();
  const cancelLayoutEditEvent = createEvent();
  const setWidgetPopulatedLayoutEvent = createEvent<WidgetPopulatedLayoutEvent>();
  const saveLayoutEditEffect = createEffect<boolean | undefined, unknown, AxiosError>((isSuperAdmin) => {
    const {
      editChanges,
      resetItems: layoutItemIds,
      isSuperAdminPopulateWidgets,
    } = editPopulatedLayoutState.getState();

    const promises: Promise<unknown>[] = [];
    const postPopulate = isSuperAdminPopulateWidgets ? postDefaultPopulate : postUsersPopulate;

    if (editChanges.length) {
      promises.push(postPopulate({ editChanges }));
    }

    if (layoutItemIds.length) {
      promises.push(postResetLayoutItems({ layoutItemIds }));

      if (isSuperAdmin) {
        promises.push(postResetDefaultLayoutItems({ layoutItemIds }));
      }
    }

    return Promise.all(promises);
  });

  storage.store
    .on(resetLayoutItemsEvent, (state, { layoutItemId }) => {
      if (state?.data) {
        return {
          ...state,
          data: state.data.filter((populate) => populate.layoutItem !== layoutItemId),
        };
      }

      return undefined;
    })
    .on(cancelLayoutEditEvent, (state) => {
      const { backupItems } = editPopulatedLayoutState.getState();

      if (state?.data) {
        return {
          ...state,
          data: backupItems.length > 0 ? backupItems : state.data,
        };
      }

      return undefined;
    })
    .on(setIsSuperAdminPopulateEvent, (state) => {
      const { editChanges, isSuperAdminPopulateWidgets } = editPopulatedLayoutState.getState();
      const { id: userId = null } = currentProfileStore.getState() || {};

      return {
        ...state,
        data: state.data.map((populate) => {
          const newPopulate = { ...populate };

          if (editChanges.find((changedPopulate) => changedPopulate.layoutItemId === populate.layoutItem)) {
            newPopulate.user = isSuperAdminPopulateWidgets ? userId : null;
          }

          return newPopulate;
        }),
      };
    })
    .on(setWidgetPopulatedLayoutEvent, (state, payload) => {
      const { isSuperAdminPopulateWidgets } = editPopulatedLayoutState.getState();
      const { id: userId = null } = currentProfileStore.getState() || {};

      const existChangesIndex = state?.data?.findIndex(
        (populatedItem) => populatedItem.layoutItem === payload.layoutItemId,
      );

      if (existChangesIndex >= 0) {
        return {
          ...state,
          data: state.data.map((populatedItem) =>
            populatedItem.layoutItem === payload.layoutItemId
              ? ({
                  ...populatedItem,
                  user: isSuperAdminPopulateWidgets ? null : userId,
                  widget: payload.widget,
                } as PagesPopulateLayoutModel)
              : populatedItem,
          ),
        };
      }

      return {
        ...state,
        data: [
          ...state.data,
          {
            layoutItem: payload.layoutItemId,
            type: payload.type,
            widget: payload.widget,
            user: isSuperAdminPopulateWidgets ? null : userId,
          },
        ] as PagesPopulateLayoutModel[],
      };
    });

  editPopulatedLayoutState.reset(cancelLayoutEditEvent, saveLayoutEditEffect.doneData);

  editPopulatedLayoutState
    .on(setIsEditLayoutEvent, (state) => {
      const populatedLayout = storage.store.getState().data;

      return { ...state, isEdit: true, backupItems: [...populatedLayout] };
    })
    .on(setIsSuperAdminPopulateEvent, (state) => {
      return { ...state, isSuperAdminPopulateWidgets: !state.isSuperAdminPopulateWidgets };
    })
    .on(resetLayoutItemsEvent, (state, { layoutItemId }) => {
      const populatedLayout = storage.store.getState().data;

      return {
        ...state,
        backupItems: state.backupItems.length > 0 ? state.backupItems : populatedLayout,
        resetItems: [...state.resetItems, layoutItemId],
        editChanges: [...state.editChanges].filter(
          (changedPopulate) => changedPopulate.layoutItemId !== layoutItemId,
        ),
      };
    })
    .on(setWidgetPopulatedLayoutEvent, (state, payload) => {
      const existChangesIndex = state.editChanges.findIndex(
        (editChange) => editChange.layoutItemId === payload.layoutItemId,
      );

      const updatedResetItems = [...state.resetItems].filter(
        (resetItemId) => payload.layoutItemId !== resetItemId,
      );

      if (existChangesIndex >= 0) {
        return {
          ...state,
          editChanges: state.editChanges.map((editChange) =>
            editChange.layoutItemId === payload.layoutItemId
              ? ({
                  layoutItemId: payload.layoutItemId,
                  widgetId: payload.widget.id,
                  type: payload.type,
                } as PopulateEditChange)
              : editChange,
          ),
          resetItems: updatedResetItems,
        };
      }

      return {
        ...state,
        editChanges: [
          ...state.editChanges,
          {
            layoutItemId: payload.layoutItemId,
            widgetId: payload.widget.id,
            type: payload.type,
          } as PopulateEditChange,
        ],
        resetItems: updatedResetItems,
      };
    });

  return {
    storage,
    editPopulatedLayoutState,
    setIsEditLayoutEvent,
    setIsSuperAdminPopulateEvent,
    cancelLayoutEditEvent,
    resetLayoutItemsEvent,
    saveLayoutEditEffect,
    setWidgetPopulatedLayoutEvent,
  };
};

export const populatedLayoutStorage = getPopulatedLayoutStorage();
