export type ListMeta = {
  objectsCount: number;
  objectsTotal: number;
  pageNumber: number;
  pagesCount: number;
};

type PageSize = number;

export type NextMeta = {
  next: Record<string, unknown>;
};

/** @deprecated */
export type PaginatedResults<T> = {
  results: T[];
  count: number;
};

export type DictPaginated<T> = {
  items: T[];
  meta: ListMeta;
};

export type PaginatedListResults<T> = {
  results: T[];
  meta: ListMeta;
};

export type PaginatedNextResults<T> = {
  results: T[];
  meta: NextMeta;
};

export type DictPaginatedObjects<T> = {
  objects: T[];
  meta: ListMeta;
};

export enum DictMatchTypes {
  Exact = '=',
  Contains = 'contains', // с учетом регистра
  Icontains = 'icontains', // по не точному совпадению
}

export type NextParams = {
  next: NextMeta['next'];
  pageSize: PageSize;
};

export type PaginationParams = {
  pageNumber: number;
  pageSize: PageSize;
};

export interface DictExactMatchValue {
  value: string;
  type: DictMatchTypes;
}

export type DictNameDataParams = { name: string };
export type DictDataParams = Record<string, DictExactMatchValue>;
export type SinceTillFetchParams = { since: string; till: string };

export interface BaseFieldParams extends Partial<SinceTillFetchParams> {
  isMine?: boolean;
  ordering?: string;
  query?: string;
  statusId?: number;
  search?: string;
}

export enum OrderingParams {
  CreatedAtAsc = 'created_at',
  CreatedAtDesc = '-created_at',
  UpdatedAtAsc = 'updated_at',
  UpdatedAtDesc = '-updated_at',
  FavoriteAtAsc = 'favorite_at',
  FavoriteAtDesc = '-favorite_at',
  CreatedByAsc = 'created_by',
  CreatedByDesc = '-created_by',
  UpdatedByAsc = 'updated_by',
  UpdatedByDesc = '-updated_by',
  RatingAtDesc = '-rating',
  RatingAtAsc = 'rating',
  JoinedAtAsc = 'joined_at',
  JoinedAtDesc = '-joined_at',
  NameAtAsc = 'name',
  NameAtDesc = '-name',
  ObjectsCountAtAsc = 'objects_count',
  ObjectsCountAtDesc = '-objects_count',
  PageNameAtAsc = 'page_name',
  PageNameAtDesc = '-page_name',
  AuthorNameAtAsc = 'author_name',
  AuthorNameAtDesc = '-author_name',
  CountAsc = 'count',
  CountDesc = '-count',
}
