import { ProjectId, ProjectsStageId, ProjectsTaskId } from '..';
import { CreateIssuerDateModel } from '../issuerDate.model';
import { UserModel } from '../user.model';

export type ProjectsHistoryId = ProjectId | ProjectsStageId | ProjectsTaskId;

export enum ProjectsHistoryType {
  Project = 'project',
  Task = 'task',
  Stage = 'stage',
}

export interface ProjectsHistoryModel extends CreateIssuerDateModel<UserModel> {
  field: string;
  previousValue?: string;
  newValue: string;
  contentObject: ProjectsHistoryType;
  objectId: ProjectsHistoryId;
  description: string;
}
