import { DependencyList, useMemo } from 'react';

import { getProfileStorage } from '..';
import { useAbstractStorage } from '../../../hooks';
import { UserIdModel } from '../../../types/models';

/**
 * Используется для всех кроме currentUser.
 * Данные для currentUser брать из /main-frontend/packages/ant/src/plugins/currentProfileService.ts
 */
export const useProfileStorage = ({ id, deps = [] }: { id?: UserIdModel; deps?: DependencyList }) => {
  const { storage: singleStorage } = useMemo(getProfileStorage, deps);

  const { data, loading: profilesLoading } = useAbstractStorage(singleStorage, {
    autoFetchAndRefetch: Boolean(id),
    autoFetchParams: { userId: id || '' },
  });

  const loading = !data || profilesLoading;

  return {
    data,
    loading,
  };
};
