import { stringify } from 'query-string';
import { useCallback } from 'react';
import { useLocation, To, useNavigate, NavigateOptions } from 'react-router-dom';

import { LongreadParams } from '../types/longread';

const LONGREAD_ROUTE = '/@longread';

export const LONGREAD_ROUTE_PATH = `${LONGREAD_ROUTE}/:type/:id`;

const getLinkParams = (params: LongreadParams, from: string): To => {
  const { type, id, ...restParams } = params;

  return {
    pathname: `${LONGREAD_ROUTE}/${type}/${id}`,
    search: stringify({ ...restParams, from }),
  };
};

export const useLongreadNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openLongread = useCallback(
    (params: LongreadParams, state?: Record<string, unknown>) => {
      const to = getLinkParams(params, location.pathname);

      const options: NavigateOptions = {
        state: { ...state, longreadLocation: location },
      };

      navigate(to, options);
    },
    [location, navigate],
  );

  return { openLongread };
};

export const useLongreadLink = (params: LongreadParams, defaultState?: Record<string, unknown>) => {
  const location = useLocation();

  const state = { ...defaultState, longreadLocation: location };
  const to = getLinkParams(params, location.pathname);

  return {
    to,
    state,
  };
};
