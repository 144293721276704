import { WidgetAccessToken } from '@vkph/widget-sdk-dispatcher';
import { AxiosResponse } from 'axios';

import { endpoints } from '../../endpoints';
import { WidgetUuid } from '../../types/models';
import { api } from '../../utils';

export type AuthTokenParams = {
  widgetUuid: WidgetUuid;
};

export type CurrentUserParams = {
  widgetAccessToken: WidgetAccessToken;
};

export const getAuthToken = <T = unknown>(data: AuthTokenParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.widgets.authToken(),
    data,
  });

export const getCurrentUser = <T = unknown>({
  widgetAccessToken,
}: CurrentUserParams): Promise<AxiosResponse<T>> =>
  api.get({
    url: endpoints.widgets.currentUser(),
    headersQuery: {
      'widget-authorization': widgetAccessToken,
    },
  });
