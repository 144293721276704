import {
  BlogId,
  BlogSlugId,
  FileStorageContextServiceTypes,
  FileStorageListEntryModel,
  ProjectId,
  UserIdModel,
} from '../../types/models';

type FileStorageRouteParams = { id: BlogId | BlogSlugId | ProjectId | UserIdModel };

export const getFileStorageRouteParams = (
  entry: FileStorageListEntryModel,
): FileStorageRouteParams | null => {
  if (entry?.context?.service === FileStorageContextServiceTypes.Blogs) {
    return { id: entry.context.blogSlug || entry.context.blogId };
  }

  if (entry?.context?.service === FileStorageContextServiceTypes.Projects) {
    return { id: entry.context.projectId };
  }

  if (entry?.context?.service === FileStorageContextServiceTypes.Profile) {
    return { id: entry.context.userId };
  }

  return null;
};
