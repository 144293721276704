import { endpoints } from '../../endpoints';
import { Tag, TagId } from '../../types/models';
import { api } from '../../utils';

export type CreateTagParams = {
  name: string;
};

export const createTag = (params: CreateTagParams) =>
  api.post<Tag>({
    url: endpoints.tags.tags(),
    data: params,
  });

export type FollowTagsParams = {
  tagIds: TagId[];
};

export const followTags = (params: FollowTagsParams) =>
  api.patch<Tag[]>({
    url: endpoints.tags.tagsFollow(),
    data: params,
  });
