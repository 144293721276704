import addDays from 'date-fns/addDays';
import endOfMonth from 'date-fns/endOfMonth';
import getISODay from 'date-fns/getISODay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';

export type MonthRangeByWeeksType = {
  sinceDate: Date;
  tillDate: Date;
};

export const getMonthRangeByWeeks = (date: Date): MonthRangeByWeeksType => {
  const sinceDate = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
  const endOfMonthDate = endOfMonth(date);
  const tillDate = addDays(endOfMonthDate, 7 - getISODay(endOfMonthDate));

  return { sinceDate, tillDate };
};
