import type { EditorValue, EntityContentData } from '../editor';
import type { CreateDateModel } from './date.model';
import type { FileModel, FileStorageEntryId, FileStorageEntryUrl, FileStorageImage } from './file.model';
import type { PermissionsModel } from './permissions.model';
import type { PostId } from './post';
import type { UserAvatarModel, UserIdModel, UserModel, UserPositionModel } from './user.model';

export enum Role {
  Follower = 'follower',
  Moderator = 'moderator',
  Author = 'author',
  Admin = 'admin',
  SuperAdmin = 'super_admin',
  NotFollower = 'not_follower',
}

export type BlogRequestId = number;
export enum BlogRequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
}

export enum BlogPublicTabs {
  Activities = 'Activities',
  Images = 'Images',
  Quiz = 'Quiz',
  FileStorage = 'FileStorage',
  Files = 'Files',
  Members = 'Members',
}

/** @deprecated use PermissionsV2Enum */
export enum PermissionsEnum {
  BlogCreate = 'blog_create',
  BlogRead = 'blog_read',
  BlogUpdate = 'blog_update',
  BlogDelete = 'blog_delete',
  BlogSubscribe = 'blog_subscribe',
  BlogViewFiles = 'blog_view_files',
  BlogViewRegistrations = 'blog_view_registrations',

  EntryCommentCreate = 'entry_comment_create',

  MicropostCommentCreate = 'micropost_comment_create',
  MicropostReactionCreate = 'micropost_reaction_create',

  BlogAddLeader = 'blog_add_leader',
  BlogAddModerator = 'blog_add_moderator',
  BlogAddExpert = 'blog_add_expert',
  BlogAddSubscriber = 'blog_add_subscriber',
}

export enum PermissionsV2Enum {
  AddFiles = 'add_files',
  AddImages = 'add_images',
  DeleteBlog = 'delete_blog',
  DeleteEntries = 'delete_entries',
  EditBlogInformation = 'edit_blog_information',
  EntryCreate = 'entry_create',
  EntryDelete = 'entry_delete',
  EntryRead = 'entry_read',
  EntryUpdate = 'entry_update',
  ForcedSubscribe = 'forced_subscribe',
  ManageAdmin = 'manage_admin',
  ManageFileStorage = 'manage_file_storage',
  ManageImageStorage = 'manage_image_storage',
  ManageSettings = 'manage_settings',
  ManageSubscriptionRequests = 'manage_subscription_requests',
  MicropostCreate = 'micropost_create',
  MicropostDelete = 'micropost_delete',
  MicropostUpdate = 'micropost_update',
  ModeratePosts = 'moderate_posts',
  PinPosts = 'pin_posts',
  PostEntries = 'post_entries',
  ReadBlog = 'read_blog',
  ReadEntries = 'read_entries',
  RemoveMembers = 'remove_members',
  SendInvitations = 'send_invitations',
}

export enum BlogRestrictedType {
  Open = 'open',
  Restricted = 'restricted',
  Invisible = 'invisible',
}

export enum BlogModerationType {
  WithoutModeration = 0,
  Premoderation = 1,
}

export enum BlogLastActivityRelatedObjectType {
  Entry = 'entry',
  StorageObject = 'storage_object',
  StorageObjectVersion = 'version',
}

export enum BlogLastActivityActions {
  Add = 'add',
  Delete = 'delete',
  Restore = 'restore',
  Update = 'update',
  Undelete = 'undelete',
  Transfer = 'transfer',
  CommentCreate = 'comment_create',
  CommentDelete = 'comment_delete',
  ReactionCreate = 'reaction_create',
  ReactionDelete = 'reaction_delete',
  CommentReactionCreate = 'comment_reaction_create',
}

export type BlogId = number;
export type BlogSlugId = string;
export type BlogsManagerRolePermission = {
  name: string;
  description: string;
  isEditable: boolean;
  defaultValue: boolean;
};

export interface BlogsManagerRolePermissions extends PermissionsModel {
  permissions: BlogsManagerRolePermission[];
}

export type BlogManagerUser = Omit<UserModel, 'avatar'> & UserAvatarModel;
export interface BlogManager {
  user: BlogManagerUser;
  role: PermissionsModel['name'];
  permissions: Array<BlogsManagerRolePermission['name']>;
}

export type BlogMembersPermissionModel = {
  isReacted: boolean;
  isComments: boolean;
  publicationAllowed: boolean;
};

export type SubscribeToBlogRequestModel = {
  requestId: BlogRequestId;
};

export type BlogFileStorageSubFolders = {
  files: FileStorageEntryId;
  images: FileStorageEntryId;
  entries: FileStorageEntryId;
};

export enum BlogWidgetsType {
  NewContent = 'new_content',
  TopTags = 'top_tags',
  LastActivity = 'last_activity',
  NewParticipants = 'new_participants',
  ContentSearch = 'content_search',
  TrackTag = 'track_tag',
}

export type BlogWidget = {
  slug: BlogWidgetsType;
  enabled: boolean;
};

export type BlogModel = BlogMembersPermissionModel &
  FileStorageImage & {
    id: BlogId;
    avatar: string;
    restrictedType: BlogRestrictedType;
    moderationType: BlogModerationType;
    expandedDescription: EntityContentData;
    permissions: PermissionsEnum[];
    permissionsV2: Record<PermissionsV2Enum, boolean>;
    name: string;
    // TODO это поле с бэка не приходит, выпилить
    notify: boolean;
    isSubscribed: boolean;
    type: 'regular';
    shortDescription: string;
    slug: string;
    showInRecommendations: boolean;
    isSearchable: boolean;
    feedback: boolean;
    categoryRid: string;
    publicTabs: BlogPublicTabs[];
    managers: BlogManager[];
    role: Role;
    requestId: BlogRequestId;
    requestStatus: BlogRequestStatus;
    fileStorageRootFolderId?: FileStorageEntryId;
    fileStorageSubFolders?: BlogFileStorageSubFolders;
    widgets: BlogWidget[];
  };

export enum BlogType {
  /** Тематический блог */
  Regular = 'regular',
  /** Проектная группа */
  ProjectGroup = 'project-group',
  /** Корпоративный центр */
  CorpCenter = 'corp-center',
  /** Новости */
  News = 'news',
  /** Блоги-сервисы */
  Service = 'service',
  /** Пресс-релизы и новости организаций Группы */
  PressRelease = 'press-release',
  /** Материалы печатных СМИ и новости информагентств */
  MassMedia = 'mass-media',
  /** Мероприятия */
  Event = 'event',
}

export interface BlogFullModel {
  id: BlogId;
  uuid: string;
  name: string;
  slug: string;
  type: BlogType;
  shortDescription: string;
  isComments: boolean;
  isReacted: boolean;
  fileStorageImageUrl: FileStorageEntryUrl;
}

export interface BlogAlbumImageModel {
  id: number;
  width: number;
  height: number;
  fileStorageImageUrl: FileStorageEntryUrl;
  fileStorageImageId: FileStorageEntryId;
}

export interface BlogAlbumImageModelV3 extends Omit<BlogAlbumImageModel, 'fileStorageImageUrl'> {
  file: FileModel;
  fileStorageImage: File;
}

export type BlogAlbumId = number;

export type BlogAlbumModel = {
  id: BlogAlbumId;
  title: string;
  imagesCount: number;
  author: UserModel;
  createdAt: Date;
  blogFeedback: boolean;
  fileStorageCoverUrl?: FileStorageEntryUrl;
};

export type BlogRegistrationId = number;

export type BlogRegistrationModel = {
  id: BlogRegistrationId;
  title: string;
  respondentsCount: number;
  dateEnd: string;
  isEnded: boolean;
  isClosed: boolean;
};

export type BlogRegistrationRespondent = {
  approved: boolean;
  avatar: string;
  email: string;
  firstName: string;
  id: UserIdModel;
  keycloakUser: UserModel['keycloakUser'];
  lastName: string;
  patronymic: string;
  position?: UserPositionModel;
  username: string;
};

export type BlogRegistrationRespondentsModel = {
  id: BlogRegistrationId;
  respondents: BlogRegistrationRespondent[];
};

interface BlogLastActivityActorModel
  extends Pick<UserModel, 'firstName' | 'lastName' | 'patronymic' | 'avatar' | 'isActive'> {
  keycloakId: UserIdModel;
}

export type BlogLastActivityMetaPostModel = {
  data: {
    id: PostId;
    body: EditorValue;
    title: string;
  };
};

export type BlogLastActivityMetaStorageObjectModel = {
  data: {
    id: FileStorageEntryId;
    name?: string;
  };
  revision?: number;
};

export type BlogLastActivityRelatedObjectMeta =
  | BlogLastActivityMetaPostModel
  | BlogLastActivityMetaStorageObjectModel;

export type BlogLastActivityRelatedObjectModel<T = BlogLastActivityRelatedObjectMeta> = {
  summary: string;
  type: BlogLastActivityRelatedObjectType;
  value: string;
  meta: T;
  isDeleted: boolean;
};

export interface BlogLastActivityModel extends CreateDateModel {
  summary: string;
  type: BlogLastActivityActions;
  actor?: BlogLastActivityActorModel;
  object: BlogLastActivityRelatedObjectModel<BlogLastActivityRelatedObjectMeta>;
}
