import { BasicPostAttachmentModel, PostAttachedEntity, PostAttachmentTypes } from '../models/post';

export const isPostAttachment = <T extends PostAttachedEntity>(
  entity: BasicPostAttachmentModel,
  type: PostAttachmentTypes,
): entity is BasicPostAttachmentModel<T> => entity.attachmentType === type;

export const isPostAttachmentsSomeOfType = <Type extends PostAttachedEntity>(
  attachments: BasicPostAttachmentModel[],
  type: PostAttachmentTypes,
): attachments is BasicPostAttachmentModel<Type>[] => {
  return attachments.every((attachment) => isPostAttachment<Type>(attachment, type));
};
