import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from '../../endpoints';
import { DictPaginated } from '../../types/api';
import {
  FetchNotificationParams,
  NotificationId,
  NotificationModel,
  NotificationStatuses,
} from '../../types/models';
import { buildEndpointWithQueryParams } from '../../utils';
import { abstractStorageFactory } from '../../utils/effector';
import { markNotificationRead } from './api';

export interface NotificationsInfo extends DictPaginated<NotificationModel> {
  unreadCount: number;
}

const DEFAULT_NOTIFICATION_INFO: NotificationsInfo = {
  items: [],
  meta: {
    objectsCount: 0,
    objectsTotal: 0,
    pageNumber: 0,
    pagesCount: 0,
  },
  unreadCount: 0,
};

export const markNotificationReadEffect = createEffect<NotificationId, void, AxiosError>((id) =>
  markNotificationRead(id).then(({ data }) => data),
);

export const getNotificationsStorage = () => {
  const storage = abstractStorageFactory<
    NotificationsInfo,
    NotificationsInfo,
    NotificationsInfo,
    FetchNotificationParams
  >({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(endpoints.notifications.feed(), { isActive: true, ...params }),
    defaultValue: DEFAULT_NOTIFICATION_INFO,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
  });

  storage.store.on(markNotificationReadEffect.done, (state, { params: notificationId }) => {
    const { data } = state;

    return {
      ...state,
      data: {
        ...data,
        items: data.items.map((notification) =>
          notification.id === notificationId
            ? {
                ...notification,
                status: NotificationStatuses.Read,
              }
            : notification,
        ),
        unreadCount: data.unreadCount > 0 ? data.unreadCount - 1 : 0,
      },
    };
  });

  return storage;
};

export const notificationsStore = getNotificationsStorage();
