import { endpoints } from '../../endpoints';
import { NotificationId } from '../../types/models';
import { api } from '../../utils';

export const markAllNotificationsRead = () => {
  return api.post({
    url: endpoints.notifications.feedMarkReadAll(),
  });
};

export const markNotificationRead = (id: NotificationId) => {
  return api.post<void>({
    url: endpoints.notifications.feedEntityIdMarkRead(id),
  });
};
