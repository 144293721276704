import {
  UserIdModel,
  GroupsMailingId,
  SendingSurveyId,
  SurveyGroupId,
  SurveyId,
  SurveyQuestionId,
} from '../types/models';

export const surveyEndpoints = {
  groupList: () => `/api/async-survey/group/list/`,
  groupGroupId: (surveyGroupId: SurveyGroupId) => `/api/async-survey/group/${surveyGroupId}/`,
  groupGroupIdMembers: (surveyGroupId: SurveyGroupId) => `/api/async-survey/group/${surveyGroupId}/members/`,
  group: () => `/api/async-survey/group/`,
  groupMailingClone: (surveyGroupId: SurveyGroupId) => `/api/async-survey/group/${surveyGroupId}/clone/`,
  groupMailingIdMembers: (groupMailingId: string) => `/api/async-survey/group/${groupMailingId}/members/`,
  memberSurveys: () => `/api/async-survey/member/surveys/`,
  memberSendingSurveyIdSurvey: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/member/${sendingSurveyId}/survey/`,
  memberSendingSurveyIdQuestions: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/member/${sendingSurveyId}/questions/`,
  memberSendingSurveyIdAnswer: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/member/${sendingSurveyId}/answer/`,
  memberSendingSurveyIdComplete: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/member/${sendingSurveyId}/complete/`,
  membersGroupMailing: (groupMailingId: GroupsMailingId, memberId: UserIdModel) =>
    `/api/async-survey/group/${groupMailingId}/members/${memberId}/`,
  rolesList: () => `/api/async-survey/roles/list/`,
  rolesUserId: (id: UserIdModel) => `/api/async-survey/roles/${id}/`,
  rolesSave: () => '/api/async-survey/roles/save/',
  survey: () => '/api/async-survey/survey/',
  surveyInstanceSendingSurveyId: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/survey/instance/${sendingSurveyId}/`,
  surveyInstanceSendingSurveyIdMembers: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/survey/instance/${sendingSurveyId}/members/`,
  surveyInstanceSendingSurveyIdCancel: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/survey/instance/${sendingSurveyId}/cancel/`,
  statisticsSurveyInstanceSendingSurveyId: (sendingSurveyId: SendingSurveyId) =>
    `/api/async-survey/statistics/survey/instance/${sendingSurveyId}/`,
  statisticsSurveyInstanceSendingSurveyIdQuestionQuestionIdComments: (
    sendingSurveyId: SendingSurveyId,
    questionId: SurveyQuestionId,
  ) => `/api/async-survey/statistics/survey/instance/${sendingSurveyId}/question/${questionId}/comments/`,
  statisticsSurveySurveyIdCommon: (surveyId: SurveyId) =>
    `/api/async-survey/statistics/survey/${surveyId}/common/`,
  surveyId: (surveyId: SurveyId) => `/api/async-survey/survey/${surveyId}/`,
  surveyClone: (surveyId: SurveyId) => `/api/async-survey/survey/${surveyId}/clone/`,
  surveyList: () => `/api/async-survey/survey/list/`,
  surveySending: () => `/api/async-survey/survey/sending/`,
  surveySurveyIdSending: (surveyId: SurveyId) => `/api/async-survey/survey/${surveyId}/sending/`,
  surveySendingList: () => `/api/async-survey/survey/sending/list/`,
  surveySendingGroupGroupId: (surveyGroupId: SurveyGroupId) =>
    `/api/async-survey/survey/sending/group/${surveyGroupId}/`,
};
