import { useState, useMemo, useCallback } from 'react';

import { getProfileListOptionsStorage, ProfileListParams } from '..';
import { useAbstractStorage } from '../../../hooks';

export type UseProfileOptionsListStorageParams = {
  autoFetchParams?: ProfileListParams;
};

export const useProfileOptionsListStorage = (params: UseProfileOptionsListStorageParams = {}) => {
  const { autoFetchParams } = params;

  const [searchQuery, setQuerySearch] = useState('');
  const { storage: listStorage } = useMemo(getProfileListOptionsStorage, []);

  const {
    data: options,
    fetchFx: fetchProfileList,
    loading,
  } = useAbstractStorage(listStorage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      skipEmptyName: true,
      ...autoFetchParams,
    },
  });

  const onUpdate = useCallback(
    (newSearchQuery: string) =>
      fetchProfileList({ ...autoFetchParams, query: newSearchQuery, skipEmptyName: true }),
    [fetchProfileList],
  );

  return {
    options,
    storage: listStorage,
    onUpdate,
    searchQuery,
    setQuerySearch,
    loading,
  };
};
