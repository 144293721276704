import { FileStorageFolderImageExtensions, FileStorageFolderVideoExtensions } from '../../types/models';

type ExtensionsType = FileStorageFolderVideoExtensions | FileStorageFolderImageExtensions;
type ExtensionContentType = 'video' | 'image';

export const getFormattedEndpointExtensions = (
  extensions: ExtensionsType[],
  extensionType: ExtensionContentType,
): string => {
  const extensionTypes = extensions.map((type) => {
    const [, extensionName] = type.split('.');

    return `${extensionType}/${extensionName}`;
  });

  return `${extensionTypes.join(',')},`;
};
