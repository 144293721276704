import { PostTypes, ReactionContentType } from '../../types/models';

export const postReactionsContentTypeMap: Record<PostTypes, ReactionContentType | null> = {
  [PostTypes.Simple]: ReactionContentType.News,
  [PostTypes.Stories]: ReactionContentType.News,
  [PostTypes.Entry]: ReactionContentType.Post,
  [PostTypes.Micropost]: ReactionContentType.Micropost,
  [PostTypes.GamificationEntry]: null,
  [PostTypes.ProfileEntry]: null,
};
