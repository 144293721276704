import { UiBreadcrumbItemType } from '@vkph/ui';
import { ReactNode } from 'react';

export type BreadcrumbRoutesOverflowParams = {
  routes?: UiBreadcrumbItemType[];
  maxAfterOverflow?: number;
  overflowTitle?: ReactNode;
};

export const getBreadcrumbRoutesOverflow = (
  params: BreadcrumbRoutesOverflowParams,
): UiBreadcrumbItemType[] => {
  const { routes = [], maxAfterOverflow = 2, overflowTitle = '...' } = params;

  return routes.length > maxAfterOverflow + 1
    ? [routes[0], { title: overflowTitle }, ...routes.slice(-Number(maxAfterOverflow))]
    : routes;
};
