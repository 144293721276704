import { UiMentionSuggestionItem } from '@vkph/ui';
import get from 'lodash/get';
import map from 'lodash/map';

import { DEFAULT_OPTION_SCHEME, OptionsScheme } from './generateOptions';

/**
 * Функция генерации data для UiMention инпута
 * @param arr - Массив элементов
 * @param scheme - Схема
 */
export const generateMentionOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): Array<UiMentionSuggestionItem & { data: T }> =>
  map(arr, (item) => ({
    id: get(item, scheme.valuePath),
    display: get(item, scheme.labelPath),
    data: item,
  }));
