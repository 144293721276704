import {
  CreatePageParams,
  CreateSectionParams,
  GetSectionReportParams,
  UpdatePageParams,
  UpdateSectionParams,
} from '.';
import { endpoints } from '../../endpoints';
import { CMSPageId, CMSSectionId } from '../../types/models';
import { api } from '../../utils';

export type SectionReportParams = {
  sectionId: CMSSectionId;
  dateFrom: Date;
  dateTo: Date;
};

export const createSection = <T = unknown>(data: CreateSectionParams) =>
  api.post<T>({
    url: endpoints.cms.sections(),
    data,
  });

export const getSection = <T = unknown>(sectionId: CMSSectionId) =>
  api.get<T>({
    url: endpoints.cms.sectionsId(sectionId),
  });

export const updateSection = <T = unknown>({ id, ...data }: UpdateSectionParams) =>
  api.patch<T>({
    url: endpoints.cms.sectionsId(id),
    data,
  });

export const deleteSection = (id: CMSSectionId) =>
  api.delete({
    url: endpoints.cms.sectionsId(id),
  });

export const getSectionReport = ({ sectionId, ...data }: GetSectionReportParams) =>
  api.post<Blob>({
    url: endpoints.cms.sectionsIdReport(sectionId),
    data: { sectionId, ...data },
    responseType: 'blob',
  });

export const createPage = <T = unknown>(data: CreatePageParams) =>
  api.post<T>({
    url: endpoints.cms.pages(),
    data,
  });

export const updatePage = <T = unknown>({ id, ...data }: UpdatePageParams) =>
  api.patch<T>({
    url: endpoints.cms.pagesId(id),
    data,
  });

export const deletePage = (id: CMSPageId) =>
  api.delete({
    url: endpoints.cms.pagesId(id),
  });

export const addPageToFavorites = (pageId: CMSPageId) => {
  return api.post({
    url: endpoints.cms.favoritePagesSlugId(pageId),
  });
};

export const removePageFromFavorites = (pageId: CMSPageId) => {
  return api.delete({
    url: endpoints.cms.favoritePagesSlugId(pageId),
  });
};
