import type { EditorValue } from '../editor';
import type { Dictionaries } from './dictionary.model';
import type { UpdateIssuerDateModel, CreateIssuerDateModel, DeleteIssuerDateModel } from './issuerDate.model';
import type { ReactableModel } from './reactions.model';
import type { UserIdModel, UserProfileModel } from './user.model';

export type CMSPageId = string;
export type CMSPageSlugId = string;
export type CMSRolesUserId = string;
export type CMSRolesUserRoleId = string;
export type CMSSectionId = string;
export type CMSSectionSlugId = string;
export type CMSPageFeedbackId = string;

type IssuerDate = CreateIssuerDateModel<UserIdModel> & DeleteIssuerDateModel<UserIdModel>;

interface BaseCMSSectionPage<T = unknown> {
  url: string;
  extraData?: T;
  path: string;
  name: string;
  createdByProfile: UserProfileModel;
}

export type CMSSectionExtraData = {
  isShownSection: boolean;
};

export interface CMSSectionModel extends BaseCMSSectionPage<CMSSectionExtraData>, IssuerDate {
  id: CMSSectionId;
  parentId?: CMSSectionId;
  ancestors?: CMSSectionModel[];
}

export type CMSPageExtraData = {
  isShowViewCount: boolean;
  isReactionsDisabled: boolean;
  isCommentDisabled: boolean;
};

export interface CMSPageModel
  extends BaseCMSSectionPage<CMSPageExtraData>,
    IssuerDate,
    UpdateIssuerDateModel<UserIdModel>,
    ReactableModel {
  id: CMSPageId;
  sectionId: CMSSectionId;
  body: EditorValue;
  sectionSlug: CMSSectionSlugId;
  tags: Dictionaries.Dict[];
  viewsCount: number;
  commentsCount: number;
  isActual: boolean;
  isFavorite: boolean;
  isSearchable: boolean;
  updatedByProfile: UserProfileModel;
  ancestors?: CMSSectionModel[];
  type: CMSPageType;
}

export enum CMSPageFilterType {
  Group = 'group',
  Personal = 'personal',
}

export enum CMSPageType {
  Regular = 'regular',
  Personal = 'personal',
}

export enum CMSViewType {
  Section = 'section',
  Page = 'page',
  Personal = 'personal',
}

export type CMSTreeModel = {
  value: CMSPageSlugId | CMSSectionSlugId;
  title: string;
  type: CMSViewType;
  children?: CMSTreeModel[];
  childrenCount?: number;
};

export enum CMSRolesUserRolePermissions {
  AccessAdminPageGroup = 'access_admin_page_group',
  AccessContentManagementPageGroup = 'access_content_management_page_group',
  CreatePage = 'create_page',
  CreatePageGroup = 'create_page_group',
  CreateSection = 'create_section',
  DeletePage = 'delete_page',
  DeletePageGroup = 'delete_page_group',
  DeleteSection = 'delete_section',
  EditPage = 'edit_page',
  EditPageGroup = 'edit_page_group',
  EditSection = 'edit_section',
  ManageAdmins = 'manage_admins',
  Report = 'report',
  ViewPage = 'view_page',
  ViewPageGroup = 'view_page_group',
  ViewSection = 'view_section',
}

export type CMSRolesUserRole = {
  name: string;
  id: CMSRolesUserRoleId;
  permissions: CMSRolesUserRolePermissions[];
};

export type CMSRolesUserModel = {
  id: CMSRolesUserId;
  userProfile: UserProfileModel;
  role: CMSRolesUserRole;
  sectionInfo: Omit<CMSSectionModel, 'createdByProfile'>;
};

export enum CMSPageFeedbackStatus {
  New = 'new',
  Process = 'process',
  Done = 'done',
  Canceled = 'canceled',
}

export interface CMSPageFeedbackModel extends CreateIssuerDateModel<UserProfileModel> {
  id: CMSPageFeedbackId;
  body: EditorValue;
  page: CMSPageModel;
  activeStatus: CMSPageFeedbackStatus;
}
