import { Rule, type UiSelectDefaultOption } from '@vkph/ui';

export enum LangRule {
  en,
  enUpper,
  enLower,
  ru,
  ruUpper,
  ruLower,
  enRuNumberUnderscore,
}

const langRuleRegexp = {
  [LangRule.en]: /^[a-zA-Z]*$/,
  [LangRule.enUpper]: /^[A-Z]*$/,
  [LangRule.enLower]: /^[a-z]*$/,
  [LangRule.ru]: /^[а-яА-Я]*$/,
  [LangRule.ruUpper]: /^[А-Я]*$/,
  [LangRule.ruLower]: /^[а-я]*$/,
  [LangRule.enRuNumberUnderscore]: /^[a-zA-Zа-яА-Я0-9_]+$/,
};

const latin = 'латиница';
const cyrillic = 'кириллица';
const upper = 'верхнем';
const lower = 'нижнем';

const langRuleText = {
  [LangRule.en]: [latin],
  [LangRule.enUpper]: [latin, upper],
  [LangRule.enLower]: [latin, lower],
  [LangRule.ru]: [cyrillic],
  [LangRule.ruUpper]: [cyrillic, upper],
  [LangRule.ruLower]: [cyrillic, lower],
  [LangRule.enRuNumberUnderscore]: [cyrillic, latin],
};

export const langRule = (type: LangRule, message?: string): Rule => {
  const regexp = langRuleRegexp[type];
  const [lang, register] = langRuleText[type];
  const regMessage = register ? ` в ${register} регистре` : '';

  return {
    validator(_, value) {
      return regexp.test(value) ? Promise.resolve() : Promise.reject();
    },

    message: message ?? `Может использоваться только ${lang}${regMessage}`,
  };
};

export const validOptionLangRule = (message?: string): Rule => {
  return {
    validator: (_, values?: UiSelectDefaultOption[]) => {
      const isInvalid = values?.find((value) => {
        return value.label && !langRuleRegexp[LangRule.enRuNumberUnderscore].test(value.label);
      });

      return isInvalid ? Promise.reject() : Promise.resolve();
    },
    message: message ?? 'Поле должно содержать только буквы, цифры и символ "_".',
  };
};
